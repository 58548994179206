import React from 'react';
import CoSpaceForm from '../../components/CoSpcaeForm';
import roomImg from "../../assets/images/room.jpeg"
import Meta from '../../components/Meta';

function CoSpace() {
  const services = [
    {
      image: roomImg,
      title: "Virtual Office",
      description: "Get a prestigious business address, mail handling, and virtual receptionist services as part of our monthly package."
    },
    {
       image: roomImg,
      title: "Room on Desk Use",
      description: "Access to shared desk space in a modern coworking environment, equipped with amenities, included in our monthly package."
    },
    {
       image: roomImg,
      title: "Hourly Physical Office",
      description: "Book a physical office space on an hourly basis with access to meeting rooms, included in our monthly package."
    },
  ];

  return (
    <div className="visa-consultancy-page">
              <Meta title={"JK Associates | Setup Foreign Company | Registration | Taxation"}
                description="'JK Associates' is top-ranked Corporate Consultancy and Law firm in Bangladesh with the international presence provides specialized services in IP Rights, Corporate Affairs, Foreign Company Registration and FDI Investment, Taxation, and Litigation."
                keywords="Setup Foreign Company, Foreign Company Registration, Company Registration, Tax Lawyer,Vat Consultant,Trademark Registration"
            />
      
      <div className="section services-section">
        <h2>JK Co-Space Packages</h2>
       
        <div className="service-list">
          {services.map((service, index) => (
            <div key={index} className="service">
              <img src={service?.image} alt={service.title} />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      

      <div>
        <CoSpaceForm />
      </div>
             
    </div>
  );
}

export default CoSpace;
