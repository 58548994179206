import React from "react";
import manufactureBannerImg from "../assets/images/manufactureBannerImg.jpg";
import Meta from "../components/Meta";
export default function Manufactures() {
  return (
    <>
      <Meta
        title="Bangladesh Global Industrial Powerhouse | Manufacturers"
        description="Bangladesh is a pivotal hub for manufacturing, propelling the Dhaka to lead the region in industrial competitiveness. Substantial investments in tech, resilient supply chains, a last-mile logistics network and world-renowned ports power the industrial ecosystem."
        image={"image will be here"}
        keywords="Bangladesh hub for manufacturing, RMG Manufacturers, Global Industrial Powerhouse"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Manufacturers
          </h1>
          <p style={{ marginTop: "20px" }}>
            Be a part of RMG Bangladesh global industrial powerhouse.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={manufactureBannerImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Bangladesh is a pivotal hub for manufacturing, propelling the Dhaka to lead
            the region in industrial competitiveness. Substantial investments in
            tech, resilient supply chains, a last-mile logistics network and
            world-renowned ports power the industrial ecosystem.
            <br />
            <br />
            Top manufacturing companies in Bangladesh like Unilever and M Glory Group
            leverage advanced technologies and functional infrastructure to
            streamline operations and boost productivity. They also benefit from
            programmes that enhance local production and in-country value (ICV).
            <br />
            <br />
            Driven by such initiatives, the manufacturing and industrial sector
            has seen consistent year-on-year growth. In the first nine months of
            2023, it contributed 8.3% to Bangladesh GDP with a value of US$ 465
            (BDT 12.4 trillion). Top-performing activities include heavy
            industries, chemicals, machinery and equipment, and F&B. Further
            enhancements to modes of production and scaling of exports in the
            coming years can significantly contribute to doubling Dubai's GDP in
            the next decade – a major goal of the vision 2040 agenda.
          </p>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Operation 300bn: industrial development in the spotlight</h3>
          <p>
            One of the top strategic programmes within the field is Operation
            300bn – a specialised framework comprising several initiatives
            designed to increase industrial contribution to the national GDP.
            <br />
            <br />
            The primary goal is to fast-track the growth of vital sub-sectors
            and outline effective policies to boost local manufacturing
            potential. Financed by the Emirates Development Bank (EDB),
            Operation 300bn also seeks to foster economic competitiveness,
            enhance R&D capabilities and position the UAE as a preferred
            location for industrial innovation.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>The ideal destination for manufacturers </h3>
        <p>
          Manufacturing industries in Dubai enjoy easy access to high-potential
          markets around the world. The city also boasts a skilled and diverse
          workforce, with progressive business policies and regulations.
          Multiple free zones offer comprehensive assistance for business
          set-up, licensing and visas, office spaces, warehouses and logistics
          support.
          <br />
          <br />
          Dubai Industrial City (DIC), one of the largest regional manufacturing
          and logistics hubs, houses hundreds of factories in Dubai. It features
          six zones, each dedicated to a key sub-sector, and creating a
          seamlessly interconnected supply chain.
          <br />
          <br />
          Another important enabler of the manufacturing industry in Dubai is
          the Jebel Ali Free Zone (JAFZA), home to 790+ manufacturers from 73
          countries. It provides everything from logistics facilities to light
          industrial units, warehouses and showrooms, and comprehensive support
          for relevant businesses.
        </p>
      </div>
    </>
  );
}
