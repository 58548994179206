import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function GetStartedPage() {
  return (
    <>
     <NavBar/>
     <Footer/>
    </>
  )
}

export default GetStartedPage