import React, { useState } from 'react';
import { FaFacebookMessenger, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import jkImg from "../../src/assets/images/logo-120x120.png"

function ChatBotComponent() {
  const [showChatBox, setShowChatBox] = useState(false);

  const toggleChatBox = () => {
    setShowChatBox(!showChatBox);
  };

  const closeChatBox = () => {
    setShowChatBox(false);
  };

  return (
    <div className="chatbot-container">
      {/* Messenger Icon */}
      <div className="messenger-icon" onClick={toggleChatBox}>
        <FaFacebookMessenger />
      </div>

      {/* Chat Box */}
      {showChatBox && (
        <div className="chatbot-chat-window">
          {/* Close Button */}
          <div className="close-button" onClick={closeChatBox}>
            <FaTimes />
          </div>

          {/* Header */}
          <div className="chatbot-header d-flex justify-content-between ">
            <img src={jkImg} alt='JK consultancy' />
          </div>

          {/* Chat Messages */}
          <div className="chat-messages">
            {/* Incoming Message */}
            <div className="incoming-message">
              <h5>Chat with JK Associates</h5>
              <p>Hello! How can we assist you today?</p>
            </div>
          </div>

          {/* Message Input */}
          <div className="message-input">
            <Link target="_blank" to="https://m.me/160620561023773">
              <button className='btn btn-primary'>Start Chat</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBotComponent;
