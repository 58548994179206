// FaqTwoColumn.js
import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { splitArray } from "../utils/utils";

export default function NewFaq({ title, colNo, faqItems }) {
  const transFormItems = splitArray(faqItems, colNo);
  return (
    <Container>
      <h2 className="my-4">{title ?? ""}</h2>
      <Row>
        {transFormItems?.map((arrItem, index) => (
         
            <Col style={{padding:0}} key={index} md={12/colNo}>
              <Accordion defaultActiveKey="" className="mb-3">
                {arrItem?.map((faq, index) => (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>{faq?.question}</Accordion.Header>
                    <Accordion.Body>{faq?.ans}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
         
        ))}
      </Row>
    </Container>
  );
}
