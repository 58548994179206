import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function Rmg() {
  return (
    <>
      <Meta
        title="Bangladesh's ready made garment (RMG)"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Bangladesh's Ready Made Garment (RMG)
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h4>
            Bangladesh RMG Industry- A global hub for garments manufacturing and
            sourcing
          </h4>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Renowned for its strong manufacturing capabilities and thriving
            ecosystem, the Bangladesh RMG industry has gone from strength to
            strength and has become a global hub for apparel sourcing. At
            present, the industry consists of over four thousand factories. RMG
            exports from Bangladesh includes a wide variety of knitwear & woven
            garments products such as shirts, trousers, T Shirt, denim, jackets,
            sweaters, etc.
            <br />
            <br />
            Growing at an annual rate of 7%, RMG exports from Bangladesh more
            than doubled between 2011 and 2019. As of December 2020, total
            exports around stood at USD 27.4 billion, capturing a market share
            of 6.30% out of the global apparel export market of $435 billion.
            While the industry suffered a temporary setback due to covid19,
            demand has started to recover and normalize again.
            <br />
            <br />
            Today, Bangladesh leads the world in green garment manufacturing
            with 150 LEED (Leadership in Environmental and Energy in Design)
            certified factories in the country and another 500 waiting to obtain
            the LEED certification from United States Green Building Council
            (USGBC). Currently, nine of the top 10 green garment factories and
            40 of the top 100 are located in Bangladesh. Bangladesh’s RMG sector
            has now transformed into a highly transparent and compliant industry
            regarding factory safety and value-chain responsibility after
            initiatives such as the Accord on Fire and Building Safety in
            Bangladesh, the Alliance for Bangladesh Worker Safety, and the RMG
            Sustainability Council were successfully undertaken.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 className="resource-section-title">Strong technology ecosystem</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>
            <h4>3rd Largest exporter of RMG products in the world in 2020</h4>
            {/* <p>Low taxes and prime location to global markets.</p> */}
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>
            <h4>Exports to more than 150 countries</h4>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <h4>
              Home to the highest number of green garment factories in the
              world.
            </h4>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <h4>Leading in compliance and factory safety</h4>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Advantage Of Bangladesh</h3>
          <p>
            Around 1,430 textile mills are present in Bangladesh which includes
            796 woven fabric manufacturers with a capacity to produce 3,850
            million meters of fabric per annum. In addition, Bangladesh has 240
            dying, printing and finishing mills, which manufacturers 3,170
            million meters of fabric per annum.
            <br />
            <br />
            Bangladesh enjoys duty free access in 52 countries which includes
            the EU, Australia, New Zealand, Norway, Switzerland, Japan, Iceland,
            South Korea, Canada, Chile, India (46 RMG products), Turkey, China.
            Bangladesh is also a member of regional blocs SAPTA, SAFTA, APTA,
            BIMSTEC.
            <br />
            <br />
            At present, six out of the top 10 eco-friendly platinum LEED
            (Leadership in Energy and Environmental Design) certified factories
            worldwide are located in Bangladesh. In addition to having the
            highest number of LEED-certified factories, Bangladesh is also home
            to the highest-rated LEED Platinum denim factory, knitting factory,
            washing plant, and textile mill in the world.
            <br />
            <br />
            All factories maintain international safety and compliance, and
            assessed in accordance with BSCI, WRAP, SMETA, ICS, Higg index, GTW,
            RTM, Join Life, ZDHC, Blue Sign, ISO, OCS, GOTS, Oekotex.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Investment Potential</h3>
        <p>
          The readymade apparel industry constantly caters to changing trends in
          fashion. Over the years, there has been a noticeable increase in use
          of artificial fibres such as polyester, viscose, tencel in apparels
          owing to its lower price, functionality and durability. To keep up
          with the consumer demand, Bangladesh readymade garments manufacturers
          have also started to increase manufacturing garments made of manmade
          fabrics. This increasing demand for artificial fibres presents a great
          investment opportunity for textile producers to set up factories in
          the country and cater to demand for fabrics made from artificial
          manmade fibers. The government is also providing additional incentives
          to encourage investment in man made fibre.
        </p>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Incentives and Policy</h3>
        <div style={{ marginTop: "20px" }}>
          <h5>
            <b>Fiscal Incentives</b>
          </h5>
          <p>
            RMG factories with green building certification will pay income tax
            at a special rate of 10%
            <br />
            <br />
            RMG factories without green building certification will pay income
            tax at a special rate of 12% Under bonded warehousing facility ,
            100% export oriented RMG factories can import raw materials duty
            free at international price.
            <br />
            <br />
            Reduced rate of TDS or withholding tax (Tax Deducted at Source):
            From Export earnings tax will be deducted at 0.5% rate, which is
            generally 1.0%.
            <br />
            <br />
            Firms established between July 1, 2019 and June 30, 2024 are
            eligible for phased or partial tax exemption from 5 up to 10 years.
          </p>
        </div>
      </div>
    </>
  );
}
