import React from "react";
import { FaCaretDown, FaFileInvoiceDollar, FaLightbulb } from "react-icons/fa";
import { FcBusinessman } from "react-icons/fc";
import { MdOutlineMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import categoryicon from "../assets/images/categoryicon.png";
import logoImg from "../assets/images/logo-120x120.png";
import startupiconImg from "../assets/images/startupicon.png";
import worldicon from "../assets/images/worldicon.png";
import SearchForm from "./SearchForm";

function NavBar() {
  return (
    <>
      <header>
        <Link to="/" className="logo">
          <img src={logoImg} width="70px" height="70px" alt="logo" />
        </Link>
        <search className="searchform">
          <SearchForm />
        </search>
        <input type="checkbox" id="menu-bar" />
        <label htmlFor="menu-bar">
          <MdOutlineMenu style={{ fontSize: "45px" }} />
        </label>
        <nav className="navbar-1">
          <ul>
            <li>
              <Link to="#" className="nav-link-1">
                <img src={worldicon} width={17} alt="worldicon" /> Foreign
                Entity <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link
                    className="element"
                    to="/foreign-company-registration-in-bangladesh"
                  >
                    Foreign Company Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/company-winding-up">
                    Company Winding up
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/foreign-direct-investment">
                    Foreign Direct Investment
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/bida-permission">
                    BIDA Permission
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/work-permit-visa">
                    Work Permit VISA
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/private-investment-visa">
                    Private Investment VISA
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/foreign-delegates">
                    Foreign Delegates
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/foreign-company-director-service"
                  >
                    Foreign Company Director Services
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/foreign-company-registered-address"
                  >
                    Foreign Company Registered Address
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="nav-link-1">
                <img src={startupiconImg} width={17} alt="startupiconImg" />{" "}
                Startup <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link
                    className="element"
                    to="/company-formation-in-bangladesh"
                  >
                    Company Formation
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/one-person-company-registration-bangladesh"
                  >
                    One Person Company
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/society-registration-in-bangladesh"
                  >
                    Society Registration
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/partnership-registration-in-bangladesh"
                  >
                    Partnership Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/rjsc-return-filing">
                    RJSC Return Filling
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/loan-consultancy-services">
                    Loan Consultancy
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/import-license-irc">
                    Import License (IRC)
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/export-license-erc">
                    Export License (ERC)
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="element" to="/bsti-permissions">
                    BSTI Permission
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/trade-license">
                    Trade License
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/project-profile">
                    Project Profile
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link className="element" to="/startup-packages">
                    Udokta Package
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="nav-link-1">
                <FaFileInvoiceDollar style={{ color: "#C32DA6" }} /> Taxation{" "}
                <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link className="element" to="/income-tax">
                    Individual Tax
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/corporate-tax">
                    Corporate Tax
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/vat-online-service">
                    Value Added Tax (VAT)
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/tax-identification-number-e-tin"
                  >
                    E-TIN Certificate
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/vat-registration-certificate-in-bangladesh"
                  >
                    BIN Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="#">
                    Audit & Assurance <FaCaretDown />
                  </Link>
                  <ul className="audit-menu">
                    <li>
                      <Link className="element" to="/account-service">
                        Account Service
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/bookkeeping-services/">
                        Bookkeeping Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="element"
                        to="/small-business-in-bangladesh"
                      >
                        Small Business
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="#">
                        Business Software <FaCaretDown />
                      </Link>
                      <ul>
                        <li>
                          <Link className="element" to="/acounting-software">
                            Accounting Software
                          </Link>
                        </li>
                        <li>
                          <Link className="element" to="/erp-software">
                            ERP Software
                          </Link>
                        </li>
                        <li>
                          <Link className="element" to="/vat-software">
                            VAT Software
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="element"
                            to="/hr-management-software"
                          >
                            HR Management Software
                          </Link>
                        </li>
                        <li>
                          <Link className="element" to="/company-portfolio">
                            Company Portfolio
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="nav-link-1">
                <FaLightbulb style={{ color: "#35c931" }} />
                Intellectual Property <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link
                    className="element"
                    to="/trademark-registration-in-bangladesh"
                  >
                    Trademark Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/copyright-registration">
                    Copyright Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/patent">
                    Patent Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/industrial-design">
                    Industrial Design
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/ipr-enforcement">
                    IPR Inforcement
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/btcl-dr">
                    BTCL Domain Registration
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/web-development">
                    Web Development
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="nav-link-1">
                <img src={categoryicon} width={17} alt="categoryicon" /> Others{" "}
                <FaCaretDown />
              </Link>
              <ul className="left-dropdown">
                <li>
                  <Link className="element" to="/civil-litigation">
                    Civil Litigation
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/criminal-litigation">
                    Criminal Litigation
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/fire-license">
                    Fire License
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/factory-license">
                    Factory License
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/customs-bond-license">
                    Custom bond License
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/environment-clearance-certificate"
                  >
                    Environment Clearance Certificate
                  </Link>
                </li>

                <li>
                  <Link className="element" to="/company-winding-up">
                    Company Winding up
                  </Link>
                </li>
                <li>
                  <Link className="element" to="#">
                    Textile License <FaCaretDown />
                  </Link>
                  <ul>
                    <li>
                      <Link
                        className="element"
                        to="/buying-house-licensing-in-bangladesh"
                      >
                        Buying HouseLicense
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/textile-dot-license">
                        Textile (DoT)License
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/epb-license">
                        EPB License (DoT)
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link className="nav-link-1" to="/resource">
                {" "}
                <FcBusinessman /> Resource{" "}
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default NavBar;
