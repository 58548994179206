import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'

function PrivacyPolicy() {
  return (
    <>
             
      <Container style={{ marginTop: '100px', padding: '10px' }}>
        <h2>For Privacy Policy The Information We Collect</h2>
        <p style={{ color: 'black', textAlign: 'justify' }}>
          In general, you can browse our Web site without telling us who you are or revealing any personal
          information about yourself. We are, however, able to determine your IP address. We Care About your
          Privacy Policy.<br /><br />

          jkassociates.com.bd is committed to ensuring the privacy policy of your personal information and
          this privacy notice is designed to tell you which information we collect from you, how this
          information may be used and how it is protected.
        </p>
        <h2>Collection and use of personal information</h2>
        <p style={{ color: 'black', textAlign: 'justify' }}>
          jkassociates.com.bd limits its requests for information to what is required to ensure accurate
          service. Most of the information we collect is very basic and is needed to complete a purchase or
          provide a refund. Examples of user information that may be collected may include your name,
          address, telephone/Cell phone number, e-mail address, a description of the item requested or
          purchased and the IP address of your computer.
        </p>
        <h2>Information Voluntarily Provided</h2>
        <p style={{ color: 'black', textAlign: 'justify' }}>
          There are times when you may provide information to us voluntarily. This occurs when you provide
          your feedback to us though e-mails return forms, letters or telephone calls. We use this
          information to respond to your query and to keep track of customer feedback.<br /><br />

          Even if you do not make a purchase on our site, we have the ability to track usage of our web
          site – including the server from which the site was visited. This information is not used to
          track information about individuals, but is used on an aggregate level to customize our site,
          improve the on-line experience and plan advertising and promotions.
        </p>
        <h2>Use of Google Analytics</h2>
        <p style={{ color: 'black', textAlign: 'justify' }}>
          This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”).
          Google Analytics “cookies”, which are text files placed on your computer, to help the website
          analyze how users use the site. The information generated by the cookie about your use of the
          website will be transmitted to and stored by Google servers in the United States.<br /><br />

          Google will use this information on behalf of the operator of this website for the purpose of
          evaluating your use of the website, compiling reports on website activity for website operators
          and providing it other services relating to website activity and internet usage.
        </p>
        <h2>Disclosure and retention of personal information</h2>
        <p style={{ color: 'black', textAlign: 'justify' }}>
          jkassociates.com.bd will share your personal information internally with those staff members who
          need it to complete your purchase or carry out your instructions regarding the receipt of
          marketing information. We will not disclose your personal information to any third party without
          your written consent unless required to do so by law.<br /><br />

          jkassociates.com.bd keeps your personal information for only as long as required to fulfil the
          purpose for which it was collected.
        </p>
        <h2>Protection of personal information privacy Policy</h2>
        <p style={{ color: 'black', textAlign: 'justify' }}>
          jkassociates.com.bd gives access to your personal information those staff members, who need your
          personal information in order to respond to your order or request. Employees are provided with
          training and information regarding the proper handling of personal information. All information
          stored in our computer system is protected from unauthorized access and information that is stored
          in document form is kept in secure locations to prevent access by unauthorized persons.
        </p>
      </Container>
             
    </>
  )
}

export default PrivacyPolicy