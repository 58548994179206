export const services = [
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Property Buying",
      description: "Assist clients in finding and purchasing their dream properties."
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Property Selling",
      description: "Help clients sell their properties quickly and for the best possible price."
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Property Management",
      description: "Offer comprehensive property management services, including rent collection, maintenance, and tenant screening."
    },
    // Add more services as needed
  ];