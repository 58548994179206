import React from "react";
import { Link } from "react-router-dom";
import CeoImg from "../../assets/images/CEO.png";
import HadiImg from "../../assets/images/hadi_jk_associates.png";
import Logo from "../../assets/images/JK Associates Log.png";
import NabilImg from "../../assets/images/nabil-jk.png";
import "./RotatingCircle.css"; // Import custom CSS for animation
const RotatingCircle = () => {
  return (
    <div className="circle-container d-flex justify-content-center align-items-center">
      <div className="circle-wrapper">
        <div className="center-image">
          <img src={Logo} alt="center" className="center-img" />
        </div>

        {/* First Rotating Circle */}
        <div className="outer-circle circle1">
          <div className="service-img">
            <img src={NabilImg} alt="center" className="center-img" />
          </div>
          <div className="service-img">
            <img src={CeoImg} alt="center" className="center-img" />
          </div>
          <div className="service-img">
            <img src={HadiImg} alt="center" className="center-img" />
          </div>
          {/* <div className="service">HRIS</div>
          <div className="service">Employer of Record</div>
          <div className="service">Global Payroll</div> */}
        </div>

        {/* Second Rotating Circle */}
        <div className="outer-circle circle2">
          <Link to="/company-formation-in-bangladesh" className="service">
            Business Registration
          </Link>
          <Link
            to="/foreign-company-registration-in-bangladesh"
            className="service"
          >
            Foreign Entity
          </Link>
          <Link to="/income-tax" className="service">
            Taxation
          </Link>
          <Link to="/rjsc-return-filing" className="service">
            Regulatory Affairs
          </Link>
          <Link to="/account-service" className="service">
            Accounting Service
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RotatingCircle;
