import React from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import environmentImg from "../../assets/images/Environmental.png";
import environment_og_Img from "../../assets/og-img/Environment Clearance Certificate.png";
import Faq from "../../components/Faq";
import Meta from "../../components/Meta";
import { intellectualRelatedArticales } from "../../data/relatedArticale";

function CompanyDissolution() {
  const faqItems = [
    {
      title: "Who needs to know that I’m dissolving the company?",
      content: <strong>Under the requirements of the Companies Act 2016, you must, within seven days, inform anyone with an interest or stake in the continued running of your business. These people and companies include but are not limited to shareholders within the business, banks, creditors, landlords,suppliers, guarantors, HMRC, employees, any person suing for a personal injury claim against you, employee pension fund managers, and directors who have not signed the DS01 form.</strong>,
    },
    {
      title: "Can I change my mind?",
      content: <strong>Once you have started the dissolution process, you can stop it by using a DS02 form from Companies House – this can be done online or with a paper form.You will also need to fill in a DS02 form if you start trading again, change your company name, carry out an activity that’s not related to the dissolution of your business, or your business becomes subject to insolvency proceedings or a section 900 application</strong>,
    },
    {
      title: "Are there any tax considerations I should know about?",
      content: <strong>If your business assets are worth £25,000 or less, you may distribute them prior to your company’s dissolution. This might be treated by HMRC as a capital gain. If your business assets are worth over £25,000, you may pay it to yourself and other shareholders in the form of a dividend. In both cases, Entrepreneurs Relief might be applicable and, if so, you would only pay 10% tax (subject to a lifetime limit of £10m).
      This is a complicated area of taxation so we recommend that you consult with an accountant before deciding on a course of action.
      </strong>,
    },
    {
      title: "How long does the process take after the submission of the DS01 form?",
      content: <strong>Between three to six months.</strong>,
    },
    {
      title: "What support do I get from Your Company Formations?",
      content: <strong>We’re committed to providing our customers with the highest level of support. Please feel free to contact us before, during, or after you have used us to change your company name by phone or email.</strong>,
    },
  ];
  return (
    <>
      <Meta
        title="Winding up Procedure in Bangladesh | Company Dissolution"
        description="The business owners choose to dissolve a company. Voluntary dissolution, sometimes called striking a company off or winding a company up, is a slow process that can be taken by any business"
        image={environment_og_Img}
        keywords="company dissolution, winding up a company, striking a company off, Voluntary dissolution, dissolve a company"
      />

      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">
                Company Winding up
              </h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Intellectual Property</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Company Winding up
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img
                className="img-fluid animated pulse infinite"
                style={{ animationDuration: "3s" }}
                src={environmentImg}
                alt="copyRight-Registration-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>Company dissolution / winding up procedure in Bangladesh</b>
          </p>
          <p>
            From shareholders deciding they wish to retire to the assets and
            goodwill of a company being sold to a new owner (as opposed to a
            share sale), there are many reasons why business owners choose to
            dissolve a company. Voluntary dissolution, sometimes called striking
            a company off or winding a company up, is a slow process that can be
            taken by any business as long as it has not, in the previous three
            months
          </p>
        </div>
        <p>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>carried out its normal business activities</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>changed its name</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              carried out any activity not related to the winding up of the
              business. Activities which do not obstruct an application to
              dissolve a company include selling property or rights that a
              company needed when it traded and settling its debts
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>been threatened by creditors with liquidation</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              entered into a credit agreement like a company voluntary
              arrangement
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>been the subject of any legal proceedings</span>
          </div>
        </p>
        <p>
          Winding up of the Company is referred to as the legal mechanism of
          permanently shutting down a company. It is a procedure by which the
          Company ends its existence as a separate legal entity after filing for
          dissolution under the supervision of a liquidator. During this
          critical period of the Company’s existence, the Liquidator oversees
          and manages its assets to ensure that the interests of the
          stakeholders are not compromised. Dissolution eventually takes place,
          wherein the Registrar of Companies dissolves the Company and strikes
          the name from the register. As a result, the Company’s existence ends.
          <br />
          <br />
          According to Section 245 of the Companies Act of 1994 (“Companies
          Act”), a petition for winding up may be submitted by a creditor, the
          company, or contributory, either together or separately. Each current
          and former member of the company shall be obligated to contribute to
          an amount adequate to cover the company’s debts, liabilities, charges,
          and liquidation expenditures in the case of the company’s winding up,
          according to Section 235 of the Companies Act.
          <br />
          <br />
          The term “contributory” is defined in Section 237 to signify that
          everyone is obligated to contribute to a company’s assets in the case
          of a winding-up. However, if the former member has not been a member
          for more than a year prior to the start of the winding up or for any
          obligations of the contractual business since he has not been a
          member, or if the current members are unable to make the required
          contribution, the former member shall be exempt from the duty of
          making such contribution. However, in the event of a limited company,
          no member (present or past) shall be obliged to contribute the sum in
          excess of any unpaid on the shares for which he is accountable. In the
          event of a limited company’s dissolution, directors’ liability
          (present or past) is unlimited.
          <br />
          <br />
          As per Sections 239 and 240 of the Companies Act, in the event of
          death of a contributor, their representatives and heirs are
          responsible for making a contribution to the company’s assets. On the
          other hand, in the event of insolvency, the assignee shall act in the
          capacity of the contributor, and the expense of any payment made in
          the form of such contribution shall be borne by the estate of the
          insolvent.
        </p>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  ">
              <img
                className="img-fluid"
                src={environmentImg}
                alt="Copyright-Registration-Process"
              />
            </div>
            <div className="col-lg-6  ">
              <div className="h-100">
                <p className="color-text fs-5 mb-4">
                  Procedure for Winding up of a Company
                </p>
                <p>
                  The liquidation process starts with filing a petition under
                  the Company Act, after which the court fixes a liquidator.
                  Also, the order of winding up has to be filed in the Registrar
                  of the Joint Stock Companies and Firms. There are several
                  grounds for winding up a company- the case of Rohimuddin Ahmed
                  Vs. Bengal Water Ways Ltd., (1979) 31 DLR 28 states some
                  grounds which justify the dissolution of the company under
                  Section 162 of the Companies Act, 1913 (corresponding to sec
                  241 of the Companies Act, 1994). In this case, three grounds
                  were established for winding up a company- firstly if the
                  default is made in filing a statutory report, secondly in
                  holding statutory meetings, thirdly if the company is unable
                  to pay its debt or if the Court is of opinion that it is just
                  and equitable that the company should be wound up. Further to
                  this, Section 241 mentions other grounds for winding up, that
                  were not mentioned in the case i.e. if the company has by
                  special resolution resolved that the company be wound up by
                  the Court, if the company does not commence its business
                  within a year from its incorporation, or suspends its business
                  for a whole year; or if the number of members is reduced, in
                  the case of a private company below two, or, in the case of
                  any other company, below seven.
                  <br />
                  <br />
                  Under Section 234 of the Companies Act, there are three modes
                  of winding up a company in Bangladesh which may be either by
                  <b> the court</b> or <b>voluntarily</b> or{" "}
                  <b>subject to the supervision of the court</b>
                  <br />
                  <br />
                  According to section 245 of the Companies Act, a creditor, the
                  company, or a contributory may submit a winding-up petition
                  jointly or separately or by the Registrar. Section 235 of the
                  Companies Act states that in the event of a company’s winding
                  up, each of the entity’s current and previous owners will be
                  required to contribute money sufficient to pay the company’s
                  debts, obligations, fees, and liquidation expenditures.
                  According to Section 237, the term contributing signifies that
                  every person is obligated to contribute to a company’s assets
                  in the event of its dissolution. For example, in the case of
                  Prime Finance and Investment Ltd vs Delwar H Khan 15 BLC (AD)
                  170, the High Court Division was very much conscious of the
                  broad fact of huge loan liabilities of the Company and was on
                  the view that the same has to be put at halt accordingly the
                  court allowed the application for winding up of the Company
                  and appointed official receiver as the liquidator and passed
                  other incidental orders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-0">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            Process of Liquidation or Winding up in Bangladesh
          </p>
          <p>
            <div>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span style={{ fontWeight: "bold" }}>
                  Step 1: Filing Petition to Court
                </span>
              </div>
              <p>
                A petition must be filed with the company court in the High
                Court Division of the Supreme Court of Bangladesh in order for a
                court to wind up a company. It should be emphasized that the
                court may wind up a company, and that process will be deemed to
                start when the petition for winding up is presented. The court
                will issue an order for the company’s liquidation after hearing
                the application.
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span style={{ fontWeight: "bold" }}>
                  Step 2: Notification to Registrar
                </span>
              </div>
              <p>
                Within 30 (thirty) days following the request date, the
                petitioner and the company are required to file a copy of the
                winding-up order with the Registrar. Upon submitting a copy of a
                winding-up order, the Registrar is required to record a summary
                in his company-related books. The official Gazette is then
                informed by the registrar that such an order has been made.
                Except in cases where the company’s operations are continuing,
                such an order shall be deemed a notice of discharge to the
                employees of the company.
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span style={{ fontWeight: "bold" }}>
                  Step 3: Appointment of Liquidator
                </span>
              </div>
              <p>
                The official liquidator will then be appointed by the court and
                the liquidator shall conduct their duties in accordance with the
                Companies Act. All of the company’s assets and effects in the
                event of a court-ordered winding up shall be deemed to be in the
                custody of the Court from the date of the order for the winding
                up of the company.
              </p>
            </div>
            <div>
              <div className="d-flex align-items-center mb-2">
                <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                <span style={{ fontWeight: "bold" }}>
                  Step 4: Information recorded with RJSC
                </span>
              </div>
              <p>
                The Court shall issue an order dissolving a company effective
                from the date of the order when all of the company’s affairs
                have been fully wound up. Within 15 (fifteen) days of the order,
                the official liquidator must notify the order to the registrar.
                A minute of the company’s dissolution must be entered by the
                registrar in his books.
              </p>
            </div>
          </p>
          <p className="color-text fs-5 mb-4">
            <b>A company may wind up voluntarily</b>
          </p>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              When the period, if any, specified in the articles of
              incorporation for the duration of the company expires or an event
              occurs for which the articles provide that the company is to be
              dissolved, and the company in general meeting has passed a
              resolution for winding up voluntarily
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              if the company resolves by special resolution that the company be
              wound up voluntarily
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              if the company resolves by extraordinary resolution that it cannot
              continue due to its liabilities
            </span>
          </div>
          <p>
            It should be emphasized that when the resolution for voluntary
            winding up is passed, the voluntary winding up process is assumed to
            have started.
            <br />
            <br />
            When a company has decided to dissolve voluntarily through a special
            or extraordinary resolution, the court may order that the voluntary
            liquidation proceed but under its supervision and on the terms it
            deems appropriate.
            <br />
            <br />
            However, if the requirements of Section 241 of the Companies Act are
            met, then the petitioner shall not be disallowed from doing so just
            because other equally effective remedies are available to them, as
            held in the case of Amir Hossain Vs. Homeland Footwear Ltd and
            others, 55 DLR 478.
            <br />
            <br />
            It is important to note that when an order of winding up is made at
            the discretion of the court, no suit or legal proceeding shall be
            pursued without the court’s permission as per Section 250 of the
            Company Act.
            <br />
            <br />
            Section 242 of the Companies Act considers whether a company is
            determined to be unable to pay its debts. This will be the case if
            the company owes a debt to a creditor and fails to pay the debt for
            three weeks, if an execution or other proceeding issued in
            accordance with a court order or decree in favour of a company
            creditor is returned unsatisfied in whole or in part, or if it is
            convincingly shown to the court that the company is unable to pay
            its debts and the court is required to take account of them.
            <br />
            <br />
            Section 316-321 of the Companies Act deals with judiciary
            supervision. The court may issue an order requiring the voluntary
            winding up to take place but be subject to scrutiny from the court
            and any conditions the court deems necessary in cases when a company
            has agreed to dissolve voluntarily through unique or exceptional
            measures. The liquidator may exercise all the powers, subject to any
            restrictions given by the court, as if the company were being wound
            up voluntarily entirely when an order for winding up is made subject
            to supervision.
          </p>

          <p className="color-text fs-5 mb-4">
            <b>Company dissolution / winding up service Bangladesh</b>
          </p>
          <div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span style={{ fontSize: "17px" }}>
                Completion and submission of DS01 form and payment of the fee to
                Companies House
              </span>
            </div>
            <p>
              Companies House require and the completion and submission of form
              DS01 to start the company dissolution process. We make this
              payment on your behalf when you use our service.
            </p>
          </div>
          <div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span style={{ fontSize: "17px" }}>
              Completion of board minutes documentation
              </span>
            </div>
            <p>
            Our legal experts write up the minutes of a board meeting showing that a vote has been passed to dissolve the company. You will need to hold onto these records as well as others (detailed below).
            </p>
          </div>
        </div>
      </div>

      <div className="container-xxl center-background1">
        <h2> Related Articles</h2>
      </div>
      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Copyright FAQ</h2>
        <Faq items={faqItems} />
      </div>
      <div className="container-xxl center-background1">
        <Marquee className="marquee-container">
          {intellectualRelatedArticales}
        </Marquee>
      </div>

      
    </>
  );
}

export default CompanyDissolution;
