import React from 'react'
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SlideShare() {
    const slides = [
        // { id: 1, content: 'Slide 1', imageUrl: 'your_image_url_1.jpg' },
        // { id: 2, content: 'Slide 2', imageUrl: 'your_image_url_2.jpg' },
        // Add more slides as needed
        {
            id: 1, content: <div>
                <p className="slidep">
                    They are very cooperative and detail about the codes and conduct. I have completed
                    my company incorporation and others with very cost efficiently. For all the legal
                    and taxing services in the future, we don't see any other alternatives.
                </p>
                <p className="slidep slidepb">
                    Company Incorporation Services with very cost efficently
                </p>
            </div>
        },
        {
            id: 2, content: <div>
                <p className="slidep">
                    I am really satisfied with their services.
                </p>
                <p className="slidep slidepb">
                    Saad Bin Saif, Managing Director, Ogroni Informatrix Limited - Dhaka, Bangladesh
                </p>
            </div>
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Auto-play slides
        autoplaySpeed: 3000, // Set auto-play speed in milliseconds (adjust as needed)
        centerMode: true, // Center align the slides
        centerPadding: '0', // Adjust padding for center alignment
    };
    return (
        <>
            <div>
                <Slider {...settings}>
                    {slides.map((slide) => (
                        <div key={slide.id}>
                            {slide.content}
                            {/* <img src={slide.imageUrl} alt={`Slide ${slide.id}`} />
            <p>{slide.content}</p> */}
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    )
}

export default SlideShare