import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function RealState() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Construction and real estate
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            Market-leading property sector
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai is one of the most sought-after property markets in the world.
            Its dynamic real estate landscape boasts world-class infrastructure,
            backed by a visionary approach to urban planning. The immense
            investment potential offered by the city’s property sector is only
            underscored by the recent surge in property transactions and plot
            sales.
            <br />
            <br />
            In 2023, the city set a new record with the highest-ever number of
            real estate transactions. The total value of property deals hit
            US$172.6 billion (AED634 billion) from 166,400 transactions, an
            annual growth of 20%. New investments grew by 55%, with more than
            42% stemming from non-resident foreign investors.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Growth in the Economy</h4>
            <p>
              International real estate developers will be increasingly
              interested in Dubai's real estate sector this decade as demand
              increases.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Regulated real estate market</h4>
            <p>
              Dubai’s real estate market is governed by firm laws, which provide
              a protected legislative and regulatory structure for businesses.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Tax-free investments</h4>
            <p>
              A property tax exemption in Dubai attracts property investors from
              around the world who want to diversify their portfolios.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Long-term residency</h4>
            <p>
              Property Investors who meet the eligibility criteria for the
              Golden visa residency may qualify for a 10-year residency.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Growing economic momentum</h3>
          <p>
            The UAE is currently the region’s second-largest construction
            projects market, with over US$356 billion (AED1.3 trillion) in
            private real estate developments, ongoing public housing and
            building projects. The sector is set for continued growth,
            anticipated to reach US$50.40 billion (AED185.12 billion) by 2029.
            <br />
            <br />
            This growth is a testament to Dubai's rise as the 'city of the
            future', a pivotal gateway to the world's fastest-growing markets
            and a premier destination for investment and development.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Building real estate excellence</h3>
        <p>
          The Dubai Strategic Plan 2026 aims to position Dubai as a global real
          estate leader. It cultivates effective governance, innovation and
          cutting-edge technologies to pioneer real estate solutions, establish
          a data-driven ecosystem, and promote investment confidence.
          <br />
          <br />
          With supportive regulations, a digital-first real estate sector, data
          collection, and professional development, the Dubai Land Department
          will become a world leader in attracting real estate investments.
        </p>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Preparing for a growing populace</h4>
        <p>
          Dubai's population is projected to reach 5.8 million by the end of
          this decade. As a proactive response, the city has unveiled the Dubai
          2040 Urban Master Plan, which promotes sustainable urban development,
          improving the quality of life for residents and boosting Dubai's
          appeal as a leisure and business destination.
        </p>
      </div>
      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>60% of Dubai's land area</h3>
            <p style={{ marginBottom: "0px" }}>
              will comprise nature reserves & rural areas by year 2040.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>+16% year-on-year price rise</h3>
            <p style={{ marginBottom: "0px" }}>
              in Dubai's property market, second fastest worldwide.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Enhancing sustainability within urban spaces</h4>
        <p>
          Dubai plans to double its green and recreational spaces, dedicating
          more than half the available area to nature reserves and rural nature
          areas. It will also establish green corridors to seamlessly connect
          service areas, residential areas and workplaces, facilitating
          sustainable pedestrian and vehicular mobility.
          <br />
          <br />
          Dubai is also expected to see significant growth in hotel and tourism
          activities, with the UAE hospitality market predicted to reach US$9.45
          trillion (AED34.7 trillion) by 2028. To prepare for this, the city
          aims to expand the space dedicated to hotels by 134%. Education and
          health facilities in the city will be expanded by 25%, along with
          public beaches being extended by 400%.
        </p>
      </div>
    </>
  );
}
