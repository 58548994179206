// src/BlogCard.js
import React from 'react';

const BlogComponent = ({ title, summary, author, date, image }) => {
    return (
        <div className="blog-card">
            <img src={image} alt={title} className="blog-image" />
            <h2>{title}</h2>
            <p>{summary}</p>
            <div className="blog-info">
                <span>By {author}</span>
                <span>{date}</span>
            </div>
        </div>
    );
};

export default BlogComponent;
