import React from "react";
import company_Formation_og_img from "../assets/og-img/company-formation.png";
import Meta from "./Meta";

export default function Partnership() {
  return (
    <>
      <Meta
        title="Partnership Business Registration in Bangladesh | Partnership Agreement"
        description="A partnership firm can be registered by rjsc an application in Form No. 1. Along with the incorporation certificate, the registration fee bank slip and a true copy of the partnership agreement"
        image={company_Formation_og_img}
        keywords="partnership firm Formation, partnership firm Registration, partnership business Bangladesh, Partnership Agreement, Partnership Business Registration"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <h1 style={{ marginBottom: "20px" }} className="color-text display-6">
            Partnership
          </h1>
          <p>
            A partnership is a type of business structure where two or more
            partners start an entity to do business. For a partnership to exist,
            there must always be two or more partners.
            <br />
            <br />A Partnership is defined by the Partnership Act, 1932, (the
            “Partnership Act”) as ‘the relation between persons who have agreed
            to share profits of the business carried on by all or any of them
            acting for all’. This definition gives three minimum requirements to
            constitute a partnership:
          </p>
          <ol style={{ color: "#5c5a5a" }}>
            <li>
              there must be an agreement entered into orally or in writing by
              the persons who desire to form a partnership,
            </li>
            <li>
              the object of the agreement must be to share the profits of
              business intended to be carried on by the partnership, and
            </li>
            <li>
              the business must be carried on by all the partners or by any of
              them acting for all of them. The term ‘person’ is not defined by
              the Partnership Act.
            </li>
          </ol>
          <p>
            It is not compulsory to register your partnership firm as there are
            no penalties for non-registration. However, it is advisable since
            the following rights are denied to an unregistered firm:
          </p>
          <ul style={{ color: "#5c5a5a" }}>
            <li>
              A partner cannot file a suit in any court against the firm or
              other partners for the enforcement of any right arising from a
              contract or right conferred by the Partnership Act
            </li>
            <li>
              A right arising from a contract cannot be enforced in any Court by
              or on behalf of your firm against any third party
            </li>
            <li>
              Further, the firm or any of its partners cannot claim a set off
              (i.e. mutual adjustment of debts owned by the disputant parties to
              one another) or other proceedings in a dispute with a third party.
            </li>
          </ul>
          <h2 style={{ marginBottom: "20px",fontSize:"30px" }} className="color-text display-6">
            Partnership – Quick Facts
          </h2>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Liability
          </p>
          <ul style={{ color: "#5c5a5a" }}>
            <li>
              A partnership is considered as a separate legal identity (i.e.
              separate from its owners) in Bangladesh only if the partnership is
              registered.
            </li>
            <li>
              All the partners of a partnership are liable severally and jointly
              for the liability of the partnership.
            </li>
            <li>
              The concept of Limited Liability Partnership does not exist in
              Bangladesh.
            </li>
          </ul>
          <p
            className="color-text mb-4"
            style={{ fontSize: "23px", marginTop: "20px" }}
          >
            Taxation{" "}
          </p>
        </div>

        <p>
          From a tax perspective, partnerships in Bangladesh are not taxed at
          the entity level and profits are treated as part of each partners’
          personal income and are taxed at personal income tax rates.
        </p>

        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Members & Management
        </p>
        <ul style={{ color: "#5c5a5a" }}>
          <li>
            There must be a minimum of 2 partners and maximum of 20 partners.
          </li>
          <li>The partners can be natural persons or companies.</li>
          <li>
            Unlike private or public limited companies, a partnership in
            Bangladesh does not have directors, shareholder or secretary,
            instead the partners own and run the business.
          </li>
        </ul>
        <h2 style={{ marginBottom: "20px",fontSize:"30px" }} className="color-text display-6">
          Partnership – Documents Required
        </h2>
        <p>
          In order to register a partnership in Bangladesh, the following
          information/documents are needed:
        </p>
        <ul style={{ color: "#5c5a5a" }}>
          <li>Proposed partnership name;</li>
          <li>Partnership agreement duly notarized;</li>
          <li>Form I;</li>
          <li>Particulars of the partners;</li>
          <li>Residential address of the partners;</li>
          <li>Details of the registered address for the partnership; and</li>
          <li>Percentage of the share of profit of each partner.</li>
        </ul>
        <h2 style={{ marginBottom: "20px",fontSize:"30px" }} className="color-text display-6">
          Partnership – Registration Procedure
        </h2>
        <p>
          A partnership may be registered with Registrar of Joint Stock
          Companies and Firm of Bangladesh (“RJSC”).
          <br />
          <br />
          The partnership registration process consists of two steps: a) name
          reservation; and b) registration of the entity. Under normal
          circumstances, a partnership registration can be completed within
          one/two days.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Step 1- Choosing the Partnership Name
        </p>
        <p>
          The partners are free to choose any name as they desire for their
          partnership firm subject to the following rules:-
        </p>
        <ul style={{ color: "#5c5a5a" }}>
          <li>
            The names must not be too identical or similar to the name of
            another existing firm doing similar business so as to lead to
            confusion. The reason for this rule being that the reputation or
            goodwill of a firm may be injured, if a new firm could adopt an
            allied name.
          </li>
          <li>
            The name must not contain certain words expressing or implying the
            sanction, approval or patronage of Govt.
          </li>
        </ul>
        <p>
          Once you select a name, you should apply for name clearance using the
          website of RJSC.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Step 2- prepare a Partnership Deed
        </p>
        <p>
          You should prepare a partnership deed. Usually, a partnership deed
          contains the following clauses:
        </p>
        <ul style={{ color: "#5c5a5a" }}>
          <li>Name and Address of the firm as well as all the partners;</li>
          <li>Nature of business to be carried on;</li>
          <li>Date of Commencement of business;</li>
          <li>
            Duration of Partnership (whether for a fixed period/indefinite
            time);{" "}
          </li>
          <li>Capital contribution by each partner; </li>
          <li>Profit sharing ratio among the partners; </li>
          <li>
            Rules to be followed in case of retirement, death and admission of a
            partner; and
          </li>
          <li>
            The above are the minimum essentials which are required in all
            partnership deeds. The partners may also mention any additional
            clauses.
          </li>
        </ul>
        <p>
          The Partnership Deed should be on a stamp paper in accordance with the
          Stamp Act and should be signed by all partners. Then it should be
          notarized.
        </p>
        <p
          className="color-text mb-4"
          style={{ fontSize: "23px", marginTop: "20px" }}
        >
          Step 3- Register Partnership deed with RJSC
        </p>
        <p>
          The partnership deed and filled up Form I should be filed with RJSC.
          These documents will be reviewed by the officials of the RJSC. When
          the officials are satisfied with the points stated in the partnership
          deed, he shall record an entry of the statement in a register called
          the Register of Firms and issue a Certificate of Registration.
        </p>
      </div>
    </>
  );
}
