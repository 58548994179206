import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function SiteMap() {
    return (
        <>
                   
            <container >
                <center>
                    <h2 style={{ marginTop: '20px' }}>Sitemap</h2>
                </center>
                <div style={{ marginLeft: '20px', padding: '80px', backgroundColor: '#f0f0f0', color: '#008080', fontSize: '20px' }}>
                    <ul>
                        <li>Home</li>
                        <li>Client Login</li>
                        <li>Startup</li>
                        <ul>
                            <li>Company Formation</li>
                            <li>One Person Company</li>
                            <li>Society Registration</li>
                            <li>Partnership Registration</li>
                            <li>Import License (IRC)</li>
                            <li>RJSC Return Filing</li>
                            <li>Loan Consultancy</li>
                            <li>Export License (ERC)</li>
                            <li>BSTI Permission</li>
                            <li>Trade License</li>
                            <li>Project Profile</li>
                            <li>উদ্যোক্তা প্যাকেজ</li>
                        </ul>
                        <li>Taxation</li>
                        <ul>
                            <li>Individual Tax</li>
                            <li>Corporate Tax</li>
                            <li>Value Added Tax (VAT)</li>
                            <li>E TIN Certificate</li>
                            <li>BIN Registration</li>
                            <li>Audit</li>
                            <ul>
                                <li>Account Service</li>
                                <li>Bookkeeping Services</li>
                                <li>Small Business</li>
                                <li>Business Software</li>
                                <ul>
                                    <li>Accounting Software for SME</li>
                                    <li>ERP Software</li>
                                    <li>VAT Softwareo</li>
                                    <li>HR Management Software</li>
                                    <li>Company Portfoli</li>
                                </ul>
                            </ul>
                        </ul>
                        <li>Intellectual Property</li>
                        <ul>
                            <li>Trademark</li>
                            <li>Copyright</li>
                            <li>Patent</li>
                            <li>Industrial Design</li>
                            <li>IPR Enforcement</li>
                            <li>BTCL Domain Registration</li>
                            <li>Web Development</li>
                        </ul>
                        <li>Litigation</li>
                        <ul>
                            <li>Civil Litigations</li>
                            <li>Criminal Litigations</li>
                        </ul>
                        <li>Foreign Entity</li>
                        <ul>
                            <li>Foreign Company Formation</li>
                            <li>Foreign Direct Investment (FDI)</li>
                            <li>BIDA Permission</li>
                            <li>Work Permit VISA in Bangladesh</li>
                            <li>Foreign Delegates</li>
                            <li>Company Registered Address</li>
                            <li>Foreign Company Director Services</li>
                        </ul>
                        <li>Regulatory Licenses</li>
                        <ul>
                            <li>Fire License</li>
                            <li>Factory License</li>
                            <li>Customs Bond License</li>
                            <li>Environment Clearance Certificate</li>
                        </ul>
                        <li>About Us</li>
                        <li>Pay Online</li>
                        <li>Consultancy Shop</li>
                        <li>Contacty</li>
                        <li>Recent Work</li>
                        <li>Refund & Returns Policy</li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </container>
                   
        </>
    )
}

export default SiteMap