export const licenseList = [
    {
        licenseName: "Trade License",
        licenseLink: "/trade-license",
        agency: "All City Corporations, Municipalities & Union Parisads",
        agencyLink: ""
    },
    {
        licenseName: "Tax Identification Number(TIN) Individual",
        licenseLink: "/tax-identification-number-e-tin",
        agency: "National Board of Revenue (NBR)",
        agencyLink: "https://nbr.gov.bd/"
    },
    {
        licenseName: "Incorporation Certificate for Limited Company",
        licenseLink: "/",
        agency: "Registrar of Joint Stock Companies And Firms",
        agencyLink: ""
    },
    {
        licenseName: "Incorporation Certificate for Partnership Firms",
        licenseLink: "/",
        agency: "Registrar of Joint Stock Companies And Firms",
        agencyLink: ""
    },
    {
        licenseName: "Tax Identification Number (TIN) For Entities",
        licenseLink: "/tax-identification-number-e-tin",
        agency: "National Board of Revenue (NBR)",
        agencyLink: "https://nbr.gov.bd/"
    },
    
    {
        licenseName: "VAT Registration Certificate",
        licenseLink: "/vat-online-service",
        agency: "National Board of Revenue (NBR)",
        agencyLink: "https://nbr.gov.bd/"
    },
    {
        licenseName: "Association Membership Certificate",
        licenseLink: "/vat-online-service",
        agency: "Government Approved Association (BMEA,BKMEA,DCCI,FBCCI) etc",
        agencyLink: "https://nbr.gov.bd/"
    },
    {
        licenseName: "Import Registration Certificate (IRC)",
        licenseLink: "/import-license-irc",
        agency: "Office of the Chief Controller of Imports & Exports (CCI&E)",
        agencyLink: "https://ccie.gov.bd/"
    },
    {
        licenseName: "Export Registration Certificate (ERC)",
        licenseLink: "/export-license-erc",
        agency: "Office of the Chief Controller of Imports & Exports (CCI&E)",
        agencyLink: "https://ccie.gov.bd/"
    },
    {
        licenseName: "Ad-hoc IRC/ BIDA Recommendation / BIDA Suparishnama",
        licenseLink: "/bida-permission",
        agency: "Bangladesh Investment Development Authority (BIDA)",
        agencyLink: "https://bida.gov.bd/"
    },
    {
        licenseName: "Indenting Registration Certificate (IRC)",
        licenseLink: "",
        agency: "Office of the Chief Controller of Imports & Exports (CCI&E)",
        agencyLink: "https://ccie.gov.bd/"
    },
    {
        licenseName: "Premises License ",
        licenseLink: "/bsti-permissions",
        agency: "Civil Surgeon Office",
        agencyLink: "https://www.bsti.gov.bd/"
    },
    {
        licenseName: "Quality Certification Mark",
        licenseLink: "/bsti-permissions",
        agency: "Bangladesh Standard and Testing Institution (BSTI)",
        agencyLink: "https://www.bsti.gov.bd/"
    },
    {
        licenseName: "Approval of Factory Plan",
        licenseLink: "/factory-license",
        agency: "Department of Inspection for Factories and Establishments (DIFE)",
        agencyLink: "https://dife.gov.bd/"
    },
    {
        licenseName: "Certificate of Registration of Factories and Establishment",
        licenseLink: "/factory-license",
        agency: "Department of Inspection for Factories and Establishments (DIFE)",
        agencyLink: "https://dife.gov.bd/"
    },
    {
        licenseName: "Bonded Warehouse License",
        licenseLink: "",
        agency: "Customs Bond Commissionerate (CBC)",
        agencyLink: ""
    },
    {
        licenseName: "Explosives Licenses",
        licenseLink: "",
        agency: "Department of Explosives",
        agencyLink: ""
    },
    {
        licenseName: "Registration Certificate from Inspector of Boiler",
        licenseLink: "",
        agency: "Office of the Chief Inspector of Boiler (CIOB)",
        agencyLink: ""
    },
    {
        licenseName: "Environmental Clearance Certificate",
        licenseLink: "",
        agency: "Department of Environment (DOE)",
        agencyLink: ""
    },
    {
        licenseName: "Fire License",
        licenseLink: "",
        agency: "Fire Service and Civil Defense (FS&CD)",
        agencyLink: ""
    },
    {
        licenseName: "Acid License",
        licenseLink: "",
        agency: "Deputy Commissioner (DC) of all Districts",
        agencyLink: ""
    },
    {
        licenseName: "Construction Certificate",
        licenseLink: "",
        agency: "RAJUK/CDA/KDA",
        agencyLink: ""
    },
    {
        licenseName: "Registration Certificate of Designs",
        licenseLink: "",
        agency: "Department of Patent, Design and Trade Marks (DPDT)",
        agencyLink: ""
    },
    {
        licenseName: "Registration Certificate of Patent",
        licenseLink: "",
        agency: "Department of Patent, Design and Trade Marks (DPDT)",
        agencyLink: ""
    },
    {
        licenseName: "Trade Marks Registration",
        licenseLink: "",
        agency: "Department of Patents, Designs & Trademarks (DPDT)",
        agencyLink: ""
    },
    {
        licenseName: "Copyright Registration",
        licenseLink: "",
        agency: "Copyright Office",
        agencyLink: ""
    },
    {
        licenseName: "Project Registration with BIDA",
        licenseLink: "",
        agency: "Bangladesh Investment Development Authority (BIDA)",
        agencyLink: ""
    },
    {
        licenseName: "Work Permit for Foreign Nationals",
        licenseLink: "",
        agency: "BIDA and BEPZA",
        agencyLink: ""
    },
    {
        licenseName: "Approval of Foreign Loan",
        licenseLink: "",
        agency: "Bangladesh Investment Development Authority (BIDA)",
        agencyLink: ""
    }
];
