import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";

export default function FinanceAndWealth() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Finance and wealth
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            Global financial powerhouse
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Renowned as a nexus of prosperity and innovation, Dubai's finance
            and wealth sector is a testament to its unwavering commitment to
            excellence. The city has a diverse economy, including well-developed
            non-oil sectors that significantly contribute to economic growth,
            making it a popular destination for investment and business.
            <br />
            <br />
            The city hosts a financial market with one of the largest
            concentrations of Islamic bonds and three derivatives platform
            operators: Dubai Gold and Commodities Exchange, Dubai Mercantile
            Exchange and Nasdaq Dubai, making it an attractive option for global
            investors across asset classes.
          </p>
          <h5>D33: Shaping the future of finance</h5>
          <p>
            One of the goals of the D33 agenda is to position Dubai as a
            regional leader in new-age finance by developing its capital market
            infrastructure, with a key focus on virtual assets, crypto,
            blockchain and fintech.
            <br />
            <br />
            Dubai aims to become one of the world’s top four financial centres
            by 2033 and It also aims to serve as the ‘bank of the region’ for
            various services, including SME financing, trade finance, insurance
            and reinsurance.
          </p>
          <h4>Accelerating financial innovation </h4>
          <p>
            Dubai has launched various funds, including a US$272 million (AED1
            billion) fund to support new economy companies within the Dubai
            Future District. The Dubai Future Accelerators, a collaboration
            between major businesses and government stakeholders, fosters
            innovation by supporting cutting-edge start-ups.
            <br />
            <br />
            The independent regulator Dubai Financial Services Authority (DFSA)
            has also introduced its Innovation Testing Licence (ITL) Programme,
            which enables fintech firms to test new products and technologies
            within DIFC. Over 100 companies have registered interest.
          </p>
        </div>
      </div>

      <div className="resource-container">
        <h2 className="resource-section-title">
          Rewarding investment landscape
        </h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>
            <h4>Hosting top industry events</h4>
            <p>
              Including the World Blockchain Summit, Dubai Fintech Summit and
              World Finance Forum.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>
            <h4>
              Via a strong network of angels, accelerators and venture
              capitalists.
            </h4>
            <p>
              Via a strong network of angels, accelerators and venture
              capitalists.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <h4>Venture capital ecosystem on the rise</h4>
            <p>
              Fastest-growing venture capital ecosystem in the world, home to
              over 30% of MENA investors.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <h4>Facilitating asset trading</h4>
            <p>
              Via key economic corridors in the Middle East, Africa, Turkey,
              India and Bangladesh.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Home of HNWIs and private wealth</h3>
          <p>
            Dubai is home to many High-Net-Worth Individuals (HNWI) and a
            significant amount of private wealth. With more than 75,000
            millionaires, 200 centimillionaires (worth over US$100 million or
            AED367 million) and 15 billionaires, Dubai is the third-wealthiest
            city among the BRICS member nations.
          </p>
          <h4 style={{ marginTop: "20px" }}>
            Preferred choice for wealth management firms
          </h4>
          <p>
            Hundreds of wealth management firms operate in Dubai, drawn by its
            flourishing economy, future-ready financial environment, clear
            regulations, market opportunities and advantageous tax laws.
            <br />
            <br />
            The DIFC alone has more than 300 wealth management firms. Firms like
            IIFL Private Wealth Management, Iron Wealth, Edmond de Rothschild,
            EnTrust Global, Nomura Singapore Limited and The Family Office
            Company have set up base in Dubai, helping clients channel their
            investments into new geographies stretching from Africa to South
            Asia.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Ideal destination for fintech</h3>
        <p>
          Dubai is a significant enabler for fintech growth and expansion across
          the MEASA region. In recent years, key subsectors attracting funding
          included neobanks, open banking and corporate and personal lending.
          Currently, more than 80 fintech start-ups operate in Dubai.
          <br />
          <br />
          Diverse tech epicentres within Dubai support the thriving fintech
          industry, such as Dubai International Financial Centre’s (DIFC)
          Innovation Hub and Dubai Multi Commodities Centre (DMCC) and its newly
          launched Crypto Hub.The city’s support for the digital economy is also
          embodied in innovation centres like the Dubai Future District, which
          provides financing and legislative services. Additionally, the Dubai
          Integrated Economic Zones (DIEZ) has announced its US$136 million
          (AED500 billion) venture capital fund to empower fintech start-ups.
        </p>
        <h3 style={{ marginTop: "20px" }}>World’s 1st family wealth centre</h3>
        <p>
          DIFC launched a dedicated centre for family wealth in 2023 - the first
          of its kind worldwide.
          <br />
          <br />
          Dubai’s ambition to develop an environment conducive to healthcare
          start-ups is in line with the Dubai Economic Agenda D33. Further, the
          city’s 2030 Industrial Strategy emphasises elevating the
          pharmaceutical and medical device industries' manufacturing
          capabilities, output, and value-addition.
        </p>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>World’s 1st family wealth centre</h3>
            <p style={{ marginBottom: "0px" }}>
              DIFC launched a dedicated centre for family wealth in 2023 - the
              first of its kind worldwide.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>2nd top market worldwide</h3>
            <p style={{ marginBottom: "0px" }}>
              for Greenfield FDI, attracting 511 projects with a cumulative
              capital of US$5.7 billion (AED20.94 billion) in the first half of
              2023.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Spearheading virtual asset regulation</h4>
        <p>
          Dubai has positioned itself at the forefront of advancing digital
          asset regulation by establishing the Virtual Asset Regulatory
          Authority (VARA), the world's first independent regulator of virtual
          assets. Granting licenses to crypto firms, VARA boosts Dubai's status
          as a crypto hub. Its operational framework supports sustainability and
          cross-border financial security, with its Test-Adapt-Scale model
          attracting tech-enabled firms to join Dubai's future-driven community.
        </p>
        <h4>Cultivating innovation in finance</h4>
        <p>
          Dubai has become a hub for financial innovation, with numerous
          projects attracting financial entities looking to capitalize on
          lucrative opportunities. The city's accelerators and sector-specific
          funds further enhance its appeal, resulting in homegrown fintech
          successes and enticing global virtual assets, crypto, and Web3
          companies to establish their regional bases here.
          <br />
          <br />
          Dubai’s well-established domestic market continues to attract new
          forms of investment, enabling non-equity-based partnership
          opportunities across joint ventures, strategic alliances,
          sub-contracting, licensing and production-sharing franchising.
        </p>
      </div>
    </>
  );
}
