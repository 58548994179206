
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from '../helper/FormHelper';

function CoSpaceForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [need, setNeed] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://jkconsultancy-backend.onrender.com/send-mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          need,
          message,
        }),
      });

      const data = await response.json();
      SuccessToast("Form Submited Successfully");
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
      ErrorToast("Something Went Wrong");
    }

    setName('');
    setEmail('');
    setPhone('');
    setNeed('');
    setMessage('');
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="container">
          <div className="text-center">
            <h2 className="text-primary">Get a Free Consultation</h2>
          </div>
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div className="card mt-2 mx-auto p-4 bg-light custom-form-container">
                <div className="card-body bg-light">
                  <div className="container">
                    <form id="contact-form" onSubmit={handleSubmit}>
                      <div className="controls">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_name">Full Name *</label>
                              <input
                                id="form_name"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Please enter your firstname *"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_phone">Phone *</label>
                              <input
                                id="form_phone"
                                type="text"
                                name="phone"
                                className="form-control"
                                placeholder="Please enter your phone no *"
                                required
                                data-error="Phone no. is required."
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_email">Email *</label>
                              <input
                                id="form_email"
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Please enter your email *"
                                required
                                data-error="Valid email is required."
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_need">Please Specify Your Need *</label>
                              <select
                                id="form_need"
                                name="need"
                                className="form-control"
                                required
                                data-error="Please specify your need."
                                value={need}
                                onChange={(e) => setNeed(e.target.value)}
                              >
                                <option value="" selected disabled>
                                  --Select Your Service--
                                </option>

                                <option>Virtual Office</option>
                                <option>Room on Desk Use</option>
                                <option>Hourly Physical Office</option>


                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="form_message">Message *</label>
                              <textarea
                                id="form_message"
                                name="message"
                                className="form-control"
                                placeholder="Write your message here."
                                rows={4}
                                data-error="Please, leave us a message."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="submit"
                              className="btn btn-success btn-send  pt-2 btn-block"
                              value="Send Message"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoSpaceForm;



// import React, { useState } from 'react';
// import 'react-toastify/dist/ReactToastify.css';
// import { ErrorToast, SuccessToast } from '../helper/FormHelper';

// function EmailForm() {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [need, setNeed] = useState('');
//   const [message, setMessage] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('http://localhost:5000/send-mail', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           name,
//           email,
//           phone,
//           need,
//           message,
//         }),
//       });

//       const data = await response.json();
//       SuccessToast("Form Submited Successfully");
//       console.log('Success:', data);
//     } catch (error) {
//       console.error('Error:', error);
//       ErrorToast("All Information are required");
//     }

//     setName('');
//     setEmail('');
//     setPhone('');
//     setNeed('');
//     setMessage('');
//   };


//   return (
//     <div className="calculator-container" style={{ height: '600px' }}>
//       <h1 style={{color:'white'}}>Get a Free Consultation</h1>
//       <div>
//         <label style={{color:'white', fontSize:'16px', fontWeight:'600'}}>Name:</label>
//         <input
//           type="text"
//           value={name}
//           placeholder='Enter your name'
//           onChange={(e) => setName(e.target.value)}
//         />
//       </div>
//       <div>
//         <label style={{color:'white', fontSize:'16px', fontWeight:'600'}}>Email: </label>
//         <input
//           type="email"
//           value={email}
//           placeholder='Enter your email'
//           onChange={(e) => setEmail(e.target.value)}
//         />
//       </div>
//       <div>
//         <label style={{color:'white', fontSize:'16px', fontWeight:'600'}}>Phone: </label>
//         <input
//           type="text"
//           value={phone}
//           placeholder='Enter your phone'
//           onChange={(e) => setPhone(e.target.value)}
//         />
//       </div>
//       <div>
//         <label style={{color:'white', fontSize:'16px', fontWeight:'600'}}>Services: </label>
//         <select
//           type="number"
//           value={need}
//           onChange={(e) => setNeed(e.target.value)}
//         >
//           <option value="" selected disabled style={{color:'white', fontSize:'16px', fontWeight:'600'}}>
//             Select Your Service
//           </option>
//           <option>Startup Basic</option>
//           <option>Startup Exclusive</option>
//           <option>Startup Premium</option>
//           <option>Company Formation</option>
//           <option>Society Registration</option>
//           <option>Partnership Registration</option>
//           <option>RJSC Return Fillig</option>
//           <option>Loan Consultancy</option>
//           <option>Import License (IRC)</option>
//           <option>Export License (ERC)</option>
//           <option>BSTI Registration</option>
//           <option>Trade License</option>
//           <option>Individual TAX</option>
//           <option>Corporate TAX</option>
//           <option>Value Added TAX (VAT)</option>
//           <option>TIN Registration</option>
//           <option>BIN Registration</option>
//           <option>Trademark</option>
//           <option>Copyright</option>
//           <option>Patent</option>
//           <option>Industrail Design</option>
//           <option>IPR Enforcement</option>
//           <option>BTCL Domain Registration</option>
//           <option>Foreign Company Foramation</option>
//           <option>Foreign Direct Investment</option>
//           <option>BIDA Permission</option>
//           <option>Work Permit VISA</option>
//           <option>Foreign Delegates</option>
//           <option>Account Service</option>
//           <option>Book Keeping Services</option>
//           <option>Small Business</option>
//         </select>
//       </div>
//       <div>
//         <label style={{color:'white', fontSize:'16px', fontWeight:'600'}}>Message: </label>
//         <textarea
//           type="text"
//           value={message}
//           placeholder='Enter your message'
//           style={{width:"100%" }}
//           onChange={(e) => setMessage(e.target.value)}
//         />
//       </div>
//       <button className='form-submit-button' onClick={handleSubmit} style={{ width: '100%' }}>
//         Submit
//       </button>
//     </div>
//   );
// }

// export default EmailForm;

