import React from 'react'
import Meta from '../../components/Meta';
import Faq from '../../components/Faq';
import { Link } from 'react-router-dom';
import privateInvestmentImg from "../../assets/images/Foreign-Direct-Investment-Investment-Bangladesh.png"
import privateInvestment_og_img from "../../assets/og-img/Private Investment VISA.png"
import Marquee from "react-fast-marquee";
import { fpRealaatedArticales } from '../../data/relatedArticale';

function PrivateInvestmentVISA() {
    const faqItems = [
        {
            title: ' What is the current foreign direct investment in Bangladesh?',
            content: <strong>FDI in Figures. According to the UNCTAD's World Investment Report 2023, FDI inflows
                to Bangladesh increased by 20.2% to USD 3.48 billion in 2022 (compared to USD 2.89 billion in
                2021). In the same year, the total stock of FDI was estimated at USD 21.1 billion, representing
                only 4.6% of the country's GDP.</strong>
        },
        {
            title: 'Which sector has the highest foreign direct investment in Bangladesh? ',
            content: <strong>Sectoral distribution of FDI inflows
                There are three broad sectors of FDI inflows: infrastructure, manufacturing, and services. The
                shares of the three sectors are 46 percent, 27 percent and 27 percent respectively (Chart 3).</strong>
        },
        {
            title: 'How does FDI affect Bangladesh?',
            content: <strong> According to research, a ten percent increase in FDI can lead to a 3.7 percent
                increase in Bangladesh's GDP. A 13% annual growth in FDI can reduce poverty by 1%.
                Encouraging FDI growth is crucial to making a real impact on alleviating poverty.</strong>
        }
    ];
    return (
        <>
            <Meta title="Private Investment VISA | Business Investment VISA in Bangladesh"
                description="This page provides information about BSTI permission and related processes."
                image={privateInvestment_og_img}
                keywords="business investment visa, investment visa, investment visa bangaldesh, business visa, director visa bangladesh"
            />
                   

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Private Investment VISA</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Foreign Entity</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Private Investment VISA</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={privateInvestmentImg} alt='Foreign-Direct-Investment-Investment-Bangladesh' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                    {
                        fpRealaatedArticales
                    }
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Foreign DirectInvestment FAQ</h2>
                <Faq items={faqItems} />
            </div>

                   
        </>
    )
}

export default PrivateInvestmentVISA