import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';

import bondImg from "../../assets/images/Fire-License-In-Bangladesh-4.png"
import bond_og_Img from "../../assets/og-img/Custom Bond License.png"
import Marquee from 'react-fast-marquee';
import customImg2 from "../../assets/images/Types-of-Bonded-Wire-Houses.png"
import { othersArticales } from '../../data/relatedArticale';

function CustomsBondLicense() {
    const faqItems = [
        {
            title: 'Accordion Item #1',
            content: <strong>One Person Company</strong>
        },
        {
            title: 'Accordion Item #2',
            content: <strong>This is the second item's accordion body.</strong>
        },
        {
            title: 'Accordion Item #3',
            content: <strong>This is the third item's accordion body.</strong>
        }
    ];
    return (
        <>
            <Meta title="Custom Bond License in Bangladesh | Bonded Warehouse License"
                description="Bond License, This application is required to apply for the Bond License at customs bond commissionerate. Bond License is issued and maintained by Bonded Warehouse Licensing Rules"
                image={bond_og_Img}
                keywords="Custom Bond License, customs bond commissionerate, Bonded Warehouse License, Bond Warehouse License, Bond License"
            />
                   

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Custom Bond License</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Regulatory License</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Custom Bond License</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={bondImg} alt='Fire-License-In-Bangladesh-4' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Customs Bond License in Bangladesh
                        </b>
                    </p>
                    <p>
                        As per Customs Act, 1969, 100% export oriented businesses are eligible to get bond
                        facilities. Bond facilities were present from the early year of Customs act enactment.
                        Initially, Bond benefits were monitored by the concern Custom Houses. Then one company
                        has to go all Custom Houses through which he import or export. It was a very complex
                        work for the reconciliation of import and export quantities. To avoid such situation
                        and encouraging export businesses, in November 2000, the Customs Bond Commissionerate
                        started is journey. There are different types of Bond Licenses issued by this office
                        for facilitating exporters. Now most of the 100% export industries are running under
                        direct supervision of Bond Commissionerate.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>
                            Customs Bond Commissionerate
                        </b>
                    </p>

                    <p>
                        Chittagong is the second Bond Commissionerate of Bangladesh. The activities of the
                        Customs Bond Commissionerate, Regional Office of Chittagong started on 25/03/2001 AD.
                        The country’s largest industrial city, Chittagong, was transformed into a
                        full-fledged commissionerate in August 2011 in response to the long-standing needs
                        of the business community. The Government of Bangladesh provides bonded warehouse
                        facilities to exporters to earn foreign exchange, expedite exports and increase
                        international trade.<br /><br />

                        Activities relating to Customs Bonded Warehouses are conducted under Section 13 of
                        the Customs Act, 1989 and Sections 74-119. Circulars, Office Orders, Standing Orders
                        and Orders issued by the Commissioner of Customs Bonds issued at various times by the
                        National Board of Revenue for the management of Customs Ware House are equally
                        applicable to the law and rules.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            The Customs Bond Commissionerate
                        </b>
                    </p>
                    <p>
                        The Customs Bond Commissionerate has two offices, namely- (1) Customs Bond
                        Commissionerate, 342/1, Segunbagicha, Ramna, Dhaka and (2) Customs Bond
                        Commissionerate, 42, M. M. Ali Road, Lalkhan Bazar, Chittagong. In other cases, the
                        respective Custom House or VAT Commissionerate controls the Bonded Ware House under
                        their jurisdiction.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Types of Bonded Wire Houses
                        </b>
                    </p>

                    <p>
                        <img className="mx-auto responsive-img" src={customImg2}
                            alt='Types-of-Bonded-Wire-Houses' style={{ width: 1400, height: 300 }}
                        />
                    </p>
                    <p>
                        There are basically two types of bonded warehouses depending on the type of raw
                        material used, the purpose of use and the type of export. Namely:
                    </p>


                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Special Bonded Warehouse (SBW) </b>Special Bonded Warehouse License is issued in
                                favor of 100% export oriented garment industry organization. These include
                                oven garments, knit garments and sweaters.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>General Bonded Wirehouse (GBW) </b> General Bonded Wirehouse License is issued to 100% (100%) export oriented industrial establishments except for export oriented garment industry establishments. There are 7 (seven) types of establishments in General Bonded Ware House. E.g. (100%) export oriented latent exporters (manufacturing or accessories industry of packing / cartoon, level, polybag, hanger, zipper, button, padding etc.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                100% export oriented tanneries and tanneries;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                100% export oriented shipbuilding industry;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Home Consumption Bonded Wire House;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Diplomatic bonds;
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bond institutions of the Export Processing Zones (IACs) and
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                One hundred percent export-oriented covert to covert export-oriented organization.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Bonded Wire House Management
                        </b>
                    </p>
                    <p>
                        The Customs Bond Commissionerate performs the following functions in the management of
                        bonded warehouses. E.g.
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bond license issue
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Renewal of 100% export oriented latent bond license
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Auto renewal of 100% export oriented garment and net garment bond license
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Change of ownership of the bond license
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Approval of factory relocation application
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Execution of General Bonds
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Annual audit
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Permanent inter-bond transfer
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                No Objection Letter for Unloading Imported Goods (If General Bond is not valid)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bank Guarantee Release (in case of latent exporter)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bank Guarantee Release (in case of Direct Exporter)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Issuance of bond license in new format
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Lien Bank Additions
                            </span>
                        </div>
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                  {
                    othersArticales
                  }
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Customs Bond License FAQ</h2>
                <Faq items={faqItems} />
            </div>

                   
        </>
    )
}

export default CustomsBondLicense