import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
import NewFaq from "./FaqTowColumn";

export default function UnifiedLicense() {
  const faq = [
    {
      question: " What is Dubai Unified licence (DUL)?",
      ans: `The Dubai Unified Licence (DUL) is a project that provides a unique identification number for all existing and newly opened businesses in Dubai with mainland and free zone licenses. As per Law No. 6 of 2023, all businesses registered and licensed in Dubai will receive a DUL. This will be gradually rolled out in stages. At the moment, your DUL and QR code will serve as a unified business identity. However, these will soon be mandatory for government entities and service providers such as telecommunications, utilities, and banks.`,
    },
    {
      question: " What is the Purpose of the Dubai Unified Licence?",
      ans: `The DUL streamlines and improves the process of doing business by providing a single source of trusted, digital information for the general public, suppliers and service providers, allowing them to view licence details for mainland and free zone businesses. This means that anyone can use the DUL portal to search and retrieve data from any business in Dubai. Each business will also receive a QR code that can be scanned to retrieve the latest licence information.

In a later stage, businesses can use their DUL to acquire services from government and private entities in a quicker and more streamlined manner. Access to verified DUL data in a single repository means business verification would take less time and effort in customer-business, business-business or business-government interactions.

With a DUL, businesses will be able to maintain the latest contact details of license members through a unified portal.`,
    },
    {
      question: " What is the difference between a licence number and DUL?",
      ans: `A licence number is specific to the Licensing Authority, whereas a DUL is a unique company identifier applicable across Dubai's mainland and free zones. A DUL will serve as a de facto identifier for licensed businesses in Dubai, allowing public and service providers to retrieve business information.`,
    },
    {
      question:
        "What are the benefits of a Dubai Unified Licence for my business?",
      ans: `DUL simplifies and streamlines consent-driven data exchange and transactions between businesses and service providers, including government entities, by assigning unique identification numbers to mainland and free zone businesses.

When interacting with government and private entities, businesses can expect improved customer experience and expedited business transactions considering that with a DUL`,
    },
    {
      question: " What should I expect with the launch of DUL?",
      ans: `DUL will launch in Q1 2023 and will be rolled out in multiple stages. All licensed businesses will be issued a DUL and an associated QR code.

You will know if you are among these businesses as you will receive an email and SMS with your DUL and a unique page link to access and download your QR code.

DULs will be issued to all remaining companies in subsequent phases.`,
    },
    {
      question: " How do I use my Dubai Unified Licence?",
      ans: `Your DUL will be your primary business identity in Dubai, and the QR code should be displayed across your business premises, website and social media accounts.

Public and service providers will scan your QR code to retrieve public information about your legitimate business.

As such, the DUL and QR code will be the sole identity that you need to share with government authorities and service providers from the private sector to avail of the services of other businesses.

You will be expected to replace the licence certificate that you usually display at your business premises with the QR code, which should also be displayed on your business premises and published on all their digital platforms, including social media pages and websites. Your customers and suppliers can scan the code to view information pertaining to your registered business and its status.`,
    },
  ];
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Dubai Unified License
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            About Dubai Unified License
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            DUL makes doing business easier and faster by providing a digital,
            trusted, single source of information for the general public,
            suppliers, and service providers to view license details of
            businesses operating in Dubai, across the mainland, and in free
            zones.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/difc-business-night-architecture-dtcm.jpg?rev=d8f925e4f5394969886706715cae2260&cx=0.5&cy=0.5&cw=200&ch=200"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Digital, Trusted, and Unified Source of Information</h4>
            <p>
              DUL ensures a digital, trusted and unified source of information
              is made available for the public, suppliers, and service providers
              for ease of access to information on businesses operating in
              Dubai.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/business-event-people-start-up.jpg?rev=33cb4295a8e7409184cec1a9d6557a1c&cx=0.5&cy=0.5&cw=200&ch=200"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>
              Improved Customer Experience and Speedy Business Transactions
            </h4>
            <p>
              With the help of Unique IDs assigned to each Business, DUL allows
              companies and service providers to exchange data and transactions
              with ease.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/neighbourhood-pages/business-bay/media/business-bay-media-1.jpg?rev=0b860f377f054a5d9395501be788d634&cx=0.5&cy=0.5&cw=200&ch=200"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>
              Simplified Issuance of No-Objection Certificates and Permits for
              Economic Activities
            </h4>
            <p>
              Some of the most prominent advantages of DUL include unifying and
              simplifying the issuance of no-objection certificates and permits
              for economic activities by the concerned government entities;
              offering unified means and tools for service providers to
              digitally access company information and provide value-added
              services; and facilitate business exchange between free zones and
              mainland in Dubai by accessing audited and reliable consolidated
              data.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/business-people-difc-meeting2.jpg?rev=5359289f13744d0c96cea35e1e7d3e19&cx=0.5&cy=0.5&cw=200&ch=200"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Streamlined ‘Know Your Customer’ Process</h4>
            <p>
              The system allows banks to access ‘Know Your Customer’ company
              data when opening bank accounts. Business owners in the future can
              also further benefit from the availability of unified and
              documented information about existing businesses in Dubai, thus
              creating a complete record of such company data, including free
              zones.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <NewFaq title="Frequently asked questions" colNo={2} faqItems={faq} />
      </div>
    </>
  );
}
