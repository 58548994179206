export const featuredProperties = [
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Luxury Villa with Ocean View",
      location: "Miami, FL",
      price: "$2,500,000"
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Modern Apartment in Downtown",
      location: "New York, NY",
      price: "$1,200,000"
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Modern Apartment in Downtown",
      location: "New York, NY",
      price: "$1,200,000"
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Modern Apartment in Downtown",
      location: "New York, NY",
      price: "$1,200,000"
    },
    {
      image: "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg",
      title: "Modern Apartment in Downtown",
      location: "New York, NY",
      price: "$1,200,000"
    },
    // Add more properties as needed
  ];