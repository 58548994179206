import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import paymentOmg from "../assets/images/JK-Associates-Payment.png"
import paymentImg2 from "../assets/images/image.png"
import paymentImg3 from "../assets/images/image-1-1024x241.png"

function PaymentProcess() {
    return (
        <>
                   
            
            <div className="container-fluid hero-header bg-light py-5" style={{ marginTop: '40px' }}>
                <center>
                    <img src={paymentOmg} width='60%' alt='JK-Associates-Payment' />
                </center>
            </div>

            <Container>
                <center>
                    <h2>Choose From Any of Our Payment Options</h2>
                    <h2>Payment via</h2>
                </center>
                <center>
                    <img src={paymentImg2} style={{ maxWidth: '100%', height: 'auto' }} alt='JK-Associates-Payment-BAKSH' />
                    <p style={{ fontSize: '19px', color: 'black' }}><b>The City Bank Limited </b>any branch is open for all <b>JK Associates</b> Clients payment. Visit</p>
                </center>

                <div className="d-flex align-items-center mb-2 ">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <b>
                        <span style={{ color: 'black' }}><span style={{ color: 'red' }}>Bank Name:</span> The City Bank Limited</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <b>
                        <span style={{ color: 'black' }}><span style={{ color: '#df0023 ' }}>Bank Account Name:</span> JK Associates</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <b>
                        <span style={{ color: 'black' }}><span style={{ color: '#df0023 ' }}>Bank Account Number: </span> 1502690641001</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <b>
                        <span style={{ color: 'black' }}><span style={{ color: '#df0023 ' }}>SWIFT Code: </span> CIBLBDDH</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />

                    <b>
                        <span style={{ color: 'black' }}><span style={{ color: '#df0023 ' }}>Routing Number: </span> 225260991</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <b>
                        <span style={{ color: 'black' }}><span style={{ color: '#df0023 ' }}>Branch:</span> Rokeya Sarani Branch, Mirpur, Dhaka</span>
                    </b>
                </div>

                <center><h1>Payment Via</h1>
                    <img src={paymentImg3} style={{ maxWidth: '100%', height: 'auto' }} alt='JK-Associates-Payment-BAKSH1' /><br />
                    <Link to='https://shop.bkash.com/jk-associatesrm53807/paymentlink/default-payment'>
                        <button class="bkash-button">Pay With Bkash</button>
                    </Link>
                </center>
                <p style={{ color: 'black', fontSize: '20px' }}><b>Manual Payment:</b></p>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />

                    <b>
                        <span style={{ color: 'black' }}>Login to your bKash account with your account’s PIN number and tap on “Payment” option.</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />

                    <b>
                        <span style={{ color: 'black' }}>In “Payment” option provide “+8801511404971” and follow the next step.</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />

                    <b>
                        <span style={{ color: 'black' }}>Now enter the “Amount” and follow the next step.</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />

                    <b>
                        <span style={{ color: 'black' }}>In “Reference” field provide the invoice number & provide the PIN and go next.</span>
                    </b>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />

                    <b>
                        <span style={{ color: 'black' }}>Now hold the pay button for a few second. You’re done!</span>
                    </b>
                </div>
            </Container>

                   
        </>
    )
}

export default PaymentProcess