import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function EducationSystem() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Education in Dubai: a comprehensive guide
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p>
            From nurseries to postgraduate programmes, Dubai offers world-class
            education through renowned institutions.
          </p>

          <p style={{ width: "80%", lineHeight: "28px" }}>
            The Dubai education ecosystem predominantly comprises a range of
            private institutions, whose offerings cater to the requirements of
            the city’s largely multicultural population. The Knowledge and Human
            Development Authority (KHDA) is the primary quality assurance and
            regulatory authority for education administration in Dubai.
            <br />
            <br />
            Schools cover the major academic curricula, including the
            International Baccalaureate and IGCSE, and also curricula aligned
            with the education systems of different countries, such as British,
            American, Canadian, French, Indian, Filipino, German, Russian and
            Pakistani.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3 style={{ marginTop: "40px" }}>Nurseries</h3>
          <p>
            Nurseries and daycares are available around the city, with more than
            263 registered early learning centres, dedicated to the care of
            children aged 45 days to three years old. Kindergartens in Dubai
            accept children aged four to six years old.
          </p>

          <h3>Expected fees</h3>
          <p>
            Nursery fees can range from AED3,000 to AED5,000 per month, while
            some also calculate fees per term or annum.
          </p>
        </div>
      </div>

      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Popular nurseries in Dubai</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Raffles Early Childhood Centre</h4>
            <p>
              Its seven centres offer two catered curricula – the UK Early Years
              Foundation Stage (EYFS) and Montessori education.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Blossom Nurseries – Dubai</h4>
            <p>
              With 13 branches in Dubai, these nurseries are for kids aged eight
              weeks to five years old, and follow the EYFS curriculum.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Circle Time – Dubai</h4>
            <p>
              A budget-friendly option helping children with fine motor skills
              and intellectual skills, Circle Time also offers the EYFS
              curriculum.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>British Orchard Nursery</h4>
            <p>
              With multiple certifications, BON branches follow the EYFS
              curriculum and incorporate languages like Mandarin.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Primary and secondary education</h3>
          <p>
            There are many affordable schools in Dubai catering to different
            preferences. In general, schooling is considered mandatory until the
            completion of Grade 12 or 13 (depending on the curriculum). Primary
            and secondary schools in Dubai are known for their exceptional
            educational standards and variety of curricula. A noteworthy
            educational network in the region is Global Education Management
            Systems (GEMS), which operates several schools in Dubai.
            <br />
            <br />
            High-achieving students can avail of perks like scholarships and
            financial incentives. Exceptional high-school students are also
            eligible to qualify for a five-year Golden Visa, which allows them
            to sponsor their family members.
          </p>

          <h4>Expected fees</h4>

          <p>
            Annual costs for schooling vary based on factors like curricula and
            educational levels, with lower grades typically costing less. Fees
            can range from AED12,700 to AED64,000 per year, or higher. These
            estimates do not cover additional charges for transportation,
            uniforms, books and other facilities, which differ across schools.
            <br />
            <br />
            The official KHDA website carries a guide for educational
            institutions, with information on fees and fact sheets covering
            various details.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Well-known Dubai schools</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>GEMS Modern Academy</h4>
            <p>
              An outstanding IBDP curriculum school in Nad Al Sheba, offering
              education from Pre-K to Grade 12.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>American School of Dubai</h4>
            <p>
              With 13 branches in Dubai, these nurseries are for kids aged eight
              weeks to five years old, and follow the EYFS curriculum.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Dubai International Academy</h4>
            <p>
              A budget-friendly option helping children with fine motor skills
              and intellectual skills, Circle Time also offers the EYFS
              curriculum.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Repton School – Dubai</h4>
            <p>
              With multiple certifications, BON branches follow the EYFS
              curriculum and incorporate languages like Mandarin.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Higher education</h3>
          <p>
            Dubai has many leading universities that offer world-class
            education. Most universities are in Dubai Knowledge Park (DKP) or
            Dubai International Academic City (DIAC) – both of which are special
            educational zones dedicated to supporting the growth of the city’s
            higher education ecosystem.
            <br />
            <br />
            Many higher education establishments here have student transfer and
            exchange programmes, enabling qualifying students to pursue courses
            of their choice at reputed partner universities around the world.
          </p>

          <h4>Expected fees</h4>

          <p>
            Annual costs for schooling vary based on factors like curricula and
            educational levels, with lower grades typically costing less. Fees
            can range from AED12,700 to AED64,000 per year, or higher. These
            estimates do not cover additional charges for transportation,
            uniforms, books and other facilities, which differ across schools.
            <br />
            <br />
            The official KHDA website carries a guide for educational
            institutions, with information on fees and fact sheets covering
            various details.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Renowned universities in Dubai</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Zayed University – Dubai</h4>
            <p>
              An outstanding IBDP curriculum school in Nad Al Sheba, offering
              education from Pre-K to Grade 12.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Canadian University of Dubai</h4>
            <p>
              With 13 branches in Dubai, these nurseries are for kids aged eight
              weeks to five years old, and follow the EYFS curriculum.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>American University in Dubai</h4>
            <p>
              A budget-friendly option helping children with fine motor skills
              and intellectual skills, Circle Time also offers the EYFS
              curriculum.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Emirates Aviation University</h4>
            <p>
              With multiple certifications, BON branches follow the EYFS
              curriculum and incorporate languages like Mandarin.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
