import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import industrialImg from "../../assets/images/Industrial-Design.png"
import industrial_og_Img from "../../assets/og-img/Industrial Design.png"
import Marquee from 'react-fast-marquee';
import industrialImg2 from "../../assets/images/Principles-of-Industrial-Design-606x1024.png"
import { intellectualRelatedArticales } from '../../data/relatedArticale';

function IndustrialDesign() {
  const faqItems = [
    {
      title: 'What are the criteria for registration of design?',
      content: <strong>Declaration of the type of applicant, whether an individual or a company. A statement
        of novelty, demonstrating the uniqueness of the design. Authorization or a Power of Attorney,
        enabling Lawfirm.com.bd to represent the applicant. Payment of the prescribed fees for the
        application.</strong>
    },
    {
      title: 'What are the 4 types of industrial design?',
      content: <strong>The four types of industrial design are product design, transportation design,
        environmental design, and interaction design. Each type focuses on specific aspects and
        objectives, allowing businesses to choose the right approach for their products.</strong>
    },
    {
      title: 'What is the duration of industrial design?',
      content: <strong>An Industrial design is registered for a period of 10 years and is extendable for another
        5 years if an application in Form-3 with a fee of Rs.</strong>
    }
  ];
  return (
    <>
      <Meta title="Industrial Design"
        description="This page provides information about BSTI permission and related processes."
        image={industrial_og_Img}
        keywords="industrial desing, keyword2, keyword3"
      />
             

      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">Industrial Design</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Intellectual Property</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Industrial Design</li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={industrialImg} alt='Industrial-Design' />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>What is industrial design and why is it important?</b>
          </p>
          <p>
            Industrial design is a field of design that focuses on the creation of products, systems, and
            environments that are functional, aesthetically pleasing, and user-centric. Industrial
            designers use their knowledge of engineering, materials, ergonomics, and aesthetics to create
            products that are not only visually appealing, but also meet the needs of their potential
            users.<br /><br />

            Industrial design is important for several reasons. Firstly, it helps to create products that
            are more user-friendly and efficient, as designers take into account the needs and
            preferences of their users. This can lead to products that are easier to use, more
            comfortable, and more functional.<br /><br />

            Secondly, industrial design can help to differentiate products from competitors in the
            marketplace. By creating products that are aesthetically pleasing and visually distinctive,
            industrial designers can help companies stand out and attract customers.<br /><br />

            Thirdly, industrial design can have an impact on sustainability. By designing products that
            are more durable, efficient, and environmentally friendly, industrial designers can help to
            reduce waste and conserve resources.<br /><br />

            Finally, industrial design can have a positive impact on society as a whole. By creating
            products that improve people’s lives, industrial designers can contribute to the betterment
            of society and help to address social and economic challenges.
          </p>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  "  >
              <img className="img-fluid" src={industrialImg2} alt='Principles-of-Industrial-Design-606x1024' />
            </div>
            <div className="col-lg-6  "  >
              <div className="h-100">
                <p className="color-text fs-5 mb-4">
                  How does industrial design differ from other design fields
                </p>
                <p>
                  Industrial design, graphic design, and interior design are all design fields, but
                  they differ in their focus, skills, and techniques.<br /><br />

                  Industrial design is concerned with the design of physical products and systems,
                  such as consumer products, furniture, transportation vehicles, and industrial
                  equipment. Industrial designers work with engineers and manufacturers to create
                  products that are functional, aesthetically pleasing, and cost-effective to
                  produce.<br /><br />

                  Graphic design, on the other hand, is focused on the visual communication of
                  information and ideas. Graphic designers use typography, color, and images to
                  create visual designs that communicate a message, such as advertising campaigns,
                  logos, and packaging.<br /><br />

                  Interior design is the design of interior spaces, such as homes, offices, and
                  commercial buildings. Interior designers work with architects and clients to
                  create spaces that are functional, aesthetically pleasing, and reflect the
                  client’s needs and preferences.<br /><br />

                  While all of these design fields share some common principles, such as an
                  emphasis on aesthetics and functionality, they differ in their focus and the
                  specific skills and techniques required. Industrial design, for example, requires
                  knowledge of engineering and manufacturing processes, while graphic design
                  requires expertise in typography and image-making. Interior design, meanwhile,
                  requires knowledge of materials, space planning, and building codes.
                </p>
                <p className="color-text fs-5 mb-4">
                  What are the key principles of industrial design?
                </p>
                <p>
                  The key principles of industrial design are a set of guidelines and standards
                  that industrial designers use to create effective, aesthetically pleasing, and
                  user-centered products. While there is no single definitive list of principles,
                  here are some common ones:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-0">
        <div className="container">
          <p>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Functionality: </b>Industrial design is primarily concerned with creating products that
                are useful and functional. The design should meet the needs and requirements of the user and
                perform its intended task efficiently.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Aesthetics: </b>Design should be visually pleasing and attractive. This includes
                considerations such as color, form, and texture.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Usability: </b>It should be easy to use and understand, with intuitive controls and clear feedback.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Ergonomics: </b>The design should be comfortable and safe to use, taking into account the
                physical dimensions and capabilities of the user.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Sustainability: </b>The industrial design should be environmentally responsible, taking into
                account factors such as material selection, energy use, and end-of-life disposal.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Innovation: </b>It should incorporate new and innovative ideas, technologies, and materials.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Manufacturability: </b>The design should be cost-effective and feasible to produce on a large scale.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Branding: </b>It should reflect the brand identity and values of the company or product.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>User-centered design: </b>This design should be based on a deep understanding of the user’s needs, preferences, and behavior.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Design for diversity: </b>The industrial design should take into account the diverse needs
                and abilities of different users, including those with disabilities or special needs.
              </span>
            </div>
          </p>
          <p className="color-text fs-5 mb-4">
            <b>What are the most common tools and technologies used in industrial design?</b>
          </p>
          <p>
            Industrial designers use a variety of tools and technologies to create their designs. Here are
            some of the most common ones:
          </p>
          <p>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Sketching and drawing tools: </b>Industrial designers often start the design process by
                sketching ideas on paper. They may use pencils, pens, markers, or other drawing tools.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Computer-aided design software (CAD): </b>CAD software allows industrial designers to
                create 3D models of their designs on a computer. This makes it easier to refine and test
                their ideas, as well as to communicate with other members of the design team.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Prototyping tools: </b>Industrial designers may use a variety of tools and technologies to
                create physical prototypes of their designs, including 3D printers, CNC machines, laser
                cutters, and woodworking tools.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Rendering and visualization software: </b>Rendering software allows industrial designers
                to create photorealistic images and animations of their designs, which can be used for
                presentations, marketing materials, and other purposes.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Augmented and virtual reality (AR/VR) tools: </b>AR/VR tools allow designers to create
                immersive simulations of their designs, which can be used to test and refine the design
                before it is produced.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Material libraries and databases: </b>Industrial designers need to be familiar with the
                properties and characteristics of different materials, such as plastics, metals, and fabrics.
                Material libraries and databases can help designers choose the right materials for their
                designs.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Human factors and ergonomics tools: </b>Industrial designers may use tools and techniques
                to analyze the ergonomics and usability of their designs, such as anthropometric data, user
                testing, and task analysis.
              </span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
              <span>
                <b>Project management and collaboration tools: </b>Industrial designers may use software and
                tools to manage their projects, collaborate with other designers and stakeholders, and
                communicate with clients and suppliers. Examples of such tools include project management
                software, online collaboration platforms, and video conferencing tools.
              </span>
            </div>
          </p>
          <p className="color-text fs-5 mb-4">
            <b>What role does user-centered design play in industrial design?</b>
          </p>
          <p>
            By taking a user-centered approach, industrial designers can ensure that their designs are not
            only visually appealing but also meet the demands of the intended audience.<br /><br />

            User-centered design involves several stages, including research, prototyping, and testing. In
            the research phase, industrial designers gather information about the users, their needs, and
            their preferences. This may involve observing users in their natural environment, conducting
            surveys or interviews, and analyzing data. By gaining a deep understanding of the users,
            industrial designers can create designs that are tailored to their needs.<br /><br />

            In the prototyping phase, industrial designers create rough mockups or prototypes of the design
            to test it with users. This allows them to get feedback on the design and make changes based on
            user input. By involving users in the design process, industrial designers can ensure that the
            final product is something that meets their needs and preferences.<br /><br />

            Testing is the final phase. In this phase, industrial designers test the final product with
            users to ensure that it meets their needs and is easy to use. This may involve usability
            testing, where users are asked to perform tasks with the product and provide feedback on their
            experience.<br /><br />

            By using a user friendly approach, it can lead to higher levels of customer satisfaction,
            increased sales, and a better reputation for the company or brand.
          </p>
          <p className="color-text fs-5 mb-4">
            <b>How do intellectual property rights affect industrial design?</b>
          </p>
          <p>
            Intellectual property rights play an important role in industrial design. Patents, trademarks,
            and other forms of intellectual property protection can help industrial designers protect their
            designs, prevent others from copying them, and ensure that they receive proper credit and
            compensation for their work.<br /><br />

            Patents are one of the most common forms of intellectual property protection for industrial
            design. A patent grants the inventor the exclusive right to make, use, and sell the invention for
            a certain period of time, typically 20 years. Patents can be granted for a wide range of
            industrial designs, including products, machines, processes, and even software.<br /><br />

            Trademarks are another important form of intellectual property protection for industrial
            designers. A trademark is a word, symbol, or design that is used to identify and distinguish the
            products or services of one company from those of another. By registering a trademark, industrial
            designers can protect their brand identity and prevent others from using similar names or logos
            that could cause confusion in the marketplace.<br /><br />

            Other forms of intellectual property protection that may be relevant to industrial design include
            copyrights, design patents, and trade secrets. Copyrights protect original works of authorship,
            such as drawings or models, while design patents protect the ornamental design of a functional
            object. Trade secrets refer to confidential information, such as manufacturing processes or
            customer lists, that give a company a competitive advantage.<br /><br />

            Overall, intellectual property rights are critical for industrial designers, as they help protect
            their designs, prevent others from copying them, and ensure that they receive proper credit and
            compensation for their work.
          </p>
          <p className="color-text fs-5 mb-4">
            <b>What are the filing requirements of a Design in Bangladesh?</b>
          </p>
          <p>
            <b>1. The candidate’s name and address</b><br /><br />

            <b>2. Representation:</b> Four identical representations of the article, each clearly displaying
            the design’s elements from various angles and identifying the viewpoints, must be submitted with
            the application on the specified form. Where appropriate, the representation may include a
            drawing, a photo, or actual design samples.<br /><br />

            <b>3. Declaration of Novelty: </b>The applicant must include a succinct description of the
            novelty for which he or she is seeking protection in addition to the application and each of the
            statements. This can be done at the moment the application is submitted or at any point before
            registration is actually put into action.<br></br>

            <b>4. Endorsement of disclaimers: </b>The statement of innovation will include a disclaimer stating
            that no ownership of any mechanism, letters, words, numbers, or other symbols, including
            trademarks, is claimed.<br></br>

            <b>5. Products Classification: </b>For the purposes of industrial design registration, the goods
            to which the design will be applied are separated into fourteen classes.<br></br>

            <b>6. </b>In Bangladesh, the original POA (Power of Authority by Applicant) must be submitted
            within one month of the filing of the patent application.<br></br>

            <b>7. Priority:</b>Bangladesh has a mechanism for priority application. Regarding this, the
            applicant for registration of an industrial design who wishes to take advantage of the priority
            of an earlier application filed in a convention country shall, within six months of the date of
            such earlier application, append to his application a written declaration indicating the date and
            number of the earlier application, the applicant’s name, and the country in which he or his
            predecessor in title filed such application, and shall, within a period. In the event that the
            priority document is not in English, it must be verified or notarized in English.
          </p>
        </div>
      </div>

      <div className='container-xxl center-background1'>
        <h2> Related Articles</h2>
      </div>

      <div className='container-xxl center-background1'>
        <Marquee className="marquee-container">
         {
          intellectualRelatedArticales
         }
        </Marquee>
      </div>

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">Industrial Design FAQ</h2>
        <Faq items={faqItems} />
      </div>
             
    </>
  )
}

export default IndustrialDesign