import React from "react";
import { Link } from "react-router-dom";
import copyrightImg2 from "../assets/images/Copyright-Registration-Process.png";
import Meta from "./Meta";

export default function EpbLicense() {
  return (
    <>
      <Meta
        title="Environment Licenses in Bangladesh | Environmental Clearance Procedure"
        description="The Procedure for issuing Environmental. Clearance Certificate has been stated in the Rule-7 of the Environmental Conservation Rules"
        // image={environment_og_Img}
        keywords="Environmental Clearance, environment clearance certificate in Bangladesh, Environmental Certificate"
      />
      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1
                className="display-4 mb-3 animated slideInDown"
                style={{ fontSize: "40px" }}
              >
                EPB License
              </h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Others</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    EPB-License
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img
                className="img-fluid animated pulse infinite"
                style={{ animationDuration: "3s" }}
                src={"/img/epb-license-banner.png"}
                alt="Environmental"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>Textile Division</b>
          </p>
          <p>
            The Textile Division of EPB looks after textile and apparel exports
            of the Country. The Division, headed by a Director General, is
            responsible for implementation of the Agreement on Textile &
            Clothing. Implementation of the bilateral textile trade agreements
            with USA, Canada and EU, RMG quota allocation and monitoring,
            issuance of GSP certificate, certificate of origin are some of the
            important jobs of the textile Division. To run the quota
            administration system, the cell maintains the Electronic Visa
            Information System (ELVIS).
          </p>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  ">
              <img
                className="img-fluid"
                src={copyrightImg2}
                alt="Copyright-Registration-Process"
              />
            </div>
            <div className="col-lg-6  ">
              <div className="h-100">
                <p className="color-text fs-5 mb-4">
                  Textile Division (Dhaka):
                </p>
                <p>
                  Textile Division is the most important division of EPB. Its
                  main function is to promote export of Readymade garments. It
                  also issues GSP, CO, SAPTA, Annex-III certificates against
                  export of RMG products. The export oriented garments industry
                  has to be enrolled with the textile Division of EPB for
                  getting necessary service.
                </p>
                <p className="color-text fs-5 mb-4">
                  Required Documents for new export oriented ready made garments
                  industry
                </p>
                <p>
                  Following documents are needed for enrollment of a new export
                  oriented readme garments industry with the Export Promotion
                  Bureau:
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Pay order for Tk. 2000/- (Two hundred) in favour of EPB.
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Two copies of passport size photographs.</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Trade licence (Renewed).</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Export Registration Certificate (ERC) (renewed).
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Membership of Concern Association (renewed).</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Bonded Warehouse licence (renewed).</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Fire Service Licence (renewed).</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Fire Policy (Renewed).</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Tax Identification (TIN) Certificate.</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Value Addition Tax (VAT) Certificate.</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Memorandum of Article (If necessary).</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>Approval of Board of Investment.</span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Approved Building layout plan and structural design from
                      concern govt. authority.
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Group Insurance for the workers employed in the factory.
                    </span>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-0">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>
              Required Documents and information for renewal of enrollment
              certificates.
            </b>
          </p>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Pay order Tk. 1000 (one thousand) up to 31-12-2006 and Tk. 1500
              (One thousand five hundred) after the said date.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Two copies passport size photographs.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Trade licence (renewed).</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Export Registration Certificate (ERC) (renewed).</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Membership of Concern Association (renewed).</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Bonded Warehouse licence (renewed).</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Fire Service Licence (renewed).</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Approved Building layout plan and structural design from concern
              govt. authority.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Workers Group Insurance for workers employed in the factory.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Following documents are needed for issuing GSP and SAPTA
              Certificates:
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Export L.C/Sales contract/contract.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Back to Back L/C, connecting Back to Back /.C.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Production Certificate issued from BTMA/Bill of Entry for import
              of Yarn.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Certificate of Origin from a Textiles Mills for supply of
              yarn/fabrics, foreign chamber certificate of origin for import of
              yarn.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>G.P-2.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Utilization Declaration.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Commercial Invoice, Packing list.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Bill of lading, Air waybill.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Truck Chalan.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Import Permit (IP) for EPZ firm and concern documents.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Bank Certificate, EPZ declaration.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Shipping Bill.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>I.D Card, Signature card to M.D or Proprietor.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Copy of a EPB Registration certificate.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Following documents are needed to obtain Certificate of Origin
              (C.O), Annex-III and Canadian Licence:
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Pay order Tk.100 (One hundred) in favour of EPB.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Invoice.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Packing list.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>L/C.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Back to Back L/C.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Utilization Declaration.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Shipping Bill.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Bill of Lading/Airway Bill.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              Import permit of BEPZA for EPZ firm and concern documents.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Chalan.</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>
              I.D card, Signature card to Managing Director or proprietor.
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
            <span>Copy of EPB Registration Certificate.</span>
          </div>
        </div>
      </div>
    </>
  );
}
