import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
import NewFaq from "./FaqTowColumn";
export default function Employees() {
  const visaFaq = [
    {
      question: "Sponsored/Work Visa",
      ans: `Foreign workers in Dubai must hold a residence visa and a work permit (also known as a labour card). Here are the steps for this process:

Your employer applies for approval from the UAE Ministry of Human Resources and Emiratisation to hire a foreign employee, i.e. you.
The Ministry issues an entry permit visa, which will allow you to enter the UAE for up to 60 days, during which time you need to obtain a residence visa.
You travel to Dubai and have 60 days to obtain a residence visa.
Upon arrival, your employer applies for a work permit — also known as a labour card.
You visit an Emirates ID service centre with your valid passport and entry visa/permit to obtain your ID.
You complete a medical examination at a government hospital.
Apply for your residence visa through the immigration authorities.
Submit all required documents including a personal photo and a passport copy with at least a six-month validity period to the General Directorate of Residency and Foreign Affairs.
Note that if you’re a foreign-born worker already resident in Dubai, you will need to either cancel your existing visa or get a No Objection Certificate (NOC) from your current employer. Submit these documents along with your passport and Emirates ID to your new employer, who can use those to issue a sponsored work visa`,
    },
    {
      question: "Remote Work Programme Visa",
      ans: `Created for remote workers, this one-year visa under the remote work programme provides access to Dubai’s telecoms, utilities, and schooling services, as well as a global hub and a skilled workforce composed of 200+ nationalities. To qualify, you need to provide proof of work/employment outside the UAE and that the work is conducted remotely. Find out more about working remotely from Dubai.`,
    },
    {
      question: "Green Visa",
      ans: `This five-year visa is for foreign investors establishing or participating in commercial activities in the UAE. To qualify for this visa as an entrepreneur, you must invest a minimum of AED 500,000 (approx. US$136,129) in your business, Alternatively, you can be a start-up owner with the approval of an accredited business incubator in the UAE. Find out more about residency visas for investors and entrepreneurs.`,
    },
    {
      question: "Golden Residence Visa",
      ans: `Recently the UAE Government introduced the ten-year Golden Residence Visa category for investors, entrepreneurs, exceptional talents, scientists and professionals, outstanding students and graduates, humanitarian pioneers and frontline heroes. Click here for more information on the criteria for each category.`,
    },
  ];
  const findingBussinessFaq = [
    {
      question: "Leasing commercial property in Dubai",
      ans: `The rules in Dubai are very clear: free zone businesses can lease office space within their respective free zone, while mainland businesses have the flexibility to lease office space anywhere in Dubai excluding free zones.

The Dubai Land Department (and the subsidary Real Estate Regulatory Authority) is the main government body responsible for handling matters related to leasing and buying of commercial and residential property in Dubai.

To become a tenant, you must provide the following documents:

Your trade licence (or initial approval from the DET or free zone licencing authority)
A copy of your passport
Contracts typically run for 12 months, three years, or five years`,
    },
    {
      question: "Rental costs, fees and utilities",
      ans: `Expect to pay five percent of the total rental amount as a security deposit. Commercial tenants also usually pay an annual or monthly maintenance fee for communal area upkeep.

To connect your utility services, you must register your lease contract, Companies should apply with the Dubai Electricity and Water Authority and provide the following documents:

1.A valid business licence
2.An attested tenancy contract with Ejari
3.A copy of your passport
4.Residence visa
5.An Emirates ID card`,
    },
    {
      question: "Registering lease agreements",
      ans: `In Dubai, all rental and lease agreements must be registered with Ejari, the online registration system for tenancy contracts. This ensures your private rental contracts comply with legal standards and follow a government-approved format. Ejari also records the agreed rental price to avoid any potential issues that may arise between landlord and tenant and acts as a guarantor of tenant rights.`,
    },
    {
      question: "Buying commercial property in Dubai",
      ans: `Mainland companies can own freehold commercial property in Dubai under their company name.

Free zone companies may own commercial properties in their respective free zones.

To find out more about the costs associated with buying commercial property, `,
    },
  ];
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Employees, administration and logistics
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h6 style={{ width: "80%", lineHeight: "28px", marginTop: "20px" }}>
            All the information you need about visas, bank accounts, employment
            and business premises.
          </h6>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Your guide to visas in Dubai</h3>
          <b>
            Getting a valid visa is the starting point of your business journey
            in Dubai
          </b>
          <div style={{ margin: "30px 0" }}>
            <p>
              Once you receive your trade or business licence - whether from the
              mainland or any free zone - you can then convert that entry permit
              into a residence visa. You can find more information in the Live &
              Work section of our website.
            </p>
          </div>
          <NewFaq
            title="What visa types are available in Dubai?"
            colNo={2}
            faqItems={visaFaq}
          />
        </div>
        <div className="container my-5">
          <h2 className="mb-4">Dubai's free zones</h2>
          <p className="mb-5">
            Browse information on Dubai's business landscape and how to start or
            move your business to Dubai.
          </p>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dsc.jpg?cx=0.5&cy=0.5&cw=440&ch=440"
                  className="card-img-top"
                  alt="Mainland companies"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai Studio City (DSC)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Maket specialisation:</h5>
                    <p>
                      Broadcasting, Arts & entertainment, Media, TV & film
                      production
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Broadcasting</li>
                      <li>Media and new media</li>
                      <li>Music</li>
                      <li>TV and film production</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Commercial Licence</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>
                        Branch of a Foreign Company (parent company should be in
                        existence for a minimum of 2 years)
                      </li>
                      <li>
                        Branch of a UAE Company including entities established
                        in other UAE Free Zones
                      </li>
                      <li>FZ LLC</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dso.jpg?cx=0.5&cy=0.5&cw=440&ch=440"
                  className="card-img-top"
                  alt="Mainland companies"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai Silicon Oasis (DSO)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Maket specialisation:</h5>
                    <p>
                      Construction, Education, Energy, Industrial, Tech and ICT,
                      Real estate, Trade
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Construction</li>
                      <li>Education</li>
                      <li>Energy</li>
                      <li>Industrial</li>
                      <li>Information Technology</li>
                      <li>Real Estate</li>
                      <li>Trade</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Service Licence</li>
                      <li>Trading Licence</li>
                      <li>Industrial Licence</li>
                      <li>Special Licence</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>Branch of a Foreign Company</li>
                      <li>Branch of a UAE Company</li>
                      <li>Free Zone Establishment (FZE)</li>
                      <li>Free Zone Company (FZCO)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dcc.jpg?cx=0.26&cy=0.46&cw=440&ch=440"
                  className="card-img-top"
                  alt="Dubai CommerCity"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai CommerCity (DCC)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Market specialisation:</h5>
                    <p>
                      Construction, Food & beverages, Education, Financial
                      services, Manufacturing, Mining & quarrying, Insurance
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Mining & Quarrying</li>
                      <li>Manufacturing</li>
                      <li>Construction</li>
                      <li>Food and beverages Trading</li>
                      <li>Education</li>
                      <li>Financial and insurance activities</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Trade Licence</li>
                      <li>Service Licence</li>
                      <li>Industrial Licence</li>
                      <li>eCommerce Licence</li>
                      <li>General Trading Licence</li>
                      <li>Dual Licence with DET</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>Branch of a Foreign Company</li>
                      <li>Branch of a UAE company</li>
                      <li>Free Zone Company (FZCO)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dmcc.jpg?cx=0.5&cy=0.5&cw=440&ch=440"
                  className="card-img-top"
                  alt="Dubai Multi Commodities Centre"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai Multi Commodities Centre (DMCC)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Market specialisation:</h5>
                    <p>
                      Aviation, Commodities, Construction, E-commerce,
                      Education, Energy, Financial services, Agriculture, Food &
                      beverages, Logistics, Healthcare, Hospitality, Media, Tech
                      and ICT, Trade
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Aviation</li>
                      <li>Commodities</li>
                      <li>Construction</li>
                      <li>E-commerce</li>
                      <li>Education</li>
                      <li>Energy</li>
                      <li>Financial services</li>
                      <li>Food and agriculture</li>
                      <li>Freight and logistics</li>
                      <li>Healthcare</li>
                      <li>Hospitality</li>
                      <li>Information Technology</li>
                      <li>Media and new media</li>
                      <li>Trade</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Trading Licence</li>
                      <li>Industrial Licence</li>
                      <li>Service Licence</li>
                      <li>Dual Licence with DET</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>Branch of a Foreign Company</li>
                      <li>Branch of a UAE Company</li>
                      <li>DMCC Company</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "0px 50px",
          color: "#505050",
        }}
      >
        <h3>Choose your free zone company structure</h3>
        <b>
          Free zones in Dubai offer several legal forms to choose from, with
          specific options within each zone.
        </b>
        <div>
          <p>The most common free zone legal forms include:</p>
          <p>
            <b>Free Zone Establishment (FZE):</b>
            <br />
            <br />A legal entity incorporated/registered by a single
            shareholder, who can either be an individual or a corporate entity.
          </p>
          <b>Free Zone Company (FZCO):</b>
          <div>
            <p>
              A legal entity incorporated/registered with a minimum of two
              shareholders, who can be either individuals or corporate entities.
            </p>
          </div>

          <b>Public Limited Liability Company (PJSC):</b>
          <div>
            <p>
              A corporate entity with multiple shareholders that can offer its
              shares to the public for trading on the stock exchange.
            </p>
          </div>

          <b>Private Limited Liability Company (LLC):</b>
          <div>
            <p>
              A corporate entity with limited liability, formed by a minimum of
              two and a maximum of 50 shareholders, not open to public
              subscription or trading of shares.
            </p>
          </div>

          <b>Branch of a Local or International Company:</b>
          <div>
            <p>
              An extension of an existing local or international company,
              allowing it to conduct business in Dubai while remaining legally
              dependent on its parent company.
            </p>
          </div>

          <div>
            <p>
              For detailed information on company structure in a particular free
              zone, please see that zone's website, or contact their support
              team.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "50px 50px",
          color: "#505050",
        }}
      >
        <h3>Start the free zone company set-up process</h3>
        <b>
          Setting up a freezone company can mostly be done online, thanks to
          streamlined processes.
        </b>
        <div>
          <p>
            Free zones make the process of setting up a company as smooth as
            possible, but make sure you have everything you'll need to complete
            the process – including all relevant paperwork, business plans, and
            anything else the free zone will need.
            <br />
            <br />
            Every free zone is different, so check your chosen zone's specific
            requirements and timelines before starting.
          </p>
          <ul>
            <p>Typical steps include:</p>
            <li>
              Registering the company, for which the owner needs to provide a
              copy of their passport, plus the original company documents if
              setting up a branch office in Dubai.
            </li>
            <li>Leasing business premises.</li>
            <li>
              Obtaining a business licence, which takes, on average, 3-5 working
              days.
            </li>
            <li>
              Setting up additional business services, such as setting up a bank
              account and arranging visas for foreign employees.
            </li>
          </ul>
        </div>
      </div>

      <div className="container my-5">
        <h2 className="mb-4">More from Dubai for business</h2>
        <p className="mb-5">
          Browse information on Dubai's business landscape and how to start or
          move your business to Dubai.
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Mainland companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Mainland companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  If you're looking to trade within the UAE, or prefer not to be
                  within a free zone, establishing a mainland company is the
                  path.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Free zone companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Free zone companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Learn more about Dubai's free zones and the process of setting
                  up a company within them.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, Regulations, legal compliance and taxation
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Support for start-ups and SMEs
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Getting the right help is crucial for a new company – and
                  Dubai has plenty of support options.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, administration and logistics
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  All the information you need about visas, bank accounts,
                  employment, and business premises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
