import { useState } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import { IoIosEye, IoMdEyeOff } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
  };
  const handleGoogleLogin = () => {
    // Handle Google Sign-In logic here
    console.log("Signing in with Google");
  };
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Form
        onSubmit={handleLogin}
        style={{
          width: "100%",
          maxWidth: "400px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h3 className="text-center mb-4">Register</h3>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Full Name"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Phone Number"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <Button
              onClick={togglePasswordVisibility}
              variant="outline-secondary"
              style={{
                borderTopRightRadius: "0.375rem",
                borderBottomRightRadius: "0.375rem",
                border: "1px solid #dee2e6",
                height: "38px",
              }}
            >
              {showPassword ? <IoIosEye /> : <IoMdEyeOff />}
            </Button>
          </InputGroup>
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100 mb-3">
          Register
        </Button>

        <Button variant="danger" className="w-100" onClick={handleGoogleLogin}>
          <FcGoogle className="me-2" /> Sign in with Google
        </Button>
        <p style={{ textAlign: "center" }}>
          Do you have account{" "}
          <b>
            <Link to="/login">Login</Link>
          </b>{" "}
          now
        </p>
      </Form>
    </Container>
  );
}
