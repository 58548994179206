import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";

export default function Health() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Healthcare and pharmaceuticals
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            Preferred destination for healthcare and pharma
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai’s healthcare and pharmaceuticals sector has seen substantial
            annual growth in recent years. World-class healthcare
            infrastructure, an advanced manufacturing sector, extensive trade
            links and extensive logistical capabilities have attracted top
            healthcare providers and multinational pharmaceutical companies.
            <br />
            <br />
            Dubai has made strides in pioneering advanced technologies,
            including AI (artificial intelligence) in public health. Its
            healthtech ecosystem is also seeing rapid expansion, bolstered by
            easy access to capital and pro-investor strategies. Dubai-based
            healthcare and pharma companies benefit from its conducive
            environment for R&D and scientific breakthroughs, enabling the
            commercialisation of innovative health-focused solutions.
          </p>
          <h5>Unlocking opportunities in an expanding sector</h5>
          <p>
            Dubai is ideally positioned to tap into this flourishing market and
            play a pivotal role in driving rapid growth in the healthcare sector
            of the entire MENA region, which has some of the region’s most
            well-developed private health facilities. The city boasts 56
            hospitals, 57 day-care surgery centres, 59 diagnostic centres and
            more than 1,500 specialised outpatient clinics. It has over 1,300
            pharmaceutical facilities, 17 medicine storage facilities and 410
            optical centres.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/healthcare-pharmaceuticals-header-image-people-focused.jpg?rev=8e9b4368f5db4e4fbc2b32b8b95862fb&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Healthcare innovation</h4>
            <p>
              is a key pillar of the ‘D33’ and pharma is one of 11 vital sectors
              under Operation 300bn.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/event/j/doctorpatient-shutterstock-feb-2022.jpg?rev=67454084ac174582b7c7d5ea9d0f6694&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>‘Doctor for Every Citizen’</h4>
            <p>
              offers city-wide free telehealth consultations with doctors and
              specialists.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/health-tech-gettyimages.jpg?rev=58d5edeba1b64b7ba84cc3131b0788a5&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Advanced technologies</h4>
            <p>
              in healthcare, including 3D modelling, robotic pharmacy, health
              apps and smart fitness.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/mbru-mohammed-in-rashid-medical-academic-center-exterior.jpg?rev=0a432cb5095e441d83571240bdc99528&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Government investments</h4>
            <p>
              in healthcare, pharmaceutical production and medical device
              innovation.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/event/w/world-hospital-congress-shutterstock-oct-2022.jpg?rev=3b47c053dd0547d69c0ad7568422d2be&cx=0.62&cy=0.3&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Long-term residency</h4>
            <p>
              for scientists and outstanding talents in various sectors,
              including doctors, medical professionals and pharma experts.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Extensive resources and support ecosystem</h3>
          <p>
            Dubai is a leader in healthcare and pharmaceutical innovation and
            home to the world’s largest medical-free zone, Dubai Healthcare City
            (DHCC). The DHCC boasts a vast network of resources, with ten
            hospitals, 168 clinical facilities, and 95 regional headquarters of
            healthcare multinational firms, with plans to expand. The DHCC is
            expected to contribute US$925.8 million (AED3.4 billion) to Dubai’s
            GDP by 2030 and support more than 16,000 jobs.
          </p>
          <h4 style={{ marginTop: "20px" }}>
            Strong logistics and distribution support
          </h4>
          <p>
            Jebel Ali Free Zone Authority (JAFZA) is a premier healthcare
            manufacturing and distribution location, offering competitive
            advantages such as zero tax, 100% ownership, and no import/export
            duties. With over 400 healthcare and pharmaceutical companies from
            60+ countries, JAFZA collaborates with Imperial Logistics (acquired
            by DP World) to operate a medical manufacturing and solutions hub,
            witnessing a seven-fold increase in export trade value in 2023. With
            its strategic proximity to Jebel Ali Port and Al Maktoum
            International Airport, JAFZA ensures smooth trade connectivity for
            healthcare customers to vital destinations across Europe, Africa,
            and Asia.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Premier destination for health and wellness</h3>
        <p>
          Dubai’s distinction in medical tourism is due to its proximity and
          accessibility to regional markets, attracting patients from all around
          the globe. The Dubai Health Authority’s (DHA) Health Tourism
          Department offers a seamless experience for health tourists through
          the Dubai Health Experience (DHX), bringing together 80 of the city’s
          leading healthcare providers into one platform for easy access to
          information.
          <br />
          <br />
          Additionally, Free zones like Dubai Science Park (DSP) and Dubai
          Healthcare City offer attractive incentives for healthcare companies,
          including tax advantages, foreign ownership, and state-of-the-art
          infrastructure. These zones host a plethora of companies ranging from
          pharmaceutical giants to innovative startups, fostering an ecosystem
          conducive to healthcare innovation and growth. DSP is home to 400+
          companies, including global giants like Pfizer, Bayer HealthCare
          Pharmaceuticals, Amgen and Pharmax Pharmaceutical.
        </p>
        <h3 style={{ marginTop: "20px" }}>
          Tech-forward healthcare transformation
        </h3>
        <p>
          Dubai is leading revolutionary initiatives to solidify its position as
          a global epicentre for healthcare development. These initiatives
          include Operation 300bn, a key strategy by the Ministry of Industry
          and Advanced Technology, designed to boost the country’s industrial
          sector. Underlining tech adoption and increased innovation, the plan
          covers 11 vital industrial sectors, one of which is pharmaceuticals.
          <br />
          <br />
          Dubai’s ambition to develop an environment conducive to healthcare
          start-ups is in line with the Dubai Economic Agenda D33. Further, the
          city’s 2030 Industrial Strategy emphasises elevating the
          pharmaceutical and medical device industries' manufacturing
          capabilities, output, and value-addition.
        </p>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Preparing for a growing populace</h4>
        <p>
          Dubai's population is projected to reach 5.8 million by the end of
          this decade. As a proactive response, the city has unveiled the Dubai
          2040 Urban Master Plan, which promotes sustainable urban development,
          improving the quality of life for residents and boosting Dubai's
          appeal as a leisure and business destination.
        </p>
      </div>
      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$4.63 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              average annual value of Dubai’s healthcare market.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>5.1% of GDP</h3>
            <p style={{ marginBottom: "0px" }}>
              expected contribution of Dubai’s healthcare sector by 2029.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Streamlining entrepreneurial growth</h4>
        <p>
          Healthtech in the region has experienced unprecedented growth,
          attracting domestic and foreign venture capital funding. Dubai is
          spearheading this growth, supported by various programmes and
          initiatives designed specifically for healthtech firms.
          <br />
          <br />
          This includes accelerators like the Dubai Healthcare City Accelerator
          (DHCX) and initiatives such as C37 and Masaar that support the growth
          of healthcare businesses. Freezones like the Dubai Multi Commodities
          Centre (DMCC) contribute to sectors such as medical engineering
          services and health awareness, as well as providing support to
          start-ups through accelerators, VCs, and more.
        </p>
      </div>
    </>
  );
}
