import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from '../helper/FormHelper';
import LoadingButton from './LoadingButton';

function EmailForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [need, setNeed] = useState('');
  const [message, setMessage] = useState('');
const [loading,setloading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      ErrorToast("Name is Required");
      return;
    }

    if (!email) {
      ErrorToast("Email is Required");
      return;
    }

    if (!phone) {
      ErrorToast("Phone Number is Required");
      return;
    }

    if (!need) {
      ErrorToast("Service is Required");
      return;
    }

    if (!message) {
      ErrorToast("Message is Required");
      return;
    }

    try {
      setloading(true)
      const response = await fetch('https://jkconsultancy-backend.onrender.com/send-mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          need,
          message,
        }),
      });

      if (response.ok) {
        setloading(false)
        const data = await response.json();
        SuccessToast("Form Submitted Successfully");
        setName('');
        setEmail('');
        setPhone('');
        setNeed('');
        setMessage('');
      } else {
        setloading(false)
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      ErrorToast("Something went wrong");
    }
  };

  return (
    <div className="calculator-container" style={{ height: '600px' }}>
      <h1 style={{ color: 'white' }}>Get a Free Consultation</h1>
      <div>
        <label style={{ color: 'white', fontSize: '16px', fontWeight: '600' }} htmlFor='name' >Name:</label>
        <input
          type="text"
          id='name'
          value={name}
          placeholder='Enter your name'
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label style={{ color: 'white', fontSize: '16px', fontWeight: '600' }} htmlFor='email'>Email: </label>
        <input
          id='email'
          type="email"
          value={email}
          placeholder='Enter your email'
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label style={{ color: 'white', fontSize: '16px', fontWeight: '600' }} htmlFor='phone'>Phone: </label>
        <input
          id='phone'
          type="text"
          value={phone}
          placeholder='Enter your phone'
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </div>
      <div>
        <label style={{ color: 'white', fontSize: '16px', fontWeight: '600' }} htmlFor='service'>Services: </label>
        <select
          id='service'
          type="number"
          value={need}
          onChange={(e) => setNeed(e.target.value)}
          required
        >
          <option value="" selected disabled style={{ color: 'white', fontSize: '16px', fontWeight: '600' }}>
            Select Your Service
          </option>
          <option>Startup Basic</option>
          <option>Startup Exclusive</option>
          <option>Startup Premium</option>
          <option>Company Formation</option>
          <option>Society Registration</option>
          <option>Partnership Registration</option>
          <option>RJSC Return Fillig</option>
          <option>Loan Consultancy</option>
          <option>Import License (IRC)</option>
          <option>Export License (ERC)</option>
          <option>BSTI Registration</option>
          <option>Trade License</option>
          <option>Individual TAX</option>
          <option>Corporate TAX</option>
          <option>Value Added TAX (VAT)</option>
          <option>TIN Registration</option>
          <option>BIN Registration</option>
          <option>Trademark</option>
          <option>Copyright</option>
          <option>Patent</option>
          <option>Industrail Design</option>
          <option>IPR Enforcement</option>
          <option>BTCL Domain Registration</option>
          <option>Foreign Company Foramation</option>
          <option>Foreign Direct Investment</option>
          <option>BIDA Permission</option>
          <option>Work Permit VISA</option>
          <option>Foreign Delegates</option>
          <option>Account Service</option>
          <option>Book Keeping Services</option>
          <option>Small Business</option>
        </select>
      </div>
      <div>
        <label style={{ color: 'white', fontSize: '16px', fontWeight: '600' }} htmlFor='message'>Message: </label>
        <textarea
          id='message'
          type="text"
          value={message}
          placeholder='Enter your message'
          style={{ width: "100%" }}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      </div>
      {
        loading ? <button className='form-submit-button' disabled style={{ width: '100%',display:"flex",alignItems:"center",justifyContent:"center", gap:"5px" }}>
        Loading<LoadingButton style={{width:"20px",height:"20px"}}/>
      </button> :  <button className='form-submit-button' onClick={handleSubmit} style={{ width: '100%' }}>
        Submit
      </button>
      }
     
    </div>
  );
}

export default EmailForm;

