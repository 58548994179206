import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import etinImg from "../../assets/images/e-tin.png"
import etin_og_img from "../../assets/og-img/E-Tin Registration.png"
import Marquee from 'react-fast-marquee';
import etinImg2 from "../../assets/images/TIN-Registration.png"
import etinImg3 from "../../assets/images/Filling-Your.png"
import { taxRelatedArticales } from '../../data/relatedArticale';

function ETINCertificate() {
  const faqItems = [
    {
      title: 'How do I get my e-TIN certificate?',
      content: <strong>Applicants will receive a TIN certificate from the National Board of Revenue (NBR) on successful registration. In Bangladesh, one can apply to NBR for a TIN certificate by physically visiting the branch or online.
        If an applicant obtains the TIN certificate in Bangladesh electronically, it is called E-TIN.</strong>
    },
    {
      title: 'How can I cancel my TIN certificate in Bangladesh?',
      content: <strong>A Comprehensive Guide
        Applying to the Tax Circle Office</strong>
    },
    {
      title: 'How do I verify my TIN number app?',
      content: <strong>TIN Inquiry button will take you to a page that asks for your name, birth date, address, civil status, spouse name, and a selfie with a valid Government ID.
        The same details are required for TIN Validation requests plus your TIN.</strong>
    }
  ];
  return (
    <>
      <Meta title="E-TIN Certificate"
        description="This page provides information about BSTI permission and related processes."
        image={etin_og_img}
        keywords="keyword1, keyword2, keyword3"
      />
             

      <div className="container-fluid hero-header bg-light py-5 ">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1 className="display-4 mb-3 animated slideInDown">E-TIN Certificate</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">E-TIN Certificate</li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={etinImg} alt='Tin-Registration-1' />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>Taxpayer’s Identification Number (TIN) </b>
          </p>
          <p>
            Electronic Taxpayer’s Identification Number (e-TIN) is provided by the National Board of Revenue (NBR). Each and every entity (Individual or Corporate) must have the Tax Identification Number who is eligible to pay Tax according to the Income Tax Ordinance 1984.
          </p>
          <p>
            <img className="mx-auto responsive-img" src={etinImg}
              alt='e-tin' style={{ width: 1400, height: 300 }}
            />
          </p>
          <p className="color-text fs-5 mb-4">
            <b>Who in Bangladesh has to get an e-Tin Certificate?</b>
          </p>
          <p>
            Everyone in Bangladesh is not required to have a Tin Certificate. The person will be regarded as a Tax Payer because possessing a Tin Certificate entails having a Tax Identification Number. Hence, if one has a Tin Certificate, they may be eligible for additional benefits when using public and private offices like banks and financial organizations. Nonetheless, the following circumstances need the possession of a Tin Certificate:
          </p>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  "  >
              <img className="img-fluid" src={etinImg2} alt='TIN-Registration.png' />
            </div>
            <div className="col-lg-6  "  >
              <div className="h-100">
                <p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      If total of the person income during a income year exceeds the maximum amount that is not chargeable to tax under this Ordinance;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      If basic salary of a person is BDT 16,000/Month or more in any public office then you are required to file Income Tax Return and for filing Tax Return you must have a Tin Certificate;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Owns a motor car;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Owns a membership of a club registered under value added tax;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Runs for an office of any Paurashava, City Corporation, or a Member of Parliament;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Participates in a tender floated by the government, semi-government, autonomous body or a local authority;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Serves in the board of directors of a company or a group of companies;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Runs any business or profession having trade license from a city corporation, a Paurashava or a Union Parishad;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      registered with a recognized professional body as a Doctor, Dentist, Lawyer, Chartered Accountant, Cost and Management Accountant, Engineer, Architect or Surveyor or any other similar profession; or
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Registered with the Board as an Income Tax Practitioner;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      For Opening a Bank Account it doesn’t require a Tin Certificate. However for having a Tin Certificate one may have some advantages on interest.
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Has a membership of a Chamber of Commerce and Industries or a Trade Association or body;
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Wants to buy Prize Bond or Saving Certificates from Banks.
                    </span>
                  </div>
                </p>
                <p className="color-text fs-5 mb-4">
                  <b>What We Do</b>
                </p>
                <p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Submitting an online application in the proper way by attaching required documents appropriately.
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Completing the TIN application, receiving an e-TIN certificate, Printing, and providing the same to respected clients.
                    </span>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-0">
        <div className="container">
          <p>
            <img className="mx-auto responsive-img" src={etinImg3}
              alt='Filling-Your' style={{ width: 1400, height: 300 }}
            />
          </p>
        </div>
      </div>

      <div className='container-xxl center-background1'>
        <h2> Related Articles</h2>
      </div>

      <div className='container-xxl center-background1'>
        <Marquee className="marquee-container">
          {
            taxRelatedArticales
          }
        </Marquee>
      </div>

      <div className="container py-5 my-0">
        <h2 className="color-text text-center mb-4">E-TIN Certificate FAQ</h2>
        <Faq items={faqItems} />
      </div>
             
    </>
  )
}

export default ETINCertificate