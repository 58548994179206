import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import bida2 from "../../assets/images/Bida-Permission.png"
import BidaImg from "../../assets/images/Bida-.png"
import Bida_og_img from "../../assets/og-img/BIDA Permission.png"
import Marquee from "react-fast-marquee";
import { fpRealaatedArticales } from '../../data/relatedArticale';

function BidaPermission() {
    const faqItems = [
        {
            title: 'What is a BIDA Investment license?',
            content: <strong> BIDA Act, 2016 requires all investors for industrial projects (those located outside
                the jurisdiction of BEZA, BEPZA, BHTPA, BSCIC) to register their investments with BIDA. BIDA
                registration is not necessarily required for commercial and trading activities, buying houses and
                service-oriented institutions.</strong>
        },
        {
            title: 'Why BIDA registration is required in Bangladesh?',
            content: <strong> BIDA Registration is mandatory for the concerned investors to be eligible. For
                privileges and facilities declared by the government. To apply for investment-related licensing
                and permits such as expatriate work permits, foreign borrowings, and import permits/
                registration certificates for industrial use</strong>
        },
        {
            title: 'What is the duration of BIDA work permit?',
            content: <strong>For foreign expert work permit in bangladesh issued for 12-Months, very first time, Private invest VISA renewal comes with 24 months.</strong>
        }
    ];
    return (
        <>
            <Meta title="BIDA Permission FDI | BIDA Registration in Bangldesh"
                description="The BIDA Permission offers a range of valuable business investment, fdi registration services to the international and national investment community. BIDA registration certificate. 
                The following documents must be submitted with the application of work permit: Permission letter of branch/ liaison/ representative office. To register a business with the Bangladesh Investment Development Authority (BIDA), you can follow these steps"
                image={Bida_og_img}
                keywords="bida permission, bida registration, foreign investment, BIDA registration certificate, why bida registration is required in bangladesh, fdi registration, business registration, bida factory registration, foreign company registration, business investment registration"
            />
                   

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">BIDA Permission</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Foreign Entity</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">BIDA Permission</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={BidaImg} alt='Bida' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>BIDA Permission Process/Procedure for local & foreign companies in Bangladesh:
                            <br /><u>Ease of Doing Business 2020</u></b>
                    </p>
                    <p>
                        The economy of Bangladesh is a major developing mixed economy. As per the report, Bangladesh is the 35th largest economies in 2022 with a GDP size of US$460.8 billion. 
                        Earlier, the rank of Bangladesh was 41st, an improvement of eight rankings from that of last year. Bangladesh 
                        is a developing mixed economy and the second-largest economy in South Asia. The 2024 DB score is 45.0, which is 3.03 points
                        higher than that of last year BIDA Permission.<br /><br />

                        Improvements were made in three of the DB indicators:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Starting a business: </b>Bangladesh made starting a business
                                less expensive by reducing name clearance and registration fees, and
                                abolishing the fee for certifying digital certificates. This reform applies
                                to both Dhaka and Chittagong.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Getting electricity: </b>Bangladesh made getting electricity faster by
                                investing in digitization and human capital at the utility. Bangladesh also
                                made getting electricity less costly by reducing the amount of the security
                                deposit for a new connection. This reform applies to Dhaka.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Getting credit: </b>Bangladesh improved access to credit information by
                                 expanding the coverage of the credit information bureau.
                            </span>
                        </div>
                        The Government of Bangladesh has taken a number of positive initiatives to drive DB
                        reforms including developing Action Plans, forming Taskforces, coordinating reform
                        initiatives among relevant government agencies, providing reform support to line
                        agencies, conducting dialogues with private sector stakeholders, and monitoring
                        reform progress.<br /><br />

                        The Government of Bangladesh, in partnership with the International Finance
                        Corporation (IFC), has been working on the DB reforms agenda. IFC has been providing
                        necessary technical support in developing the DB Reform Action Plan and its
                        implementation BIDA Permission.<br /><br />

                        The Government aims to achieve double-digit DB ranking by 2021 for which reform
                        momentum requires acceleration and big-ticket reforms need successful implementation
                        including amendment of Companies Act, Bankruptcy Act, Arbitration Act and Imarat
                        Nirman Bidhimalas, enactment of Secured Transaction Bill, the establishment of
                        Commercial Dispute Resolution Court, and introduction of efficient inspection
                        systems for border compliance by developing risk-profiles of businesses (BIDA
                        Permission)
                    </p>
                </div>
            </div>

            <div className="container-xxl py-3">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  " >
                            <img className="img-fluid" src={bida2} alt='Bida-Permission' />
                        </div>
                        <div className="col-lg-6  " >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">Investment in Bangladesh:</p>
                                <p>
                                    Bangladesh offers an unparalleled investment climate compared to the other
                                    South Asian economies. Eight key pointers are given below:
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Bangladesh is a largely homogeneous society with no major internal
                                            or external tensions and a population with great resilience in the
                                            face of adversity (e.g. natural calamities).
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Bangladesh maintains a liberal democracy. The population of this
                                            country irrespective of race or religion has been living in
                                            harmony and understanding for thousands of years.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Broad non-partisan political support for market-oriented reforms
                                            and the most investor-friendly regulatory regime in South Asia.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Trainable, enthusiastic, hardworking, and low-cost (even by
                                            regional standards) labor force suitable for any labor-intensive
                                            industry.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Bangladesh is endowed with an abundant supply of natural gas, water, and its
                                            soil is very fertile.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Although Bengali (Bangla) is the official language, English is
                                            generally used as a second language. The majority of the educated
                                            population can read, write and speak in English.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            As a result of low per capita GDP, the present domestic consumption
                                            is not significant. However, it should always be considered that
                                            there exists a middle class with over 10% of the population. As
                                            economic growth picks up, the purchasing power will also grow
                                            substantially.
                                        </span>
                                    </div>
                                    Bangladeshi products enjoy duty free and quota free access to almost all
                                    the developed countries. This access to the global market is further
                                    helped by the fact that the policy regime of Bangladesh for foreign
                                    direct investment is by far the best in South Asia. Most Bangladeshi
                                    products enjoy complete duty and quota free access to EU, Canada,
                                    Australia and Norway. Though in limited scale, Bangladesh products
                                    already found their access with lower duty in the markets of Thailand,
                                    India and Pakistan. However, talks are underway with China, Russia,
                                    Malaysia and other neighboring countries in this regard.
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Bangladesh Export Competitiveness
                                </p>
                                <p>
                                    Bangladesh, despite being the least developed economy, has a proven record
                                    in export competitiveness. Here is a summary of the facts. From 2003 to
                                    2017 Bangladesh achieved annual export value growth of 19.6%, a testimony
                                    to its export competitiveness. Whilst not wishing to be complacent, and
                                    being mindful of difficult global trade conditions in 2008-2010, these
                                    positive trade differentials are likely to be with Bangladesh well into
                                    the future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Competitive Cost Base</b>
                    </p>
                    <p>
                        In January 2010, JETRO conducted a comparative survey of investment-related costs in
                        29 major cities and regions in Asia. The following comparison is based on that survey
                        with some selected cities. Bangladesh offers a truly low competitive cost base. Wages
                        and salaries are still the lowest in the region, a strong business advantage. Yet this
                        is an increasingly well-educated, adaptive, and peaceful population with many skilled
                        workers.
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Dhaka’s skilled labor cost base is still less than the other major cities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Dhaka’s management grades are 2-3 times less than Singapore, Shanghai, Bangkok.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Industrial estate rent in Dhaka is cost-effective than Shanghai, Jakarta, Bangkok.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Office rents are also very competitive with other international cities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Dhaka’s housing rent for foreigners is less expensive than Singapore, Mumbai, Karachi, Hanoi.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Cost of diesel in Dhaka is found to be more competitively priced than most other large cities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Vehicles increasingly use LPG as Dhaka gasoline costs are competitive with most other cities.
                            </span>
                        </div>
                        BIDA Permission notes that the figures are not absolutes, but serve as a guide.
                        Whilst the actual costs vary each month the general trends remain similar. No
                        precise figures are shown with the graphs because they are only a ‘snapshot’ of
                        costs on the day of survey.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Fiscal-and-Non-Fiscal-Incentives</b>
                    </p>
                    <p>
                        Bangladesh offers some of the world’s most competitive fiscal non-fiscal incentives.
                        BIDA can advise further on this matter. In summary and in most cases, these amount
                        to the following:<br /><br />

                        Remittance of royalty, technical know-how, and technical assistance fees.<br /><br />

                        Repatriation facilities of dividend and capital at exit.<br /><br />

                        Permanent resident permits on investing US$ 75,000 and citizenship on investing US$ 500,000.<br /><br />

                        If you wish to set up an operation in Bangladesh, your best contact point is the
                        Office of the Bangladesh Investment Development Authority (BIDA), which operates
                        under the Prime Minister’s office and is the principal government agency for
                        encouraging investment. The BIDA Permission is available to assist you in numerous
                        ways, including:
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Enhancing Competitiveness and Investment Facilitation</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Offers an attractive and competitive package of tax incentives.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Imposes no foreign equity restrictions on manufacturing activities or on some service.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Provide assistance in the provision of visas and work permits to facilitate
                                entry and subsequent operation for a foreign-owned business.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Waives restrictions on land ownership by foreign entities.
                            </span>
                        </div>
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>Business Support Services</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Provides comprehensive information and advice on establishing operations in Bangladesh.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Arranges site visits.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Identifies potential suppliers, subcontractors, joint-venture partners.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Provides useful contacts with key public and private organizations.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Coordinates between the foreign business community and other public agencies.
                            </span>
                        </div>
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>Assistance in Setting up a Business with BIDA Registration (BIDA Permission)</b>
                    </p>
                    <p>
                        The BIDA Permission offers a range of valuable business-related services to the
                        international and national investment community. The office helps prospective
                        investors and promoted firms in obtaining official permits and documents required
                        for conducting business, including visas, work permits, and permanent residency
                        permits. The BIDA also assists investors in gaining access to utilities such as
                        water, electricity, gas, land, and telecommunications
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>BIDA Registration for industries and Entity<br />
                            The BIDA requried documents for registration
                        </b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Incorporation certificate, MoA, AoA, from 12 from Joint Stock Register’s office company registration
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                               Trade License upto date
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                               Encasement certificate for foreign equity company
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Tax Identification Number (TIN) Certificate for Company
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Rent deed agreement for registered office, and others land deed papers (if any)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Employees list, mechinary list, raw materials list
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Company authorised letter, Board Resulation, seal, letterhead pad
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Bank solvancy and BIN registration certificate
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                All directors NID, Pictures, TIN certificate, BIO Data, (if foreign directors passport copy, TiN certificate)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Online services also provided by BIDA (email and phone number)
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Information on Investment Opportunities</b>
                    </p>
                    <p>
                        The Investment Services Center maintains comprehensive information on investment
                        opportunities in Bangladesh, both by sector and by the district. Information and
                        investment advice is readily available to both Bangladeshi and foreign investors at
                        no charge.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>One-Stop Service Center <br />
                            The BIDA Permission coordinated the establishment of a One-Stop Service Center
                        </b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Trade License from respective city corporations and local government bodies are
                                given within 48 hours, assuming all required documents are provided. Bangladesh
                                Investment Development Authority with local government division insures this
                                service.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Company registration from Joint Stock Register’s office company registration complete within 48
                                hours.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Registration of Bangladesh Investment Development Authority is complete within a
                                day, assuming all required documents are provided.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Electricity connection’s results are given within 10 days after receiving the
                                application from BIDA where a representative of power development board authority
                                is working.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Assistance for Land acquire is supported by BIDA.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Environmental certificates are given within 10 days from BIDA where respective
                                officers from the Environment Department are working.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                From Bangladesh Investment Development Authority representative of Titas Gas
                                Authority, the result of gas connection is given within 10 days from the date
                                of application received.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Foreign loan borrowing application disposed within 30 days assuming all required
                                documents are provided.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Tax-related complication is taken from BIDA by respective National Board of
                                Revenue officer after getting an application from entrepreneur and result are
                                given within 10 days.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Online services also provided by BIDA
                            </span>
                        </div>
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>Visa Processing Service for foreign Nationals in Bangladesh.</b>
                    </p>
                    <p>
                        Experts/advisers/employees/individuals appointed in government/semi-government/
                        autonomous bodies/ projects and equivalent organizations.  Or Individuals employed in
                        local/foreign government/semi-government/liaison/Industrial/commercial organizations
                        or other equivalent organizations.  The Bangladesh Investment Development Authority
                        (BIDA) has slapped a restriction on recruitment of low-skilled foreign workers in the
                        country’s industrial and commercial enterprises.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Applicable to individual visiting for:</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Supply
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Installation
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Maintenance of equipment and software
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Supervision of projects
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Main purpose/objective of the visa</b>
                    </p>
                    <p>
                        Supply of software and machineries/ installation/maintenance/training/supervision/project
                        visit
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Duration</b>
                    </p>
                    <p>
                        Maximum of Three Months
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Extension in Bangladesh</b>
                    </p>
                    <p>
                        Once the expatriate into Bangladesh with the short visa, he needs to obtain the
                        work-permit from BIDA/BEPZA. The work-permit can be granted for a period of One
                        to two years.<br /><br />

                        Then the expatriate needs to apply to the Department of Immigration and Passport
                        (DIP) along with relevant documents for extending the visa.<br /><br />

                        The DIP upon receiving the Security clearance and the work-permit can extend the visa
                        for the full employment period or maximum three years, whichever is shorter.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>Visa Requirement under as follows:</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                2 recent pp size photographs (for all)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Dollar endorsement $150.00 from Gov. Authorized bank or 3-month bank statement (Till to Currant Date)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Company forwarding latter (for privet service)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Company visiting card (for all over)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Trade License Certificates photocopy (for Provider)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Visa processing for1 to 4 working days.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Electric Bill copy /Phone Bill/ Gas bill/ nationality certificate(last moth Photocopy)
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                All document 2 seat, 1 seat original & 1 seat photocopy with passport.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Authorization latter to Embassy (I D Card/ Visiting Card & authorized Signature as per passport)
                            </span>
                        </div>
                    </p>


                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                   
                   {
                    fpRealaatedArticales
                   }
                   
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">BIDA Permission FAQ</h2>
                <Faq items={faqItems} />
            </div>
                   
        </>
    )
}

export default BidaPermission