import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
import NewFaq from "./FaqTowColumn";
export default function SupportForManufacture() {
  const serviceFaq = [
    {
      question: "Guidance for exporters and buyers",
      ans: `If you are keen to export in Dubai, you can take advantage of several initiatives to promote offerings, gain market awareness and contribute to the industry’s overall profitability. Resources are available to companies that are not yet operational as well as those that are deemed export-capable. 

Programmes cover export assessment questionnaires, access to technical information, a range of training and consulting services, and trade analyses. UAE-based companies can make use of skills-enhancement courses when looking to expand abroad. Similarly, trade missions provide opportunities to gain first-hand knowledge of overseas markets through focused briefings and interactions with potential partners. Dubai Department of Economy and Tourism (DET) also collaborates with The International Trade Centre (ITC) on the SheTrades MENA Hub, which empowers female entrepreneurs in the region towards sustainable development, resilience and inclusive growth.

Buyers and international stakeholders are supported too, with market assessments, access to UAE trade publications, and the varied services of our overseas network offices, which can facilitate communications with Dubai-based entities.`,
    },
    {
      question: "Overseas trade network offices",
      ans: `Our established network of international offices and strategic partners extends the success of Dubai’s export sector by fostering connections around the world. Overseas buyers and suppliers can contact their nearest satellite office to maximise opportunities rendered by Dubai’s unique position as a natural gateway between east and west. 

Businesses abroad can request support in the way of registrations, practical advice and guidance, franchise development services and long-term growth strategies. They can also find out about attending upcoming exhibitions, roadshows and trade missions. Meanwhile, international representatives can help UAE-based companies with new market penetration, product launches and alternative product sourcing.

Key network office locations include KSA, Russia, India, Germany, Brazil, Egypt and Hong Kong, among several others.`,
    },
    {
      question: "Practical assistance",
      ans: `Manufacturers and exporters have a wealth of useful resources at their disposal. Verified businesses in Dubai can rely on ready-to-use marketing collateral, listings on government-backed websites and franchise development services, while small and medium-sized enterprises (SMEs) benefit from funding for export operations.

On the International front, investors can arrange for accompanied Dubai site visits and select buyers even receive financial support for sourcing trips.`,
    },
    {
      question: "Registration and industry information",
      ans: `Save time and effort with these dedicated portals:

• Register as an exporter or buyer: add and edit your company profile, submit service requests and get industry updates.
Visit website

• UAE exports and industry directory: Find all manufacturing companies that operate under 13 industrial sectors in the UAE. Use the HS Code (Harmonized System) to search for different products offered locally and internationally.
Visit website

• Exporters Gateway: Gain insights on Dubai's exports industry with infographics and annual reports on top performing markets, latest products and key sector trends. Explore export opportunities, compare markets and stay informed on upcoming events and trade shows.
Visit website`,
    },
  ];
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Support for manufacturers and exporters
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            From securing your manufacturing licence to accessing niche
            resources and operational services, find out how you can set up your
            manufacturing or export business and grow your international
            footprint from Dubai.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <p>
            A preferred base for entrepreneurs and mega corporations, Dubai is
            making strides in the international business arena. With its
            strategic location, historic import-export routes, state-of-the-art
            ports, advanced technologies and future-focused infrastructure, it
            is a gateway to the world and emerging as a production and trading
            powerhouse.
            <br />
            <br />
            Not only has Dubai’s industrial sector witnessed steady year-on-year
            growth, but it contributed 8.3% to the GDP in the first three
            quarters of 2023 alone. Thanks to specialised incentives that will
            enhance production, scale exports and favour innovation, the sector
            is well-poised to help the city double its GDP in a decade – a major
            goal of the D33 agenda.
            <br />
            <br />
            There’s no time like the present to be a manufacturer or exporter
            working in or through Dubai. Join leading companies and renowned
            SMEs already established here to enjoy incredible benefits –
            including tax-free zones, regulatory ease, a friendly business
            approach and support within an exciting ecosystem featuring
            competitors from diverse markets.
            <br />
            <br />
            Explore our extensive offerings tailored to manufacturers and
            exporters, or register your business today and get started.
          </p>
        </div>
      </div>
      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>Simplified setup since 2024</h3>
            <p style={{ marginBottom: "0px" }}>
              with the Dubai Unified Licence (DUL) which eases operational
              procedures and provides digital information access for businesses
              in the city
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>More than US$8 bn</h3>
            <p style={{ marginBottom: "0px" }}>
              has been allocated by Emirates Development Bank (EDB) to support
              priority on-ground manufacturing sectors over the next five years
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <NewFaq title={"Mainland incubators"} colNo={2} faqItems={serviceFaq} />
      </div>
    </>
  );
}
