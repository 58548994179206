import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../../components/Faq'
import Meta from '../../../components/Meta'
import bookeepingImg from "../../../assets/images/BookKeeping-.png"
import bookeeping_og_img from "../../../assets/og-img/Bookkeeping Services in Bangladesh.png"
import bookeepingProcessImg from "../../../assets/images/bookKeeping-procees.png"
import Marquee from 'react-fast-marquee';
import { serviceRelatedArticales } from '../../../data/relatedArticale'

function BookeepingServices() {
    const faqItems = [
        {
            title: 'What does full service bookkeeping include?',
            content: <strong>A full-charge bookkeeper can also manage payroll, handle deposits, create and maintain financial reports, manage the ever-changing world of sales taxes as well as quarterly taxes and withholding.
                Bookkeepers also reconcile bank statements to internal accounts and even help out during an internal or IRS audit.</strong>
        },
        {
            title: 'What is the bookkeeping service process?',
            content: <strong>The process of bookkeeping involves four basic steps: 1) analyzing financial transactions and assigning them to specific accounts; 2) writing original journal entries that credit and debit the appropriate accounts; 3)
                posting entries to ledger accounts; and 4) adjusting entries at the end of each accounting period.</strong>
        },
        {
            title: 'Is bookkeeping a financial service?',
            content: <strong>They will enter, store, and record your financial data. Although the terms are sometimes used interchangeably because bookkeepers and accountants share common goals,
                they refer to two different kinds of financial services</strong>
        }
    ];
    return (
        <>
            <Meta title="Bookeeping Services for Small Business in Bangladesh"
                description="a professional bookkeeper to do your books for you and to review your finances. It’s everything you need to forget about your bookkeeping"
                image={bookeeping_og_img}
                keywords="bookeeping, bookeeping service, small business, bpo bookeeping"
            />
                   

            <div className="container-fluid hero-header bg-light py-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Bookkeeping Services</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Audit</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Bookkeeping Services</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={bookeepingImg} alt='BookKeeping' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>Bookkeeping/Accounting Services in Bangladesh:</b>
                    </p>
                    <p>
                        Accounting for start-ups or small business organizations can be a cumbersome process.
                        Most of us do not understand the technical jargon the accountants speak. Although the
                        accountants provide you with the picture of how your business is running, and
                        accounts are required for fulfilling different regulatory requirements, maintaining
                        an in house professional accountant, takes up your office space, increases your cost,
                        increases paperwork, increases overhead cost and often the service that you get is
                        not up to your expectations. Accurate and practical financial advice and the right
                        financial data are pivotal for business profitability. In Bangladesh the need for
                        such Proficient accountancy is absolute. We understand the trouble you could have
                        seeking a premium Accounting Service in Dhaka and Chittagong, Bangladesh.  Like most
                        small business runners, you struggle continuously with the urge to improve this
                        business profitability and simultaneously stress yourselves by sorting out the means
                        of reducing taxes, manage overhead costs, and also scrape some personal time for
                        yourself and family.<br /><br />
                        We at Jk Associates in Dhaka, Chittagong, and all over Bangladesh brings you a
                        feasible solution to all these problems. We are a team of chartered accountants, cost
                        management accountants, and Barristers, which is ready for maintaining your accounts,
                        preparing your returns (income tax return, VAT return, etc.), advising on different
                        aspects of your business and providing bookkeeping services at an affordable cost.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid" src={bookeepingProcessImg} alt='bookKeeping-procees' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">Bookkeeping Services</p>
                                <p>
                                    With us you get a professional bookkeeper to do your books for you and to
                                    review your finances. It’s everything you need to forget about your
                                    bookkeeping, without actually forgetting about your bookkeeping. You don’t
                                    have to worry about reconciling your accounts, categorizing your day-to-day
                                    expenses, or matching your receipts. Bookkeeping services in Bangladesh has
                                    never been easier before.
                                </p>
                                <p className="color-text fs-5 mb-4">Invoice/bills/receipts and bank reconciliation statement</p>
                                <p>
                                    Jk Associates in Bangladesh can give you the scope of spending time on
                                    things that matter and waste less time on expenses, receipts, invoices, and
                                    bank. With our small business bookkeeping/accounting services, we can make
                                    almost all of your everyday paperwork disappear. And it’s all included in
                                    your fixed-price package. Take receipt and bills.
                                </p>
                                <p className="color-text fs-5 mb-4">Formalities of the regulatory authorities</p>
                                <p>
                                    We would prepare for you the income tax returns to be filed to the
                                    National Board of Revenue, different forms required for the annual filing
                                    with the Registrar of Joint Stock Companies & Firms (RJSC), preparation of
                                    different treasury challans for depositing money in the Government
                                    Collection Account, preparation of withholding tax return and VAT return.

                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Payroll
                                </p>
                                <p>
                                    We know and understand how much you crave for a fast, efficient, and
                                    trouble-free method for completing your payroll. Jk Associates'
                                    confidential, cost-effective & complete Payroll service eliminates the
                                    tedious chore of payroll processing in your office.  It is a natural
                                    extension of the monthly financial reporting and tax services we provide.
                                    In Bangladesh, Payroll services are either untidy or not in practice as a
                                    whole, but we understand that the services of quality will introduce a lot
                                    of people to receive it in great horizons.
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Personal Accountant responsibility for your bookkeeping services
                                </p>
                                <p>
                                    An experienced, professional dedicated accountant for your business who
                                    would be your point of contact and will be responsible for your
                                    bookkeeping, financial statements, tax return, and representing you in
                                    the National Board of Revenue. He will be an expert in growing businesses
                                    like yours and he will be someone who understands your sector too.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                  {
                    serviceRelatedArticales
                  }
                </Marquee>
            </div>


            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Bookeeping Services FAQ</h2>
                <Faq items={faqItems} />
            </div>
                   
        </>
    )
}

export default BookeepingServices