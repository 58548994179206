import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function VcPcCompanies() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Venture capital and private equity companies
          </h1>
          <p style={{ marginTop: "20px" }}>
            Catalysing venture capital and private equity growth.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai is the fastest growing venture capital ecosystem worldwide.
            Its business-first landscape and resilient financial infrastructure
            provide an ideal foundation for sustainable economic growth.
            Numerous strategies, initiatives and legislations, spearheaded by
            the overarching D33 agenda, are in place to position Dubai as one of
            the top four financial centres in the world.
            <br />
            <br />
            Pro-business tax policies, a stable political and economic
            environment and a future-ready financial setting present unique
            opportunities for investors and wealth management firms seeking to
            diversify their portfolios and expand into new geographies.
            <br />
            <br />
            The city's dynamic economy is also intrinsically connected to the
            wider regional ecosystem, giving investors easy access to emerging
            high-potential markets. An extensive network of Dubai’s start-up
            accelerators and business incubators add further incentives for
            corporate venture capital companies and private equity firms to set
            up base here.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h2>One-third</h2>
            <p style={{ marginBottom: "0px" }}>
              of all MENA investors live in Dubai
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h2>30%+</h2>
            <p style={{ marginBottom: "0px" }}>
              of all MENA deals closed in Q3 2023 were in the UAE
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Expanding investment opportunities </h3>
          <p>
            Dubai boasts a remarkably strong ecosystem for both venture capital
            and private equity investments. This is why the world’s most
            reputable banking institutions, private banks, hedge funds, and
            asset management and wealth management firms call this city home.
            <br />
            <br />
            More than a third of investors and the highest concentration of
            venture capital funds in the region are in Dubai. Over the past few
            years, the city has become the world’s top destination for both FDI
            and Greenfield FDI – a demonstration of its attractive investment
            proposition.
            <br />
            <br />
            Dubai also offers one of the region’s most vibrant start-up and
            scale-up ecosystems, powered by easy access to corporate venture
            capital. Currently, more than 40% of MENA’s (Middle East and North
            Africa) scale-ups are headquartered in Dubai, drawing 57% of the
            region’s total funding. The city also hosts 90% of the UAE’s
            scale-ups, a key pull factor for innovators, entrepreneurs and
            investors.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>The world's premier venture hub </h3>
        <p>
          Dubai is an attractive operating ground for top venture capital (VC)
          and private equity (PE) firms, with a major inflow of funding brought
          in by the digital and tech sectors. To offer additional support, the
          DIEZ (Dubai Integrated Economic Zones Authority) has set up a US$136
          million (AED492 million) corporate venture fund, named Oraseya
          Capital, specifically for tech start-ups in Dubai. Aligned with D33
          objectives, the fund fortifies the presence and expansion of SMEs
          across sectors, underscoring the city’s dedication to supporting
          innovation and economic growth.
          <br />
          <br />
          At the heart of this thriving ecosystem is the Dubai International
          Financial Centre (DIFC) Innovation Hub, a promising haven for both VC
          and PE firms in Dubai. Starting a Dubai VC firm in DIFC is
          straightforward, with zero regulatory capital required, and reduced
          costs for licences, visas and regulatory fees. Members also get access
          to the US$272 million (AED1 billion) Future District Fund, tailored
          for diverse needs based on a company's size, growth trajectory and
          other assets.
        </p>
      </div>
    </>
  );
}
