import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function PublicTransport() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Dubai's public transport: a comprehensive guide
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p>
            Local transport across the city is streamlined and affordable,
            allowing residents and visitors to explore every locality with ease.
          </p>
          <h2 className="resource-section-title">
            Uncover the city via a network of transport options
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Getting around Dubai is as simple as hailing one of the many taxis,
            walking to the nearest bus, tram or metro station, or even using one
            of the city’s famed water taxis. In combination, these services
            offer efficient, cost-effective and dependable travel, with easy
            access and dedicated areas for people of determination and
            designated seats for women and the elderly.
            <br />
            <br />
            Public transportation in Dubai is managed by the Roads and Transport
            Authority, which oversees the city’s rapidly expanding transit
            ecosystem. Customer satisfaction is a key goal for the RTA,
            prioritising comfort and convenience for commuters. The RTA website
            is also a comprehensive source of information and services,
            including Dubai public transport fares, metro and bus timetables,
            topping up travel passes (called Nol cards) and paying any fines.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Dubai Metro</h3>
          <p>
            A central component of the Dubai transport system is the city’s
            metro rail – known as Dubai Metro, it is the world’s longest
            driverless metro, spanning nearly 75 km.
            <br />
            <br />
            Riding the metro is as easy as getting to the closest station and
            purchasing a Nol card – a rechargeable travel pass that provides
            cheaper fares than buying a new ticket for every journey. The metro,
            with multiple stops, offers convenient access to the city's top
            attractions. People of determination will find convenient lift
            access available on all station levels, and dedicated spaces for
            wheelchair users on all trains.
            <br />
            <br />
            Dubai Metro currently operates on two lines – the Red and Green
            lines – across 49 stations, nine of them underground.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>RTA buses</h3>
          <p>
            Public transport buses within Dubai form an extensive network
            spanning 119 internal lines, with 35 linking to metro stations.
            Buses also operate from Dubai to the other six emirates: Abu Dhabi,
            Sharjah, Ajman, Ras Al Khaimah, Umm Al Quwain and Fujairah. There
            are feeder bus stations at five major hubs: Mall of Emirates | Al
            Rashidiya | Ibn Battuta | Abu Hail | Jebel Ali
            <br />
            <br />
            Dubai bus stations and buses are air-conditioned, and all vehicles
            offer facilities for people of determination, and dedicated seats
            for women and the elderly. Dubai bus fares are determined based on
            the number of stops, and payment is via rechargeable Nol cards – the
            same as for all other public transport in the city.
            <br />
            <br />
            RTA apps like S’hail allow commuters to locate the nearest bus stops
            and plan the most convenient route. After entering the starting
            point and final destination, the app provides starting options and
            fares, and allows commuters to plan their full journey.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Dubai taxis and ride-hailing</h3>
          <p>
            Taxis are one of the most popular travel options in Dubai.
            Specialised cabs cater to specific needs, with female drivers for
            women who prefer such a service and accessible vehicles for people
            of determination. Commuters can hail a taxi from the street, or
            using the following methods:
          </p>
          <ul>
            <li>Book a cab using the S’hail app</li>
            <li>
              Use private ride-hailing services like Uber, Hala taxi or Careem
              and their respective apps
            </li>
            <li>Book a Dubai cab by calling 800 88088</li>
          </ul>
        </div>
      </div>
    </>
  );
}
