import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function WorkRemotely() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Work remotely from Dubai
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p>
            Explore Dubai's one-year virtual working programme that lets you
            live and work by the beach.
          </p>
          <h3>About the programme</h3>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai’s Virtual Work Visa offers an easy way to allow anyone who
            does their job remotely to be resident in Dubai for a year. Whether
            you’ve always wanted to live in Dubai, or you’re a digital nomad
            roaming the world, this programme is for you.
            <br />
            <br />
            Benefits include:
          </p>

          <ul>
            <li>One-year visa allowing residency in Dubai</li>
            <li>No need for a UAE sponsor</li>
            <li>Access to utilities and services</li>
            <li>Unrestricted travel from and to Dubai</li>
            <li>
              Ability to sponsor family members, including spouse and children
            </li>
            <li>Access to schooling for dependants</li>
          </ul>
          <p>
            Additional fees will apply when sponsoring dependants, and they may
            be subject to additional requirements.
            <br />
            <br />
            Note the Virtual Work Visa does not give you the right to work for
            companies based in Dubai or the UAE – any work you do must be for a
            non-UAE company.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3 style={{ marginTop: "40px" }}>Virtual Work Visa eligibility</h3>
          <p>
            People of any nationality can apply for a Virtual Work Visa if they:
          </p>

          <ul>
            <li>
              Are employed by a non-UAE company, with a contract of at least one
              year.
            </li>
            <li>
              Can prove they are able to do their work remotely (eg an
              employment contract, or letter from their employer).
            </li>
            <li>
              Earn a salary of at least US$3,500 (AED12,856) per month, or the
              equivalent in other currencies.
            </li>
          </ul>
          <p>
            Applicants of some nationalities may also be asked for further
            documents or information, and may be charged additional fees.
            <br />
            <br />
            Note that applying for the Virtual Work Visa does not guarantee that
            residency will be granted.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Visa costs</h3>
          <p>
            Fees for the Virtual Work Visa depend on where you’re applying from,
            any extra services required and if you need to provide additional
            documents.
            <br />
            <br />
            If you’re applying from outside Dubai, the base fees as of August
            2024 total US$101 (AED372.50), including VAT.
            <br />
            <br />
            Note that applications for the Virtual Work Visa can also be made in
            Dubai, subject to additional fees. The current cost of applying for
            a Virtual Work visa via AMER government service centres in Dubai is
            US$334 (AED1,225.90), including VAT.
            <br />
            <br />
            Fees are subject to change – please check the current costs before
            starting your application.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Home Life</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Urban Company</h4>
            <p>
              Comprehensive cleaning services, including one-time visits and
              subscriptions, plus booking and scheduling.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Justlife</h4>
            <p>
              Offers home cleaning, lab-at-home services, spa treatments,
              disinfection and more, with same-day availability.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>ServiceMarket</h4>
            <p>
              This platform connects users with home services like cleaning,
              repairs, moving, pest control and more.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Washmen</h4>
            <p>
              Specialises in laundry and dry-cleaning services for clothes, home
              linens, shoes and bags, with home pick-up and delivery.
            </p>
          </div>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Food Delivery</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Talabat</h4>
            <p>
              Founded in 2004, Talabat has expanded throughout the region. It
              offers food, groceries and medication delivery.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Deliveroo</h4>
            <p>
              This popular app partners with restaurants and cafes across the
              city for food delivery and takeaway.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Uber Eats</h4>
            <p>
              Part of the global Uber family, this service features major
              restaurants and F&B chains across various cuisines.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Instashop</h4>
            <p>
              Primarily a grocery delivery app, it also partners with pharmacies
              to deliver everyday needs to the customer's door.
            </p>
          </div>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Entertainment and dining</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Dubai Calendar</h4>
            <p>
              Dubai's official events calendar, with an extensive list of
              concerts, festivals, exhibitions and more.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Platinumlist</h4>
            <p>
              A renowned ticketing platform which supplies tickets for
              entertainment events, including concerts, sports and nightlife.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Zomato</h4>
            <p>
              A comprehensive food and dining discovery app, with detailed
              reviews, menus and local restaurant information.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Instashop</h4>
            <p>
              Primarily a grocery delivery app, it also partners with pharmacies
              to deliver everyday needs to the customer's door.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
