import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";

export default function D33Agenda() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Dubai Economic Agenda 'D33'
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>
      <h1>Coming Soon</h1>

      {/* <div
      style={{
        background: "#ececed",
        padding: "30px 0",
        marginBottom: "40px",
        width: "90%",
        float: "inline-end",
        borderBottomLeftRadius: "85px",
      }}
    >
      <div style={{ width: "80%", margin: "auto" }}>
        <p style={{ width: "80%", lineHeight: "28px" }}>
        As one of the world’s most diversified economies, Dubai is the preferred location for multinational corporations (MNCs) and conglomerates seeking a strategic location for multi-continent operations and attracting the best talent. Emphasising productivity and innovation, the city is committed to helping businesses expand and grow in a competitive, innovation-centric environment. 
          <br />
          <br />
          Global corporate giants have leveraged Dubai’s status as a key trade gateway, enabling the seamless flow of goods and services by bridging the east-west time zone gap. They have tapped into the city’s diverse workforce, strong regulatory framework and highly integrated trade and transport network to scale their services to new markets and demographics from this central geographic node. 
          <br/>
          <br/>
          The pro-business environment is supported by growth-focused government strategies and programmes, such as the D33 Agenda, Dubai Global and Operation 300bn. These seek to position the city as a leading hub for business, finance, logistics and technology, as well as new-economy sectors like fintech, blockchain, AI and Web3.
        </p>
      </div>
    </div>

    <div className="row" style={{ width: "80%", margin: "auto" }}>
      <div className="col-6">
        <div
          style={{
            width: "90%",
            background: "#005f66",
            color: "white",
            padding: "40px 35px",
            borderRadius: "15px",
          }}
        >
          <h2>90%</h2>
          <p style={{ marginBottom: "0px" }}>
          of top regional CEOs of global firms are based in the UAE
          </p>
        </div>
      </div>
      <div className="col-6">
        <div
          style={{
            width: "90%",
            background: "#005f66",
            color: "white",
            padding: "40px 35px",
            borderRadius: "15px",
          }}
        >
          <h2>In top 10</h2>
          <p style={{ marginBottom: "0px" }}>
          most competitive economies in the world in 2023
          </p>
        </div>
      </div>
    </div>

    <div
      style={{
        width: "90%",
        margin: "40px auto",
        padding: "50px",
        borderTopLeftRadius: "70px",
        background: "#d0e4eb",
      }}
    >
      <div style={{ width: "90%", lineHeight: "28px",margin:"30px 50px",color:"#505050"}}>
        <h3>Benefits for global companies in Dubai</h3>
        <p>
        The agile and resilient business ecosystem offers numerous unique advantages to multinational corporations in Dubai, including specially designated lower tax options and the opportunity to list on the Dubai Financial Markets (DFM). Dubai is also a top venture capital and FDI destination, offering a range of corporate support mechanisms through a dynamic network of angels, accelerators and the region’s largest concentration of venture capitalists.
          <br />
          <br />
          More than 20 business-first free zones provide advantages tailored to specific industries, from 100% foreign ownership, business set-up, licensing and visa assistance, to regulatory and legal support, access to ports and logistics facilities, and flexibility in working models. 
          <br />
          <br />
          These include Dubai Internet City, which caters to technology and ICT-related businesses; the Dubai Multi Commodities Centre (DMCC), focused on facilitating trade across a range of commodities; and Dubai International Financial Centre (DIFC), with its business-led ecosystem under a common law English framework that offers familiarity and stability to MNCs.
        </p>
      </div>
    </div>
    <div style={{ width: "80%", lineHeight: "28px",margin:"30px auto",color:"#505050"}}>
        <h3>
        Empowering MNCs with world-class logistics and trade </h3>
        <p>
        Dubai’s diversified economy opens up investment and co-investment opportunities across sectors like real estate, hospitality, technology and healthcare, as well as ESG-focused portfolios. The business ecosystem is also optimally suited for expansion and growth, offering extensive networking prospects for industry leaders to nurture deals, build strong partnerships and scale operations.
          <br />
          <br />
          Family offices in Dubai can avail of a wide range of tailored services, including wealth management, tax planning, estate planning and legal counsel. The city also does not impose any inheritance, estate or wealth taxes, enabling wealth preservation to span generations.
        </p>
      </div> */}
    </>
  );
}
