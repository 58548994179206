import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function LazyImg({img,style,className}) {
  return (
    <LazyLoadImage 
    alt={img?.alt ? img.alt : img.src}
    src={img.src}
    height={img.height && img.height}
    style={style}
    className={className}
    width={img.width && img.width} 
     delayMethod="debounce"
     delayTime
    />
   
    
  )
}
