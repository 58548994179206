import React from "react";
import { MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import "./style.css"

export default function Timeline() {
  return (
    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#F0F2F5" }}>
      <div className="main-timeline">
        <div className="timeline left">
          <MDBCard>
            <MDBCardBody className="p-4">
              <h3 style={{ color: '#1e73be' }}>Step-1</h3>
              {/* <FaHourglassStart style={{ fontSize: '40px', color: '#239B56' }} /> */}
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px' }}>Proposed Name Search & Application</p>
              <p>
              Request free company name search. We can check the eligibility of the name, and make suggestions if necessary.
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard>
            <MDBCardBody className="p-4">
              <h3 style={{ color: '#1e73be' }}>Step-2</h3>
              {/* <FaHourglassStart style={{ fontSize: '40px', color: '#239B56' }} /> */}
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px' }}> Prepare Company Details</p>
              <p>
                <ul>
                  <li>Approval proposed company name.</li>
                  <li>To draft the memorandum of association (MoA) and articles of association (AoA).</li>
                  <li>RJSC Register or login and  online fillup in the company names and director/shareholder(s) details.</li>
                  <li>Fill in the company address with share capital, other details (if any).</li>
                  <li>If, the foreign equity company in Bangladesh. Open a bank account for the proposed company and transfer equity capital or transfer inward remittance from abroad and request for encasement certificate for foreign equity company registration to the bank.</li>
                </ul>
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline left">
          <MDBCard>
            <MDBCardBody className="p-4">
              <h3 style={{ color: '#1e73be' }}>Step-3</h3>
              {/* <FaHourglassStart style={{ fontSize: '40px', color: '#239B56' }} /> */}
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px' }}>Payment for your company</p>
              <p>
                <ul>
                  <li>Register or login into the RJSC portal for check and final submission.</li>
                  <li>Before the final submission, attach the zip file and encasement certificate for foreign company registration.</li>
                  <li>To generate submission ID and acknowledgement slip.</li>
                  <li>To communicate with concerned authority.</li>
                </ul>
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="timeline right">
          <MDBCard>
          <MDBCardBody className="p-4">
              <h3 style={{ color: '#1e73be' }}>Step-4</h3>
              {/* <FaHourglassStart style={{ fontSize: '40px', color: '#239B56' }} /> */}
              <p style={{ color: '#1e73be', fontWeight: '700', fontSize: '20px' }}> Send the company kit to your address</p>
              <p>
                <ul>
                  <li>You will receive soft copies of necessary documents including: Certificate of Incorporation, Memorandum and Articles of Association, Form XII.
                  </li>
                  <li>
                  You can bring the documents in the company kit to open a corporate bank account or we can help you with our long experience of Banking support service.
                  </li>
                  <li>
                  Before the bank account opening you required the company tax certificate, trade license and rental deed, company seal stamp and board resolution on the official pad. Then your new company in a jurisdiction is ready to do business.
                  </li>
                </ul>
              </p>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </MDBContainer>
  )
}
