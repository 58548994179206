import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";

export default function RetailAndEcommarce() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Retail and e-commerce
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            World-renowned shopping destination
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai is a global and regional destination for brick-and-mortar
            retail and e-commerce. In 2023, more than 17 million tourists
            visited the city to enjoy its upscale retail experiences, making it
            a haven for luxury shopping.
            <br />
            <br />
            At the core of Dubai's retail sector is a steadfast commitment to
            luxury, propelling regional growth. With the government's
            pro-business policies and wholesome consumer confidence, the city
            hosts a diverse array of global and regional giants. Shoppers revel
            in a rich tapestry of choices, from esteemed luxury labels like
            Hermes and Richard Mille to beloved consumer brands like Nike and
            Adidas, facilitated seamlessly by platforms like Amazon, Noon and
            Namshi.
          </p>
          <h5>Driving economic growth and progress</h5>
          <p>
            Dubai's growing retail industry plays a key role in driving its
            thriving economy; it is one of the main sectors contributing to the
            city's GDP placing it as a destination of choice for business
            expansion into the $1.5 trillion MENA consumer spending market.
            <br />
            <br />
            In recent years, a large influx of HNWIs and private wealth have
            fueled significant demand for retail and e-commerce services.
            Dubai's retail industry has also created more than 250,000 jobs,
            driven by decades of retail capital expenditure and rapid
            digitalisation, and is expected to grow by more than 6% through
            2029.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/festivals/national-day/emirati-culture/v2/uae-national-day-desert-camp-1560x1000.jpg?rev=4efa64228e5540f0b2d03421732bfe54&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Top tourism destination</h4>
            <p>
              More than 17 million international tourists visited Dubai in 2023
              - the highest number ever recorded.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/personalization-headers/india-shopping-v2.jpg?rev=42472e9f082848ce85c52fc2e03be3e9&cx=0.47&cy=0.98&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Tax-free shopping</h4>
            <p>
              Tourists visiting Dubai can take advantage of tax-free shopping
              and VAT refunds for purchases made in the city.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/g/guide-to-the-best-luxury-gift-shops-in-dubai/fallback-image/luxury-gifts-dtcm-nov-2021.jpg?rev=10e57c7d2a1d44668d627f61ea317149&cx=0.61&cy=0.38&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Luxury shopping oasis</h4>
            <p>
              Dubai is home to more than 200 renowned top luxury brands
              including Hermes, Cartier and Rolex.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/dotsite/migrated/images/2015/06/sheyaab-hr-6664.jpg?rev=fdf9d7f7178b489498771576d51a18f1&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Flagship shopping events</h4>
            <p>
              Dubai's Retail Calendar has over 18 shopping festivals annually,
              including the Dubai Shopping Festival.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Booming e-commerce sector</h3>
          <p>
            Dubai’s e-commerce landscape has experienced exponential growth in
            recent years. Large companies and SMEs have benefited from its
            business-friendly ecosystem, which offers easy access to workplaces,
            order fulfilment and diverse delivery solutions.
            <br />
            <br />
            The sector is anticipated to witness sustained growth momentum over
            the next three years, with 12% growth for e-commerce and social
            commerce. This is expected to boost the diversity and number of
            players in the ecosystem and attract tech companies and providers
            that can pioneer innovative solutions for the e-commerce market.
          </p>
          <h4 style={{ marginTop: "20px" }}>
            Accelerating growth with strategic initiatives
          </h4>
          <p>
            The government is focused on positioning Dubai as a global epicentre
            for e-commerce, making it a key contributor to the UAE’s e-commerce
            sector, which is the fastest growing worldwide. Initiatives and
            projects like Dubai’s e-commerce strategy and the launch of the DMCC
            e-commerce ecosystem aim to catalyse e-commerce growth across the
            MEASA region and solidify Dubai’s position as the region’s primary
            global logistics hub, drawing increased foreign direct investment.
            <br />
            <br />
            These developments are also supported by the introduction of a new
            e-commerce law that outlines more explicit regulations on the
            interaction between e-commerce platforms and customers. This will
            enable a more seamless operating ecosystem for e-commerce
            activities, boost the overall economy and improve competitiveness.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Robust consumer confidence</h3>
        <p>
          Dubai's retail and e-commerce ecosystem continues to prove its
          resilience with strong consumer confidence in the sector. According to
          the World Travel and Tourism Council, tourists to the UAE are the
          world's biggest retail spenders, spending nearly three times the
          global average per trip.
          <br />
          <br />
          The city also boasts the region's largest duty-free operator, Dubai
          Duty Free, which brought in nearly US$2 billion (AED7.8 billion) in
          sales in 2023, Dubai Duty Free's 40th anniversary.
        </p>
        <h4 style={{ marginTop: "20px" }}>Unparalleled luxury shopping hub</h4>
        <p>
          The GCC's retail landscape attracts billions in revenue each year.
          Amid this, Dubai stands out as the leading destination for luxury
          retail shopping. Recent research shows that 70% of consumers in the
          Middle East have increased their spending on luxury goods, compared to
          just 53% across more mature markets like Europe and the US.
          <br />
          <br />
          Dubai's innovation-focused business landscape and recognised status as
          a retail hub have made the city a destination for regional and global
          brands looking to expand into the regional consumer spending market,
          valued at US$1.5 trillion (AED5.5 trillion).
        </p>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$70.5 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              expected value of UAE retail sales by 2025.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$7.6 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              expected value of Dubai’s e-commerce sales by 2025.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Tech innovation in retail</h4>
        <p>
          Dubai’s retail scene is undergoing a tech revolution, supported by the
          government’s focus on adopting the latest technologies to remain
          competitive and cater to evolving consumer demands. Biometrics has
          been introduced as a payment option, with customers being able to pay
          using facial recognition software instead of traditional payment
          methods. Additionally, the emergence of “phygital stores” provides a
          unique shopping experience, combining online and traditional in-person
          shopping.
          <br />
          <br />
          Strategic initiatives such as the Dubai Future Foundation’s ‘Future of
          Luxury Retail’, created in partnership with Richemont, aim to propel
          start-up-based tech innovation in the luxury retail market. The focus
          is to provide a platform for entrepreneurs to ideate new and effective
          modes and solutions to enable further growth in the sector.
        </p>
        <h4>The Global Retail Summit: shaping the future of retail</h4>
        <p>
          In addition to Dubai’s well-known shopping festivals, the Dubai
          Chambers also organises the annual Global Retail Summit. As the
          premier platform that explores modern trends shaping the retail
          industry, the summit brings together industry leaders, experts,
          policy-makers and officials.
          <br />
          <br />
          The summit aims to promote sustainability, enhance customer
          engagement, integrate new technologies, and improve digital
          infrastructure. The 2024 edition is expected to feature over 800
          attendees from 64 countries and present an array of insightful round
          tables, panel discussions and exclusive networking opportunities for
          retailers and e-commerce companies.
        </p>
      </div>
    </>
  );
}
