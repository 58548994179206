import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
import NewFaq from "./FaqTowColumn";

export default function Smes() {
  const MainlandIncubatorsFaq = [
    {
      question: "Bedayat",
      ans: `Sustainability in the built environment
Corporate sustainability`,
    },
    {
      question: "Hamdan Innovation Incubator",
      ans: `Art and Design,
Corporate and Support Services,
Education,
Entertainment,
Manufacturing,
Media and Production,
Research and Development,
Technology,
Telecoms`,
    },
    {
      question: "The Co-Dubai",
      ans: `Retail,
Technology`,
    },
    {
      question: "Intelak",
      ans: `Aviation,
Travel,
Tourism`,
    },
    {
      question: "Dubai Future Accelerators",
      ans: `Multiple sectors
`,
    },
    {
      question: "Turn8",
      ans: `Fintech,
Edutech,
Transport and logistics,
SaaS platforms,
Mobile cloud,
AI ,
Smart cities,
E-Commerce,
Fintech,
Healthtech,
Martech`,
    },
  ];

  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Support for start-ups and SMEs
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h4>
            Getting the right help is crucial for a new company – and Dubai has
            plenty of support options.
          </h4>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Across the city you'll find plenty of support organisations and
            investor groups dedicated to helping new and smaller companies to
            succeed and grow their operations. Here you can find a selection of
            bodies such as public and private investment bodies, incubators,
            accelerators, training programmes and more.
          </p>
          <h3 style={{ marginTop: "30px" }}>
            Funding options for start-ups and SMEs
          </h3>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai’s thriving venture capital and investment sector makes it a
            great place to seek your next investor. Below are some of the most
            prominent funds in the emirate, including those open to all and ones
            dedicated to supporting Emirati-owned businesses.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h3 style={{ textAlign: "left" }}>Funds open to all</h3>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/funding-incubators-and-accelerators/dubai-future-district.jpg?rev=453e585edddb4702b4bc58c0d1058075&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Future District Fund</h4>
            <p>
              Launched in 2020, the Dubai Future District Fund is a US$272
              million seed-to-growth fund aimed at supporting and growing the
              start-ups of the future.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/funding-incubators-and-accelerators/dubai-future-accelerators.jpg?rev=55c4d51b81354aa0a5b219fd70e9d551&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Dubai Future Accelerators</h4>
            <p>
              DFA is designed to facilitate collaboration between emerging
              enterprises and public and private sector organisations, aimed at
              solving specific challenges.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/funding-incubators-and-accelerators/dubai-next-v2.jpg?rev=b44c1e35403a46689731a3ffe7678ab4&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Dubai Next</h4>
            <p>
              Dubai Next is a crowdfunding platform that helps start-ups and
              SMEs raise funds. To encourage trust, the platform collects the
              funds in an escrow account and guarantees the project.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/business-meeting-3-dtcm.jpg?rev=34eb8bfd17b6495ebef9463e7f98f77e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Dubai Entrepreneurship Academy</h4>
            <p>
              The DEA offers a range of programmes and opportunities to help
              start-up and SME talent develop skills and promote the sharing of
              best practice.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Start-up incubators and accelerators</h3>
          <p>
            Dubai offers a great support network for young start-ups, with an
            active investor ecosystem of venture capital funds, business
            incubators and accelerators.
          </p>
          <ul>
            <li>
              Incubators: programmes designed to help start-ups develop and grow
              by providing workspaces, mentorship, education/training, and
              access to investors.
            </li>
            <li>
              Accelerators: programmes that support developing start-ups to
              become self-sufficient and speed their growth, by providing
              logistical and technical resources, shared office space and a
              network of experienced peers.
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <NewFaq
          title={"Mainland incubators"}
          colNo={2}
          faqItems={MainlandIncubatorsFaq}
        />
      </div>
      <div className="container my-5">
        <h2 className="mb-4">More from Dubai for business</h2>
        <p className="mb-5">
          Browse information on Dubai's business landscape and how to start or
          move your business to Dubai.
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Mainland companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Mainland companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  If you're looking to trade within the UAE, or prefer not to be
                  within a free zone, establishing a mainland company is the
                  path.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Free zone companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Free zone companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Learn more about Dubai's free zones and the process of setting
                  up a company within them.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, Regulations, legal compliance and taxation
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Support for start-ups and SMEs
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Getting the right help is crucial for a new company – and
                  Dubai has plenty of support options.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, administration and logistics
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  All the information you need about visas, bank accounts,
                  employment, and business premises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
