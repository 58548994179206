import React from 'react'

function Faq(props) {
    const { items } = props;
    return (
        <div className="accordion" id="accordionPanelsStayOpenExample">
            {items?.map((item, index) => (
                <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`panelsStayOpen-heading${index}`}>
                        <button className={`accordion-button ${index === 0 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${index}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`panelsStayOpen-collapse${index}`}>
                            {item.title}
                        </button>
                    </h2>
                    <div id={`panelsStayOpen-collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`panelsStayOpen-heading${index}`}>
                        <div className="accordion-body">
                            {item.content}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Faq
