import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function DesignMedia() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Design media and entertainment
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            Leading creative growth and success
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai is a trailblazer in the region’s booming creative industry.
            Over the years, the government has invested in building a supportive
            framework that covers free zones, dedicated clusters for media
            production, design, fashion and more, as well as strong support for
            creative start-ups and companies.
            <br />
            <br />
            The city has also launched the Dubai Creative Economy Strategy,
            developing a conducive legislative and investment environment to
            increase its attractiveness to talents, investors and entrepreneurs.
            Its key goal is to make Dubai a preferred hub for global talents and
            the world’s creative economy capital by 2026.
          </p>
          <h3>Nurturing creative ambition</h3>
          <p>
            In 2022, the Dubai Culture and Arts Authority (Dubai Culture) signed
            an MOU with Dubai CommerCity, MEASA’s first free zone dedicated
            exclusively to digital commerce, to boost Dubai’s creative economy
            and position it as an attractive destination for global creatives.
            Under the agreement, both entities will offer services to help set
            up new businesses in specific creative economy sectors and support
            their growth at Dubai CommerCity. Sectors covered include music,
            fine arts, photography, film and video production, video game
            development and fashion and design.
            <br />
            <br />
            Dubai’s Al Quoz Creative Zone is also vital in driving innovation
            and boosting investment in creative businesses. It is a dynamic hub
            for designers and artists, enabling them to live, work and innovate
            in Dubai. As a one-stop shop, it offers multipurpose arts spaces,
            creative freelance permits and licensing and visa fees exemptions
            for innovative startups and SMEs and a smart platform for creatives.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 className="resource-section-title">Dynamic innovation hub</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>
            <h4>Strong infrastructure</h4>
            <p>
              A modern landscape with world-class media production, TV, cinema
              and art facilities.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>
            <h4>Creative festival host</h4>
            <p>
              Including the Dubai Art Festival, Dubai International Jazz
              Festival and the Lynx Awards.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <h4>National programmes</h4>
            <p>
              To consistently support and encourage the growth of Dubai’s
              cultural and creative industries.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <h4>Long-term residency</h4>
            <p>
              Available for leading creatives, musicians and artists from all
              over the world.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "10px",
        }}
      ></div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Media and design ecosystem</h3>
          <p>
            TECOM Group's in5 Innovation Centre is a key enabler for design and
            media companies in Dubai. Since its inception in 2013, In5 has
            supported over 750 startups, playing a critical role in facilitating
            innovation and entrepreneurship in the city and offering access to
            global investors. The Dubai startup ecosystem has produced globally
            renowned companies, such as Anghami, Fenix Games and Tabby.
            <br />
            <br />
            It offers startups a dedicated array of facilities, including
            co-working spaces, prototyping labs, 3D printers, wood/metal-cutting
            machinery, fashion labs, green rooms, screening rooms, recording
            studios, editing suites and production equipment.
          </p>
          <h4 style={{ marginTop: "40px" }}>
            Vibrant entertainment ecosystems
          </h4>
          <p>
            Dubai Production City (DPC) is a world-class network focused on
            media production, printing, publishing and packaging. DPC is
            renowned for its cutting-edge infrastructure, business-friendly
            environment and retail and residential developments.
            <br />
            <br />
            Dubai Studio City is at the core of the regional film and television
            production ecosystem. It features premier infrastructure and
            specialised facilities, including the largest sound stages in the
            region. Major cinematic projects such as Star Trek: Beyond and
            Mission Impossible: Ghost Protocol have benefited from the
            facilities offered by Dubai Studio City.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>15,000 companies</h3>
            <p style={{ marginBottom: "0px" }}>
              are expected to operate in Dubai’s creative sector by 2025 .
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>140,000 creatives</h3>
            <p style={{ marginBottom: "0px" }}>
              are expected to be based out of Dubai by 2025.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Where creativity meets industry</h4>
        <p>
          Dubai Design District (D3) is where creatives can meet, exchange ideas
          and insights, exhibit their work and plan the sector’s future
          trajectory. D3’s connectivity and strategic location drive a calendar
          of high-profile events all year round, including Dubai Design Week,
          Arab Fashion Week and Sole DXB.
          <br />
          <br />
          In 2023, Dubai introduced the 'Make Works UAE' portal- a collaborative
          platform between the creative sector and local manufacturers,
          fabricators and suppliers, created by Dubai Culture in partnership
          with Tashkeel. This initiative underscores the significance of the
          industrial sector in bolstering cultural and creative industries,
          reflecting Dubai Culture's dedication to fostering an environment
          conducive to talents, entrepreneurs and creative SMEs. Aligned with
          the goal of empowering local talent, sparking innovation and
          establishing the city as a vibrant hub for artistic and cultural
          pursuits, the portal epitomises Dubai's commitment to nurturing its
          creative ecosystem.
        </p>
        <h4>Government Initiatives</h4>
        <p>
          Dubai Culture has pioneered a transformative programme that aims to
          grant cultural residency visas to 1,000 creators and artists from all
          over the world to live and work in Dubai. This first-of-its-kind
          initiative makes Dubai a global hub for culture, an incubator for
          innovation and a centre for talent.
          <br />
          <br />
          The move boosts the number of artists, performers and creatives in the
          city and spurs increased involvement in Dubai’s knowledge-based
          economy. It also enhances the role of the creative industry in the
          city’s development, aligning seamlessly with the UAE’s goal of
          building a harmonious and sustainable society that attracts
          exceptional regional and international talent.
        </p>
        <h4>DMCC Gaming Centre</h4>
        <p>
          It offers a fully digital set-up process, state-of-the-art
          infrastructure and wide-ranging legislative and regulatory support.
          Members enjoy access to regular events and esports tournaments and
          benefit from industry support from within the DMCC and its partner
          YaLLa Esports.
        </p>
      </div>
    </>
  );
}
