import React, { useState } from 'react';
import { FaBalanceScale, FaMoneyBillAlt, FaBuilding, FaTrademark, FaBook, FaClipboardList, FaIdCard, FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import passportImg from "../assets/images/passport.png"

const iconSize = '50px';

const iconMapping = {
  'Value Added TAX (VAT)': <FaBalanceScale style={{ fontSize: iconSize }} />,
  'TAX': <FaMoneyBillAlt style={{ fontSize: iconSize }} />,
  'Company Formation': <FaBuilding style={{ fontSize: iconSize }} />,
  'Work Permit VISA': <img src={passportImg} style={{ fontSize: iconSize }} width={75} alt='passportImg' />,
  'Trademark': <FaTrademark style={{ fontSize: iconSize }} />,
  'Copyright': <FaBook style={{ fontSize: iconSize }} />,
  'Audit': <FaClipboardList style={{ fontSize: iconSize }} />,
  'IRC & ERC': <FaIdCard style={{ fontSize: iconSize }} />,
  'Foreign Entity': <FaGlobe style={{ fontSize: iconSize }} />,
};

const cardInfo = {
  'Value Added TAX (VAT)': {
    description: 'We provide expert consultancy regarding Value Added Tax (VAT).',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/vat-online-service');
    },
  },
  'TAX': {
    description: 'Pay only if you are eligible and remove all difficulties!',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/income-tax');
    },
  },
  'Company Formation': {
    description: 'We are here to take your hassle and make your dream true!',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/company-formation-in-bangladesh');
    },
  },
  'Work Permit VISA': {
    description: 'Get Standards Work Permit VISA Service',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/work-permit-visa');
    },
  },
  'Trademark': {
    description: 'We help to protect your assets.',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/trademark-registration-in-bangladesh');
    },
  },
  'Copyright': {
    description: 'We help you to reserve your rights.',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/copyright-registration');
    },
  },
  'Audit': {
    description: 'We help you to ensure integrity.',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/account-service');
    },
  },
  'IRC & ERC': {
    description: 'Reach to the world...',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/import-license-irc');
    },
  },
  'Foreign Entity': {
    description: 'We help your business to grow.',
    buttonLabel: 'Show More',
    onButtonClick: (navigate) => {
      navigate('/foreign-company-registration-in-bangladesh');
    },
  },
};

const HomeCard = ({ content, color, onHover, isHovered, navigate }) => {
  const info = cardInfo[content];

  return (
    <div
      className="card text-center"
      style={{
        backgroundColor: isHovered ? '#1e73be' : color,
        color: isHovered ? 'black' : 'white',
        margin: '5px',
        padding: '15px',
        height: '200px',
        // width: '330px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: isHovered ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
        animation: isHovered ? 'pulse 0.5s ease infinite alternate' : 'none',
        transition: 'background-color 0.7s, box-shadow 0.7s, color 0.7s',
      }}
      onMouseEnter={() => onHover(content)}
      onMouseLeave={() => onHover(null)}
    >
      {iconMapping[content]}
      <div style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Helvetica' }}>
        {isHovered ? content : content}
      </div>
      {isHovered && (
        <div style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '14px', color: 'white', fontFamily: 'Helvetica' }}>
          <p style={{ color: 'white' }}>{info.description}</p>
          <button
            style={{
              padding: '10px 15px',
              backgroundColor: '#65c5c4',
              color: 'white',
              borderRadius: '5px',
              cursor: 'pointer',
              border: 'none',
              outline: 'none',
            }}
            onClick={() => info.onButtonClick(navigate)}
          >
            {info.buttonLabel}
          </button>
        </div>
      )}
    </div>
  );
};

const HomeGrid = () => {
  const [hoveredContent, setHoveredContent] = useState(null);
  const navigate = useNavigate();

  const handleHover = (content) => {
    setHoveredContent(content);
  };

  const cardsContent = [
    'Foreign Entity', 'Company Formation', 'TAX',
    'Value Added TAX (VAT)', 'IRC & ERC', 'Audit',
    'Trademark', 'Copyright', 'Work Permit VISA',
  ];

  const colors = [
    '#f16820', '#f77181', '#65c5c4',
    '#33689a', '#c06c84', '#fab95a',
    '#cf142c', '#6c5b7b', '#408ab4',
  ];

  return (
    <div className="container-xxl py-2 d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div  className="grid-container">
        {cardsContent?.map((content, index) => (
          <HomeCard
            key={index}
            content={content}
            color={colors[index]}
            onHover={handleHover}
            isHovered={hoveredContent === content}
            navigate={navigate}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeGrid;
