import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'

function RefundAndReturnPlolicy() {
  return (
    <>
             
      <Container style={{ marginTop: '100px', padding: '10px' }}>
        <p style={{ color: 'black', textAlign: 'justify', padding: '20px 10px' }}>
          We hope that you’re happy with your purchase every time! If there is any manufacturing fault or the
          item is “Dead on Arrival” or any colour or size mismatches, we’ll be happy to “Exchange” or “Full
          Refund” or the same product provided that it is in full as it is a condition as delivered. We do
          Also offer Quick Replacement warranty for many products. Read more about Replacement Policy here
          for better understanding and your comfort.<br /><br />

          We (jkassociates.com.bd) have a 7 days easy return policy for most of the items, however, specific
          return periods may apply for certain types of items while certain items are not returnable unless
          there is a problem with their quality.<br /><br />

          If there is an issue with your order, you are requested to inform us immediately and not within 24
          hours of receiving the product. Returns should be made within 7 days of receiving the items in
          original, undamaged and everything included and intact packaging. In most cases, original and
          undamaged packaging refer to the manufacturer’s packaging that the product was delivered to you in.
          An item being returned should also be returned with any accessories, manuals, and items that may
          have been bundled with the item.<br /><br />

          If the product being returned is not in a fully re-saleable condition, or the packaging is damaged,
          or accessories, manuals or bundled items may be missing, we reserve the right to refuse a refund on
          the item or deduct up to 80% of the original selling price from the refund amount. Refund will be
          added to your jkassociates.com.bd account as Store Credit/Equivalent Plus Points or in cash/bKash.
          Other cases if everything is as it is and fulfills all our refund policy, we are good to offer you
          the full refund without any question.<br /><br />

          In the case of size, color or if you don’t like the item or any other natural issue, the product can
          be exchanged one time for FREE or when you pay the return delivery charge or bring the product to
          our office.<br /><br />

          If it’s required to change more than one time then additional charge 20% of the product value could
          be deducted. So, we request you to choose the right product and triple check the product details or
          call our customer support if needed before replacing it the 2nd time.<br /><br />

          <b>Note:</b> If there is any problem from our side we will offer FREE returns or else we will charge
          you for the return shipping and rest amount will be refunded as per the above policy.
        </p>
      </Container>
             
    </>
  )
}

export default RefundAndReturnPlolicy