import React from "react";
import BlogComponent from "../../components/BlogComponent";
import Meta from "../../components/Meta";
import { blogPosts } from "../../data/blogPosts ";

function BlogCard() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="App">
        <h2 style={{ textAlign: "center", marginTop: "150px" }}>
          Latest Blog Posts
        </h2>
        <div className="blog-grid container-xxl py-5">
          {blogPosts.map((post, index) => (
            <BlogComponent
              key={index}
              image={post.image}
              title={post.title}
              summary={post.summary}
              author={post.author}
              date={post.date}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default BlogCard;
