import React from "react";
import { Link } from "react-router-dom";
import copyrightImg2 from "../assets/images/Copyright-Registration-Process.png";
// import environmentImg from "../../assets/images/Environmental.png";
import { Table } from "react-bootstrap";
import Meta from "./Meta";

export default function BuyingHouseLicense() {
  return (
    <>
      <Meta
        title="Environment Licenses in Bangladesh | Environmental Clearance Procedure"
        description="The Procedure for issuing Environmental. Clearance Certificate has been stated in the Rule-7 of the Environmental Conservation Rules"
        // image={environment_og_Img}
        keywords="Environmental Clearance, environment clearance certificate in Bangladesh, Environmental Certificate"
      />
      <div className="container-fluid hero-header bg-light py-5 mb-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h1
                className="display-4 mb-3 animated slideInDown"
                style={{ fontSize: "40px" }}
              >
                Buying House Licensing in Bangladesh
              </h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Others</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Buying House Licensing in Bangladesh
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6 animated fadeIn">
              <img
                className="img-fluid animated pulse infinite"
                style={{ animationDuration: "3s" }}
                src={"/img/Buying House Licensing in Bangladesh.jpg"}
                alt="Environmental"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>What is Textile (DoT) License / Buying House Licensing?</b>
          </p>
          <p>
            In Bangladesh, a Textile Directorate (DoT) License is essential for
            businesses involved in the textile sector, including buying houses
            that act as intermediaries between international buyers and local
            manufacturers. This license is issued by the Directorate of Textiles
            and serves as an official permit to conduct operations related to
            textile production, trading, and sourcing.
            <br />
            <br />
            A buying house typically manages the export of ready-made garments
            (RMG) and other textile products by liaising between foreign clients
            and local garment manufacturers. Having a DoT License ensures that
            buying houses meet regulatory standards, maintain ethical business
            practices, and operate within the legal framework set by the
            Bangladesh government.
            <br />
            <br />
            Copyright registration has not been made compulsory in the Copyright
            Act, 2000. It is not also compulsory in other countries. But the
            copyright registration is a pre-requisite to avoid any possible
            conflict over the ownership of intellectual property. Being a member
            country of WIPO, World Trade Organization (WTO) and the signatory at
            Berne Convention, Universal Copyright Registration Convention, and
            Bangladesh is bound to abide by all conditions of these conventions
            related to copyright and the Trade-Related Aspects of Intellectual
            Property Rights (TRIPS) Agreement. The Bangladesh Copyright Act 2000
            was enacted incorporating the nationally and globally important
            provisions of all major international copyright rules. It was
            further amended in 2005 for updating. Later, the copyright Rules
            2006 (SRO No-219) was formulated under section 103 of the Copyright
            Act to strengthen copyright management through proper implementation
            of the law.
          </p>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6  ">
              <img
                className="img-fluid"
                src={copyrightImg2}
                alt="Copyright-Registration-Process"
              />
            </div>
            <div className="col-lg-6  ">
              <div className="h-100">
                <p className="color-text fs-5 mb-4">
                  Textile (DoT) License / Buying House Licensing Process in
                  Bangladesh
                </p>
                <p>
                  <b>1. Business Registration:</b> The first step is registering
                  the business with the Registrar of Joint Stock Companies and
                  Firms (RJSC), obtaining a trade license, and opening a bank
                  account.
                  <br />
                  <br />
                  <b>2. Application Submission:</b> TAfter business
                  registration, the applicant needs to fill out the required
                  forms, provide necessary documents, and submit the application
                  to the Directorate of Textiles. Documents required may
                  include:
                  <ul>
                    <li>Business incorporation certificate</li>
                    <li>Trade license</li>
                    <li>Bank solvency certificate</li>
                    <li>Tax identification number (TIN)</li>
                    <li>
                      Lease or ownership documents of the business premises
                    </li>
                  </ul>
                  <br />
                  <br />
                  <b>3.Inspection & Verification:</b>The Directorate of Textiles
                  will conduct a physical inspection of the business premises to
                  ensure compliance with legal and environmental standards.
                  <br />
                  <br />
                  <b>4.Approval & License Issuance:</b>Once the inspection is
                  successful, the Directorate will issue the DoT License,
                  allowing the business to legally operate as a textile entity
                  or buying house in Bangladesh.
                  <br />
                  <br />
                  <b>5.License Renewal:</b>The license is typically valid for a
                  specified period and must be renewed periodically by
                  fulfilling the renewal requirements set by the Directorate of
                  Textiles.
                </p>
                <p className="color-text fs-5 mb-4">
                  Who Can Apply for This License?
                </p>
                <p>
                  Any individual or business entity that intends to work within
                  the textile industry in Bangladesh, particularly buying houses
                  and garment manufacturers, can apply for the Textile (DoT)
                  License. This includes:
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Entrepreneurs who want to start a textile trading or
                      manufacturing business
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Buying houses working as intermediaries between
                      international buyers and local textile producers
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                    <span>
                      Exporters of ready-made garments (RMG) and other textile
                      products
                    </span>
                  </div>
                  To be eligible, the applicant must have a registered business
                  entity in Bangladesh and comply with all regulatory
                  requirements under the Directorate of Textiles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-0">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>Required Documents and information</b>
          </p>
          <ul>
            <li>Registration letter to Director General of DoT</li>
            <li>Duly filled in the registration form</li>
            <li>Trade license (attested copy)</li>
            <li>Income tax certificate (attested copy)</li>
            <li>Certificate of incorporation, MoA, and AoA</li>
            <li>Estimated yearly turnover</li>
            <li>
              Membership certificate of relevant commercial association (i.e.
              BGBA or BGME) (attested copy)
            </li>
            <li>Bank draft pay order for the registration fee</li>
            <li>Solvency certificate from bank</li>
          </ul>
        </div>
      </div>
      <div className="container-xxl py-0">
        <div className="container">
          <p className="color-text fs-5 mb-4">
            <b>Schedule-I (Service Fees for Textile (DoT) Certificate )</b>
          </p>
          <p>
            <b>
              {" "}
              (a) In case of application for proposed textile certificate of
              registration or existing certificate of registration
            </b>
          </p>
        </div>
      </div>
      <div className="container-xxl py-0">
        <div className="container">
          <Table striped bordered hover variant="light blue">
            <thead>
              <tr>
                <th>Amount of investment</th>
                <th>Amount of fee (BDT)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>From One Taka to Ten Corers</td>
                <td>5,000</td>
              </tr>
              <tr>
                <td>Ten Corers to Twenty-Five Corers</td>
                <td>10,000</td>
              </tr>
              <tr>
                <td>Twenty-Five Corers to Fifty Corers</td>
                <td>25,000</td>
              </tr>
              <tr>
                <td>Fifty Corers to One Hundred</td>
                <td>50,000</td>
              </tr>
              <tr>
                <td>More than One Hundred Core </td>
                <td>1,00,000</td>
              </tr>
            </tbody>
          </Table>

          <p>
            <b>(b) In respect of other service (Textile (DoT) Certificate)</b>
          </p>
          <Table striped bordered hover variant="light blue">
            <thead>
              <tr>
                <th>Details</th>
                <th>Amount of fee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}>Registration Amendment Fee</td>
                <td>
                  Fees shall be fixed as per (a) above in case of amendment due
                  to increase in investment
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <td>1,000 (in other cases except increase in investment)</td>
                </td>
              </tr>
              <tr>
                <td>
                  Conversion Fee from & 'Proposed Registration' to 'Existing
                  Registration'
                </td>
                <td>1,000</td>
              </tr>
              <tr>
                <td>Buying House Registration Fee</td>
                <td>20,000</td>
              </tr>
              <tr>
                <td>Buying House Registration Renewal Fee</td>
                <td>5,000</td>
              </tr>
              <tr>
                <td>Duplicate Registration Certificate Issue Fee</td>
                <td>2,000</td>
              </tr>
              <tr>
                <td>Proposed Registration Extension Fee</td>
                <td>1,000</td>
              </tr>
              <tr>
                <td>Renewal Fee for Existing Registration</td>
                <td>1,000</td>
              </tr>
              <tr>
                <td>Late Fee (for every year)</td>
                <td>1,000</td>
              </tr>
              <tr>
                <td>Fees for all other service</td>
                <td>1,000</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
