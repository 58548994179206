import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";

export default function VisaEntry() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Dubai visas for visits, work and living
          </h1>
          <p>
            From remote work to long-term investors, Dubai offers a wide range
            of visas for everyone.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">What visa should I get?</h2>
          <p style={{ lineHeight: "28px" }}>
            Depending on why you’re coming to Dubai, there are a number of visa
            options available – and you’ll find details of the main types on
            this page.
            <br />
            If you’re visiting the city looking for business opportunities, and
            won’t be working in the UAE, then a visit visa is all you need.
            Nationals of many countries can receive a visa on arrival, while
            those requiring a pre-arranged visa can easily apply online.
            <br />
            Once you’ve decided to establish yourself in Dubai, you will need to
            apply for a residency visa. For workers employed by a Dubai-based
            company this will be handled by your employer – but if you’re
            setting up your own business, working as a freelancer, or want to
            retire in Dubai, you can arrange your own residency visa, which is
            usually valid for five or 10 years.
            <br />
            The main residency visas include:
            <ul>
              <li>
                {" "}
                <b>Golden Visa</b>: for investors, entrepreneurs and talented
                professionals
              </li>
              <li>
                <b>Green Visa</b>: for skilled individuals, freelancers or the
                self-employed
              </li>
              <li>
                <b>Virtual work visa </b>: one-year visa for remote-work
                employees
              </li>
              <li>
                <b>Retirement visa</b>: for those aged 55+ wanting to retire in
                Dubai
              </li>
              <li>
                <b>Blue Visa</b>: a newly launched visa for those involved in
                environmental work
              </li>
            </ul>
          </p>
        </div>
      </div>

      <div className="resource-container">
        <h2 className="resource-section-title">
          The main residency visas include:
        </h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <h4>Golden Visa</h4>
            <p>
              10-year residency visa for investors, entrepreneurs, and talented
              professionals
            </p>
          </div>
          <div className="resource-card">
            <h4>Green Visa</h4>
            <p>
              5-year residency visa for skilled individuals, freelancers or the
              self-employed
            </p>
          </div>
          <div className="resource-card">
            <h4>Virtual work visa</h4>
            <p>One-year visa for remote-work employees</p>
          </div>
          <div className="resource-card">
            <h4>Retirement visa</h4>
            <p>For those aged 55+ wanting to retire in Dubai</p>
          </div>
          <div className="resource-card">
            <h4>Blue Visa</h4>
            <p>
              A newly launched visa for those involved in environmental work
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Golden Visa</h3>
          <p>
            The Golden Visa is a 10-year residency visa for property owners,
            those investing significant capital in the UAE, or entrepreneurs –
            as well as individuals with outstanding talent, or who have made
            exceptional humanitarian contributions.
            <br />
            <br />
            Other people, including specialist workers in a range of fields and
            elite recent graduates can also apply for a Golden Visa: find more
            information on the website of Dubai’s General Directorate of
            Residency and Foreigners Affairs.
          </p>

          <h4 style={{ marginTop: "30px" }}>Eligibility</h4>
          <hr />

          <ul>
            <li>
              Investors
              <ul>
                <li>
                  Owner of one or more real estate properties worth no less than
                  US$544,484 (AED2 million) in total
                </li>
                <li>
                  Investor in a UAE-accredited fund with a minimum deposit of
                  US$544,484 (AED2 million)
                </li>
                <li>
                  Founder of a company in the UAE with a capital of no less than
                  US$544,484 (AED2 million)
                </li>
                <li>
                  Partner in an existing or new company with a share value of no
                  less than US$544,484 (AED2 million)
                </li>
              </ul>
            </li>
            <li>
              Talented individuals
              <ul>
                <li>Doctors</li>
                <li>Scientists</li>
                <li>Creatives in culture and art</li>
                <li>Experts in engineering and science</li>
                <li>Inventors</li>
                <li>Executives</li>
                <li>Specialists in scientific fields</li>
                <li>Doctoral degree holders</li>
                <li>Athletes</li>
              </ul>
            </li>
            <li>
              Entrepreneurs
              <ul>
                <li>
                  Owner or partner in a pioneering project registered with Dubai
                  Future Authority
                </li>
                <li>
                  Owner or partner in a start-up classified as a small or medium
                  enterprise (SME)
                </li>
                <li>
                  Founder of a registered pioneering project that was sold for
                  no less than approx. US$1,905,825 (AED7 million)
                </li>
              </ul>
            </li>
            <li>
              Humanitarian workers
              <ul>
                <li>
                  A recommendation by the Ministry of Community Development
                </li>
                <li>A university degree</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Green Visa</h3>
          <p>
            The Green Visa is a five-year residency visa offered for skilled
            professionals, freelancers, and self-employed people. All applicants
            must have university degrees or an equivalent, and meet the relevant
            income requirements.
          </p>

          <h4 style={{ marginTop: "30px" }}>Eligibility</h4>
          <hr />

          <ul>
            <li>
              Freelancers / self-employed
              <ul>
                <li>
                  Freelance or self-employment permit issued by the Ministry of
                  Human Resources and Emiratisation (MOHRE)
                </li>
                <li>Bachelor's degree or its equivalent (fully attested)</li>
                <li>
                  Minimum annual self-employment income of approx. US$98,014
                  (AED360,000)
                </li>
                <li>Proof of financial solvency throughout stay in the UAE</li>
              </ul>
            </li>
            <li>
              Skilled professionals
              <ul>
                <li>A valid employment contract</li>
                <li>A bachelor's degree or its equivalent</li>
                <li>
                  A minimum monthly income of approx. US$4,084 (AED15,000)
                </li>
                <li>
                  An occupational level classified as first, second, or third by
                  the Ministry of Human Resources and Emiratisation (MOHRE)
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Virtual Work Visa</h3>
          <p>
            Dubai's virtual working programme offers people who work remotely
            for a non-UAE company a one-year residency visa. This is ideal for
            those looking to work remotely from Dubai. Note that holders of this
            visa are not allowed to work for UAE companies.
          </p>

          <h4 style={{ marginTop: "30px" }}>Eligibility</h4>
          <hr />

          <ul>
            <li>
              Proof of employment from an employer outside the UAE with a
              minimum one-year contract validity
            </li>
            <li>
              A monthly income of no less than US$3,500 or the equivalent in
              foreign currencies
            </li>
            <li>Proof that the work is being conducted remotely</li>
          </ul>
        </div>
      </div>
      <div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Retirement Visa</h3>
    <p>
      Retirement Visas are available to those aged 55 or above who own property
      or have sufficient income or savings. Retiring to Dubai offers vast
      opportunities for relaxation, travel, or self-exploration – along with
      world-class healthcare facilities.
    </p>
    

    <h4 style={{ marginTop: "30px" }}>Eligibility</h4>
    <hr />

    <ul>
      <li>
        Minimum yearly income of approx. US$49,000 (AED180,000) annually
      </li>
      <li>
        Minimum of approx. US$275,000 (AED1 million) savings in a three-year
        fixed deposit
      </li>
      <li>
        Unmortgaged property valued at a minimum of approx. US$275,000
        (AED1 million)
      </li>
      <li>
        Mortgage property with a minimum of approx. US$275,000 (AED1 million)
        paid off
      </li>
      <li>
        A combination of three-year fixed deposit savings and property, worth a
        minimum of approx. US$275,000 (AED1 million) in total, with property
        making up a minimum of approx. US$137,500 (AED500,000)
      </li>
    </ul>
    
  </div>
</div>
<div
  style={{
    width: "90%",
    margin: "40px auto",
    padding: "50px",
    borderTopLeftRadius: "70px",
  }}
>
  <div
    style={{
      width: "90%",
      lineHeight: "28px",
      margin: "30px 50px",
      color: "#505050",
    }}
  >
    <h3>Blue Visa</h3>
    <p>
      The Blue Visa is a newly-announced 10-year residency visa issued to
      individuals who have made an exceptional contribution towards protecting
      the environment, either in the UAE or internationally.
    </p>


    <h4 style={{ marginTop: "30px" }}>Eligibility</h4>
    <hr />

    <ul>
      <li>
        Members of relevant international charities, associations, NGOs, or
        companies
      </li>
      <li>Global award winners</li>
      <li>
        Distinguished activists and researchers in environmental work
      </li>
    </ul>
    <p>
      Details including full requirements for a Blue Visa, and how to apply, are
      in the process of being finalised: please see the UAE government portal’s
      page for the latest updates.
    </p>

  </div>
</div>
    </>
  );
}
