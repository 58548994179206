import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';
import { FaPhoneAlt } from "react-icons/fa";
import { FaMobileScreen, FaLocationDot } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { CgMail } from "react-icons/cg";
import { Link } from 'react-router-dom';
import contactImg from "../assets/images/pngwing.com-2-100x100.png"

function ContactUs() {
  return (
    <>
             
      <div className="container-xxl py-5">
        <Container className='py-10 mt-5'>
          <center>
            <h2 style={{ padding: '30px' }}>Best Business Setup Firm in Bangladesh.</h2>
            <img src={contactImg} alt="jkCoffee" />
            <h2>You are Invited to have a coffee with us!</h2>
          </center>
          <hr style={{ width: '86.7%', margin: '0 auto', border: '3px solid blue', borderRadius: '5px' }} />
          <div className="flex-container">

            {/* Flex Container 1 */}
            <div className="flex-item" >
              <FaPhoneAlt style={{fontSize:'40px', color:'#239B56', marginTop: '20px' }} /><br /><br />
              <h3 style={{ fontSize: '20px',color:"#6e6d6d" }}>02-8090785 <br />(9.30am - 6.30pm)</h3>
            </div>

            {/* Flex Container 2 */}
            <div className="flex-item">
              <FaMobileScreen style={{ fontSize: '40px', color: '#239B56', marginTop: '20px' }} /><br /><br />
              <h3 style={{ fontSize: '20px',color:"#6e6d6d"  }}>+8801511-404971 <br /> +8801973-404971</h3>
            </div>

            {/* Flex Container 3 */}
            <div className="flex-item">
              <Link to="https://api.whatsapp.com/send?phone=01511404971">
                <IoLogoWhatsapp style={{ fontSize: '40px', color: '#239B56', marginTop: '20px' }} /><br /><br />
                <h3 style={{ fontSize: '20px',color:"#6e6d6d"  }}>+8801511-404971</h3>
              </Link>
            </div>

            {/* Flex Container 4 */}
            <div className="flex-item">
              <a href="mailto:info@jkassociates.com.bd">
                <CgMail style={{ fontSize: '40px', color: '#239B56', marginTop: '20px' }} />
                <br /><br />
                <h3 style={{ fontSize: '20px',color:"#6e6d6d"  }}>info@jkassociates.com.bd</h3>
              </a>
            </div>


            {/* Flex Container 5 */}
            <div className="flex-item">
              <FaLocationDot style={{ fontSize: '40px', color: '#239B56', marginTop: '20px' }} /><br /><br />
              <h3 style={{ fontSize: '20px',color:"#6e6d6d"  }}>752/2, City Bank Building (6th floor) Begum Rokeya Ave, Mirpur,Dhaka 1216</h3>
            </div>
          </div>
          <div className='mb-10px'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.7258972599575!2d90.37192907402824!3d23.792773187150665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8bf5300eaad%3A0xe50b10a249f6394e!2sJK%20Associates%20%7C%20Income%20Tax%20%7C%20VAT%20%7C%20Company%20Registration%20%7C%20Copyright%20%7C%20Trademark!5e0!3m2!1sen!2sbd!4v1710233919831!5m2!1sen!2sbd" width="100%" height="450" style={{ "border": 0, "marginBottom": "10px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Container>
      </div>
             
    </>
  );
}

export default ContactUs;
