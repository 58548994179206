import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import BkashLogo from "../../assets/images/BKash-bKash-Logo.wine.svg";
import RocketLogo from "../../assets/images/Rocket_Logo.png";
import CardPaymentLogo from "../../assets/images/card-payment.png";
import cashOnDeliveryImg from "../../assets/images/jk-cash-on-delivery.png";
import NagadLogo from "../../assets/images/nagad.png";
import "./CheckoutPage.css"; // Optional: add your own CSS for custom styling

const CheckoutPage = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const handlePaymentSelect = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle checkout logic here
    console.log("Selected Payment Method:", selectedPaymentMethod);
  };

  return (
    <Container className="my-5">
      <Row>
        <Col md={8}>
          <Card style={{ padding: "40px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <h5>Payment Method</h5>
              <p className="text-muted">(Please select a payment method)</p>
            </div>
            <hr />

            <Form onSubmit={handleSubmit}>
              {/* Cash on Delivery */}
              <h6 style={{ marginLeft: "10px" }}>Cash On Delivery</h6>
              <Card className="mb-3 col-lg-6 col-sm-12">
                <Card.Body style={{ display: "flex" }}>
                  <Form.Check
                    style={{ marginTop: "10px" }}
                    type="radio"
                    name="paymentMethod"
                    id="cashOnDelivery"
                    checked={selectedPaymentMethod === "Cash on Delivery"}
                  />
                  <div
                    onClick={() => handlePaymentSelect("Cash on Delivery")}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <img
                      src={cashOnDeliveryImg}
                      height={50}
                      width={50}
                      alt=""
                    />
                    <p style={{ fontSize: "15px" }}> Cash on Delivery</p>
                  </div>
                </Card.Body>
              </Card>

              {/* Mobile Wallet */}
              <h6 style={{ marginLeft: "10px" }}>Mobile Wallet</h6>
              <div className="mb-3">
                <Card.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Card
                      className="mb-3"
                      style={{ padding: "0 15px", minHeight: "80px" }}
                    >
                      <Card.Body
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check
                          style={{ marginTop: "10px" }}
                          type="radio"
                          name="paymentMethod"
                          id="cashOnDelivery"
                          checked={selectedPaymentMethod === "bkash"}
                        />
                        <div
                          onClick={() => handlePaymentSelect("bkash")}
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <img src={BkashLogo} height={55} width={105} alt="" />
                        </div>
                      </Card.Body>
                    </Card>
                    <Card
                      className="mb-3"
                      style={{ padding: "0 15px", minHeight: "80px" }}
                    >
                      <Card.Body
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check
                          style={{ marginTop: "10px" }}
                          type="radio"
                          name="paymentMethod"
                          id="cashOnDelivery"
                          checked={selectedPaymentMethod === "rocket"}
                        />
                        <div
                          onClick={() => handlePaymentSelect("rocket")}
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={RocketLogo}
                            height={55}
                            width={105}
                            alt=""
                          />
                        </div>
                      </Card.Body>
                    </Card>
                    <Card
                      className="mb-3"
                      style={{ padding: "0 15px", minHeight: "80px" }}
                    >
                      <Card.Body
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check
                          style={{ marginTop: "10px" }}
                          type="radio"
                          name="paymentMethod"
                          id="cashOnDelivery"
                          checked={selectedPaymentMethod === "nagad"}
                        />
                        <div
                          onClick={() => handlePaymentSelect("nagad")}
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <img src={NagadLogo} height={55} width={105} alt="" />
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Card.Body>
              </div>

              <h6 style={{ marginLeft: "10px" }}>Card Payment</h6>
              <Card className="mb-3 col-lg-6 col-sm-12">
                <Card.Body style={{ display: "flex", gap: "5px" }}>
                  <Form.Check
                    style={{ marginTop: "10px" }}
                    type="radio"
                    name="paymentMethod"
                    id="cashOnDelivery"
                    checked={selectedPaymentMethod === "Cash on Delivery"}
                  />
                  <div
                    onClick={() => handlePaymentSelect("Cash on Delivery")}
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    <img src={CardPaymentLogo} height={50} width={300} alt="" />
                  </div>
                </Card.Body>
              </Card>

              <Button variant="primary" type="submit" className="mt-3">
                Proceed to Payment
              </Button>
            </Form>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <h5>Checkout Summary</h5>
              <div className="d-flex justify-content-between">
                <p>Subtotal:</p>
                <p>290 TK</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Shipping (Changeable):</p>
                <p>53 TK</p>
              </div>
              <div className="d-flex justify-content-between font-weight-bold">
                <p>Total:</p>
                <p>343 TK</p>
              </div>
              <hr />
              <div className="d-flex justify-content-between font-weight-bold">
                <p>Payable Total:</p>
                <p>343 TK</p>
              </div>

              <Form.Group className="mt-3">
                <Form.Label>Apply Voucher or Promo Code</Form.Label>
                <Form.Control type="text" placeholder="Enter code" />
              </Form.Group>

              <div className="alert alert-success mt-3" role="alert">
                You are saving 17%!
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutPage;
