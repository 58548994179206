import React from "react";
import vat_og_Img from "../../assets/og-img/Vat Software.png";
import Footer from "../../components/Footer";
import Meta from "../../components/Meta";
import VATCalculator from "../../components/VATCalculator";

function VATCalculatorPage() {
  return (
    <>
      <Meta
        title="VAT Calculator in Bangladesh | Online Vat Calculator"
        description="VAT Calculator in Bangladesh, Online Vat Calculator, vat software, vat return"
        image={vat_og_Img}
        keywords="Vat Calculator, online vat calculator, vat software, vat return,"
      />
      <div
        className="container-fluid hero-header bg-light py-5 "
        style={{ marginTop: "30px" }}
      >
        <VATCalculator />
        <div className="container-xxl">
          <h3> How the calculation works:</h3>
          <ul>
            <li>
              The component maintains state variables for the total amount
              (amount), VAT rate (vatRate), VAT amount (vatAmount), and total
              amount including VAT (totalAmount).
            </li>
            <li>Users input the total amount in the first input field.</li>
            <li>
              Users can also input the VAT rate in the second input field. The
              default VAT rate is set to 15%, but users can change it.
            </li>
            <li>
              When users click the "Calculate Total" button, the calculateTotal
              function is triggered.
            </li>
            <li>
              Inside calculateTotal, it computes the VAT amount by multiplying
              the total amount by the VAT rate divided by 100.
            </li>
            <li>
              Then, it calculates the total amount including VAT by adding the
              calculated VAT amount to the total amount.
            </li>
            <li>
              Both the VAT amount and the total amount including VAT are rounded
              to 2 decimal places.
            </li>
            <li>
              The calculated VAT amount and total amount including VAT are then
              displayed below the buttons.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VATCalculatorPage;
