
import openForeignCompany from "../assets/og-img/Foreign Company Formation.png"
import workPermitVISA from "../assets/og-img/Work Permit Visa In Bangladesh.png"
import privateInvestmentImg from "../assets/og-img/Private Investment VISA.png"
import directorServiceImg from "../assets/og-img/Foreign Company Director Service.png"
import foreignDelegatesImg from "../assets/og-img/Foreign Delegates.png"
import foreigncompanyregisteredaddressImg from "../assets/og-img/Foreign Company Registered Address.png"
import foreigncompanydirectorserviceImg from "../assets/og-img/Foreign Company Director Service.png"
import BidaImg from "../assets/og-img/BIDA Permission.png"
import MarqueeCard from "../components/MarqueeCard"
import uddoktaImg from "../assets/og-img/Startup Packages.png"
import projectImg from "../assets/og-img/Project Profile.png"
import BSTIPermissionImg from '../assets/og-img/bsti-permission-in-bangladesh.png'
import onePersionCompanyImg from "../assets/og-img/One Person Company.png"
import societyRegistrationIMg from "../assets/og-img/Society Registration.png"
import partnershipImg from "../assets/og-img/Partnership Registration.png"
import rjscImg from "../assets/og-img/RJSC Return Filling.png"
import  loanImg from "../assets/og-img/bank-loan.png"
import imprortImg from "../assets/og-img/Import Registration Certificate.png"
import exportImg from "../assets/og-img/Export Registration Certificate.png"
import tradeImg from "../assets/og-img/Trade License.png"
import compnayFormationImg from "../assets/og-img/company-formation.png"
import vatlImg from "../assets/og-img/Vat Consultant.png"
import individualImg from "../assets/og-img/Income Tax Lawyer.png"
import etinImg from "../assets/og-img/E-Tin Registration.png"
import corporateImg from "../assets/og-img/corporate-tax.png"
import binImg from "../assets/og-img/Bin Registration.png"
import bookeepingImg from "../assets/og-img/Bookkeeping Services in Bangladesh.png"
import smallImg from "../assets/og-img/Small Business Setup in Bangladesh.png"
import accountImg from "../assets/og-img/Account-service.png"
import companyImg from "../assets/og-img/Company Portfolio.png"
import erpImg from "../assets/og-img/ERP Software.png"
import hrImg from "../assets/og-img/HR Management Software.png"
import vatImg from "../assets/og-img/Vat Software.png"
import accountngImg from "../assets/og-img/Accounting Software.png"
import BtclImg from "../assets/og-img/BTCL Domain Registration.png"
import copyrightImg from "../assets/og-img/Copyright.png"
import industrialImg from "../assets/og-img/Industrial Design.png"
import iprImg from "../assets/og-img/IPR Enforcement.png"
import patentImg from "../assets/og-img/Patent.png"
import webdevelopImg from "../assets/images/web-development-1.jpg"
import trademarkImg from "../assets/og-img/trademark.png"
import criminalImg from "../assets/og-img/Criminal Litigation.png"
import fireImg from "../assets/og-img/Fire License.png"
import factoryImg from "../assets/og-img/Factory License.png"
import bondImg from "../assets/og-img/Custom Bond License.png"
import environmentImg from "../assets/og-img/Environment Clearance Certificate.png"
import civilImg from "../assets/og-img/Civil Litigation.png"
export const relatedArticaleData =[
    {
        imageUrl: openForeignCompany,
        title: "Foreign Company Formation",
        buttonText: "Read Foreign Company...",
        buttonLink: "/foreign-company-registration-in-bangladesh",
        category:"fp"
    },
    {
        imageUrl: workPermitVISA,
        title: "Work Permit Visa In Bangladesh",
        buttonText: "Read Work Permit..",
        buttonLink: "/work-permit-visa",
        category:"fp"
    },
    {
        imageUrl: privateInvestmentImg,
        title: "Private Investment VISA",
        buttonText: "Read Private Investment..",
        buttonLink: "/private-investment-visa",
        category:"fp"
    },
    {
        imageUrl: directorServiceImg,
        title: "Foreign Company Director Service",
        buttonText: "Read Foreign Company Dir..",
        buttonLink: "/foreign-company-director-service",
        category:"fp"
    },
    {
        imageUrl: foreignDelegatesImg,
        title: "Foreign Delegates",
        buttonText: "Read Foreign Delegates",
        buttonLink: "/foreign-delegates",
        category:"fp"
    },
    {
        imageUrl: foreigncompanyregisteredaddressImg,
        title: "Foreign Company Registered Address",
        buttonText: "Read Foreign Company..",
        buttonLink: "/foreign-company-registered-address",
        category:"fp"
    },
    {
        imageUrl: foreigncompanydirectorserviceImg,
        title: "Foreign Direct Investment",
        buttonText: "Read Foreign Direct..",
        buttonLink: "/foreign-company-director-service",
        category:"fp"
    },
    {
        imageUrl: BidaImg,
        title: "BIDA Permission",
        buttonText: "Read BIDA Permission",
        buttonLink: "/bida-permission",
        category:"fp"
    },
    {
        imageUrl: uddoktaImg,
        title: "Startup Packages",
        buttonText: "Read Startup Packages",
        buttonLink: "/startup-packages",
        category: "startup"
    },
    {
        imageUrl: projectImg,
        title: "Project Profile",
        buttonText: "Read Project Profile",
        buttonLink: "/project-profile",
        category: "startup"
    },
    {
        imageUrl: tradeImg,
        title: "Trade License",
        buttonText: "Read Trade License",
        buttonLink: "/trade-license",
        category: "startup"
    },
    {
        imageUrl: exportImg,
        title: "Export License",
        buttonText: "Read Export License",
        buttonLink: "/export-license-erc",
        category: "startup"
    },
    {
        imageUrl: imprortImg,
        title: "Import License",
        buttonText: "Read Import License",
        buttonLink: "/import-license-irc",
        category: "startup"
    },
    {
        imageUrl: loanImg,
        title: "Loan Consultancy",
        buttonText: "Read Loan Consultancy",
        buttonLink: "/loan-consultancy-services",
        category: "startup"
    },
    {
        imageUrl: rjscImg,
        title: "RJSC Return Filling",
        buttonText: "Read RJSC Return",
        buttonLink: "/rjsc-return-filing",
        category: "startup"
    },
    {
        imageUrl: partnershipImg,
        title: "Partnership Registration",
        buttonText: "Read Partnership Reg",
        buttonLink: "/partnership-registration-in-bangladesh",
        category: "startup"
    },
    {
        imageUrl: societyRegistrationIMg,
        title: "Society Registration",
        buttonText: "Read Society Reg",
        buttonLink: "/society-registration-in-bangladesh",
        category: "startup"
    },
    {
        imageUrl: onePersionCompanyImg,
        title: "One Permission Company",
        buttonText: "Read One Permission",
        buttonLink: "/one-person-company-registration-bangladesh",
        category: "startup"
    },
    {
        imageUrl: compnayFormationImg,
        title: "Company Formation",
        buttonText: "Read Company Formation",
        buttonLink: "/company-formation-form-page",
        category: "startup"
    },
    {
        imageUrl: BSTIPermissionImg,
        title: "BSTI Permission",
        buttonText: "Read BSTI Permission",
        buttonLink: "/bsti-permissions",
        category: "startup"
    },
    {
        imageUrl: binImg,
        title: "BIN Registration",
        buttonText: "Read BIN Reg",
        buttonLink: "/bin-registration",
        category: "tax"
    },
    {
        imageUrl: corporateImg,
        title: "Corporate TAX",
        buttonText: "Read Corporate TAX",
        buttonLink: "/corporate-tax",
        category: "tax"
    },
    {
        imageUrl: etinImg,
        title: "E-TIN Certificate",
        buttonText: "Read E-TIN ",
        buttonLink: "/etin-certificate",
        category: "tax"
    },
    {
        imageUrl: individualImg,
        title: "Individual TAX",
        buttonText: "Read Individual",
        buttonLink: "/individual-tax",
        category: "tax"
    },
    {
        imageUrl: vatlImg,
        title: "Value Added TAX",
        buttonText: "Read Value Added TAX",
        buttonLink: "/value-added-tax",
        category: "tax"
    },
    {
        imageUrl: accountImg,
        title: "Accounting Service",
        buttonText: "Read Accounting Service",
        buttonLink: "/account-service",
        category: "service"
    },
    {
        imageUrl: bookeepingImg,
        title: "Bookkeeping Service",
        buttonText: "Read Bookkeeping",
        buttonLink: "/bookkeeping-services",
        category: "service"
    },
    {
        imageUrl: smallImg,
        title: "Small Business",
        buttonText: "Read Small Business",
        buttonLink: "/small-business-in-bangladesh",
        category: "service"
    },
    {
        imageUrl: vatImg,
        title: "VAT Software",
        buttonText: "Read VAT Software",
        buttonLink: "/vat-software",
        category: "software"
    },
    {
        imageUrl: hrImg,
        title: "HR Management Software",
        buttonText: "Read HR Management..",
        buttonLink: "/hr-management-software",
        category: "software"
    },
    {
        imageUrl: erpImg,
        title: "ERP Management Software",
        buttonText: "Read ERP Management..",
        buttonLink: "/erp-software",
        category: "software"
    },
    {
        imageUrl: companyImg,
        title: "Company Portfolio",
        buttonText: "Read Company Portfolio",
        buttonLink: "/company-portfolio",
        category: "software"
    },
    {
        imageUrl: accountngImg,
        title: "Accounting Software",
        buttonText: "Read Accounting Software",
        buttonLink: "/accounting-software",
        category: "software"
    },
    {
        imageUrl: webdevelopImg,
        title: "Foreign Company Formation",
        buttonText: "Read Foreign Company..",
        buttonLink: "/web-development",
        category: "intellectual"
    },
    {
        imageUrl: trademarkImg,
        title: "Trademark",
        buttonText: "Read Trademark",
        buttonLink: "/trademark",
        category: "intellectual"
    },
    {
        imageUrl: patentImg,
        title: "Patent",
        buttonText: "Read Patent",
        buttonLink: "/patent",
        category: "intellectual"
    },
    {
        imageUrl: iprImg,
        title: "IPR Enforcement",
        buttonText: "Read IPR Enforcement",
        buttonLink: "/ipr-enforcement",
        category: "intellectual"
    },
    {
        imageUrl: industrialImg,
        title: "Industrial Design",
        buttonText: "Read Industrial",
        buttonLink: "/industrial-design",
        category: "intellectual"
    },
    {
        imageUrl: copyrightImg,
        title: "Copyright",
        buttonText: "Read Copyright",
        buttonLink: "/copyright",
        category: "intellectual"
    },
    {
        imageUrl: BtclImg,
        title: "BTCL Domain Registration",
        buttonText: "Read BTCL Domain",
        buttonLink: "/btcl-dr",
        category: "intellectual"
    },
    {
        imageUrl: environmentImg,
        title: "Environment Clearance Certificate",
        buttonText: "Read Environment Clearance",
        buttonLink: "/environment-clearance-certificate",
        category: "others"
    },
    {
        imageUrl: bondImg,
        title: "Custom Bond License",
        buttonText: "Read Custom Bond",
        buttonLink: "/customs-bond-license",
        category: "others"
    },
    {
        imageUrl: factoryImg,
        title: "Factory License",
        buttonText: "Read Factory License",
        buttonLink: "/factory-license",
        category: "others"
    },
    {
        imageUrl: fireImg,
        title: "Fire License",
        buttonText: "Read Fire License",
        buttonLink: "/fire-license",
        category: "others"
    },
    {
        imageUrl: criminalImg,
        title: "Criminal Litigation",
        buttonText: "Read Criminal Litigation",
        buttonLink: "/criminal-litigation",
        category: "others"
    },
    {
        imageUrl: civilImg,
        title: "Civil Litigation",
        buttonText: "Read Civil Litigation",
        buttonLink: "/civil-litigation",
        category: "others"
    }
]



export const fpRealaatedArticales = relatedArticaleData?.filter((item)=>item?.category === "fp").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))

export const startupRelatedArticales = relatedArticaleData?.filter((item)=>item?.category === "startup").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))
export const taxRelatedArticales = relatedArticaleData?.filter((item)=>item?.category === "tax").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))
export const serviceRelatedArticales = relatedArticaleData?.filter((item)=>item?.category === "service").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))
export const softwareRelatedArticales = relatedArticaleData?.filter((item)=>item?.category === "software").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))
export const intellectualRelatedArticales = relatedArticaleData?.filter((item)=>item?.category === "intellectual").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))
export const othersArticales = relatedArticaleData?.filter((item)=>item?.category === "others").map((item,index)=>(
    <MarqueeCard
    key={index}
    imageUrl={item.imageUrl}
    title={item?.title}
    buttonText={item?.buttonText}
    ariaLabel = {`More about ${item?.title}`}
    buttonLink={item?.buttonLink}
/>
))

