import React from 'react'
import { FaStar } from "react-icons/fa";
export default function TestimonialCard({ item }) {
    const stars = Array(5).fill(<FaStar  style={{ color: "#ffca05", fontSize: "18px" }} />)

    return (
        <div style={{ background: "white", padding: "20px", borderRadius: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <div>
                    {
                        stars?.map(item=>item)
                    }
                </div>
                <p style={{ marginTop: "16px" }}>{item?.time}</p>
            </div>

            <p style={{ fontSize: "18px", color: "black" }}>{item?.review}</p>
            <div style={{ display: "flex", alignItems: "center", gap: "15px", marginTop: "25px" }}>
                <img style={{ display: "block", width: "2.5em", height: "2.5em", borderRadius: "50%", objectFit: "cover" }} src={item?.imgUrl} alt="JK Associates Clients Reviews" />
                <div style={{ color: "#1d3a6c", fontWeight: "bold", fontSize: "17px" }}>{item?.name}</div>
            </div>
        </div>
    )
}
