import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./style.css";

ReactGA.initialize("G-MBQYJM0ZDB");

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement); // Initialize root for React 18

root.render(
  <>
    <App />
    <Toaster />
  </>
);

reportWebVitals();
