import React from "react";
import { Table } from "react-bootstrap";
import { licenseList } from "../data/licenseAndAprovalList";
import Meta from "./Meta";

export default function LicenseAndApproval() {
  return (
    <>
      <Meta
        title="Business License in Bangladesh | Business Certificates"
        description="a list of business licenses/ approvals/ permits/ certificates, which are most frequently required for doing business in Bangladesh. Please note that there are some other licenses/approvals/permits, which are not mentioned here. Unless you are conducting your business as a proprietorship, you will be required to register a Partnership or Company prior to obtaining these licenses/approvals/permits/certificate."
        image={"image will be here"}
        keywords="business licenses in bangladesh, Business Certificates, Business license in bangladesh requirements for foreigners"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <h1
            style={{ marginBottom: "20px", fontSize: "2.12rem" }}
            className="color-text display-6"
          >
            Business License and Business Certificates Approval in Bangladesh
          </h1>
          <p>
            Below we have compiled a list of
            licenses/ approvals/ permits/ certificates, which are most frequently
            required for doing business in Bangladesh. Please note that there
            are some other licenses/approvals/permits, which are not mentioned
            here.
            <br />
            <br />
            Unless you are conducting your business as a proprietorship, you
            will be required to register a Partnership or Company prior to
            obtaining these licenses/ approvals/ permits/ certificate.
          </p>
          {
            <Table
              className="table"
              striped
              bordered
              hover
              variant="light blue"
            >
              <thead>
                <tr>
                  <th scope="col">Required Business Licenses for Bangladesh</th>
                  <th scope="col">Licenses Approval Authority</th>
                </tr>
              </thead>
              <tbody>
                {licenseList.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.licenseName}</td>
                    <td>{item?.agency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      </div>
    </>
  );
}
