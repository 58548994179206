import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function RoadsAndDriving() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Driving in Dubai: a comprehensive guide
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p>
            Residents and non-residents can easily drive around Dubai with the
            required documentation and by following the rules.
          </p>
          <h2 className="resource-section-title">
            Getting around Dubai by car
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai and the UAE follow the right-hand drive system, where drivers
            must stick to the right-hand side of the roads, and the steering
            wheel is on the left. Every resident needs a valid driving licence
            to drive in Dubai, while non-residents usually need a valid
            international driving licence from their home country.
            <br />
            <br />
            The Roads and Transport Authority (RTA) in Dubai oversees the
            processes of licensing and vehicle registration in the city, and is
            in charge of traffic rules and regulations. The RTA also maintains
            the city’s road network.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Key highways</h3>
          <ul>
            <li>
              E11 – this is an arterial highway running across six of the seven
              Emirates of the UAE (except Fujairah)
            </li>
            <li>
              Sheikh Zayed Road – this is a toll road and forms the central part
              of the E11 running across Dubai, covering many of the city’s key
              landmarks, including Mall of the Emirates, Burj Khalifa, Museum of
              the Future and Bur Dubai
            </li>
            <li>
              E311 – also known as Sheikh Mohammed Bin Zayed Road, this is a
              toll-free highway that connects Dubai to neighbouring Sharjah,
              Ajman and Umm Al Quwain
            </li>
          </ul>
        </div>
      </div>
      <div className="resource-container">
        <h1 style={{ textAlign: "left" }}>
          How to get a driving licence in Dubai
        </h1>
        <p style={{ textAlign: "left" }}>
          Getting a licence is a simple process, following the rules set by the
          RTA. Enrolling in and completing a training programme from an
          RTA-approved driving school is mandatory. This equips candidates with
          the skills and knowledge to safely navigate roads with confidence.
        </p>
        <h3 style={{ textAlign: "left" }}>Basic requirements</h3>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Applicant age</h4>
            <p>
              New applicants must be at least 17 years and six months old to
              start applying for a driving licence. The actual licence will only
              be issued once drivers reach the age of 18.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Medical fitness</h4>
            <p>
              Prospective drivers should be medically fit to operate a vehicle.
              This requires passing a medical examination at any RTA-approved
              testing centre in Dubai.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Training level</h4>
            <p>
              Applicants must pass theoretical and practical tests, which
              evaluate knowledge of Dubai road signs and traffic symbols,
              driving performance and parking ability.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Driving licence for people of determination</h3>
          <p>
            Dubai is an inclusive city, empowering people of determination to
            pursue and obtain a driver’s licence as long as they meet certain
            set conditions based on recommendations from relevant medical
            reports.
            <br />
            <br />
            Customised classes accommodate people of determination, with
            necessary adjustments to help the driver overcome challenges.
            Drivers with certain physical disabilities can modify their
            privately owned vehicles accordingly and train with adaptive driving
            equipment.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h3 style={{ textAlign: "left" }}>
          Transition to a valid Dubai driver licence
        </h3>

        <p style={{ textAlign: "left" }}>
          Residents who already hold a driver’s licence in another country can
          transfer it in the UAE, including Dubai, by visiting a driver’s
          licence department with the following documents:
        </p>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />
            <p>Original driving licence from eligible country + copies</p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <p>Original passport, along with a copy</p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <p>Eye test results from an approved testing centre</p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />
            <p>No objection letter from sponsor (if required)</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Driving schools</h3>
          <p>
            Multiple RTA-approved driving schools in Dubai offer a variety of
            services for residents and visitors, including:
          </p>
          <ul>
            <li>Driving courses for beginners</li>
            <li>Refresher courses for experienced drivers</li>
            <li>Licence transfer services</li>
          </ul>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Getting auto insurance</h4>
        <p>
          Having car insurance is mandatory for all Dubai vehicle owners (the
          insurance is usually provided by the agency in case of rented
          vehicles).
          <br />
          <br />
          Things to know about car insurance:
        </p>
        <ul>
          <li>
            Many types of insurance are available. Most commonly, motorists go
            for third-party insurance, which covers bodily injury and any damage
            done to property by the owner’s vehicle.
          </li>
          <li>
            Comprehensive insurance extends to the owned vehicle, covering
            accidents, theft or natural disasters. Platforms like Insurance
            Market provide guides to understand more about insurance options.
          </li>
          <li>
            Car insurance in Dubai typically ranges from 1.25% to 3% of the
            car’s value. Factors such as previous insurance claims, the driver’s
            age and licence history may affect these rates. It is advisable to
            compare insurance rates and conditions between various providers
            before making a final choice.
          </li>
        </ul>
      </div>
      <div className="resource-container">
        <h3 style={{ textAlign: "left" }}>Car insurance documents</h3>

        <p style={{ textAlign: "left" }}>
          Car insurance expires after a 12-month period, with a 30-day grace
          period for renewal. Driving without car insurance incurs a fine of
          AED500, as well as 4 black points on the driving licence. The car may
          even be impounded for seven days.
        </p>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />
            <p>Driver’s licence</p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <p>Previous insurance claims</p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <p>Vehicle registration card</p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />
            <p>Model, size and vehicle manufacture year</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Quick tips for driving in Dubai</h3>
          <p>
            Multiple RTA-approved driving schools in Dubai offer a variety of
            services for residents and visitors, including:
          </p>
          <ul>
            <li>
              Know the speed limits: streets in the city have a 'buffer zone' of
              20kmph over the stated speed limit. Drivers and motorists must
              stay within this limit to ensure road safety and avoid driving
              fines in Dubai. Streets are monitored by radar and fines will be
              automatically applied on any vehicle violating traffic rules or
              exceeding the speed limits.{" "}
            </li>
            <li>Know the local road rules and regulations thoroughly</li>
            <li>Use RTA Smart Drive to navigate the city better</li>
            <li>
              Always ensure that the driver and all passengers are wearing their
              seatbelts. This improves safety and helps owners avoid fines and
              black points.
            </li>
            <li>
              All drivers must be 18 years of age or older to drive in the city
            </li>
            <li>Maintain a safe driving distance</li>
          </ul>
        </div>
      </div>
    </>
  );
}
