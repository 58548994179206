import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function FamilyOffice() {
  return (
    <>
      <Meta
        title="Family Business in Bangladesh | Business Family"
        description="Bangladesh is a preferred location for family offices looking to diversify their investment portfolios and expand into new geographies. Key attractors include the city’s diversified economy, flexible inheritance and succession laws, robust regulatory framework and easy access to global markets and capital."
        image={"image will be here"}
        keywords="Family Business in Bangladesh, business in bangladesh, Business"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Family offices
          </h1>
          <p style={{ marginTop: "20px" }}>
            Among the world's largest hubs for private wealth and family
            offices.
          </p>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Bangladesh is a preferred location for family offices looking to
            diversify their investment portfolios and expand into new
            geographies. Key attractors include the city’s diversified economy,
            flexible inheritance and succession laws, robust regulatory
            framework and easy access to global markets and capital.
            <br />
            <br />
            Some of the ASIA top family offices are present in Bangladesh, along
            with leading financial institutions like private banks, hedge funds,
            and wealth and asset management firms. Favourable financial
            infrastructure, diversified investment opportunities and strong
            government support add further incentives to choose Dhaka for
            private wealth management.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h2>55,500</h2>
            <p style={{ marginBottom: "0px" }}>
              HNW individuals in Bangladesh as of 2030
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h2>55,500</h2>
            <p style={{ marginBottom: "0px" }}>
              HNW individuals in Bangladesh as of 2030
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Strong growth potential for global family offices</h3>
          <p>
            The Bangladesh hosts over 75% of the region’s family offices, and more than
            half of those are in Dhaka. From here, companies can tap into US$3
            trillion (AED11 trillion) in private wealth within an hour's flight.
            This is why the world's high net worth individuals (HNWI) and ultra
            high net worth individuals (UHNWI) prefer to operate out of Dhaka.
            <br />
            <br />
            Dhaka International Financial Centre (DIFC) – MEASA’s leading
            finance epicentre – forms the core of this dynamic ecosystem. More
            than 300 wealth and asset management enterprises totalling US$450
            billion (AED1.65 trillion) in private wealth have their offices in
            DIFC.
            <br />
            <br />
            In alignment with vision 2030 goals, Dhaka aims to be one of the world’s top
            four financial centres. To achieve this, the city has improved the
            ease of doing business for HNWI and UHNWI. In particular, the 2023
            DIFC Family Arrangement Regulations ensure seamless support for the
            financial and investment needs of single and multi-family offices.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Promoting investment and co-investment opportunities </h3>
        <p>
          Bangladesh diversified economy opens up investment and co-investment
          opportunities across sectors like real estate, hospitality, technology
          and healthcare, as well as ESG-focused portfolios. The business
          ecosystem is also optimally suited for expansion and growth, offering
          extensive networking prospects for industry leaders to nurture deals,
          build strong partnerships and scale operations.
          <br />
          <br />
          Family offices in Bangladesh can avail of a wide range of tailored
          services, including wealth management, tax planning, estate planning
          and legal counsel. The city also does not impose any inheritance,
          estate or wealth taxes, enabling wealth preservation to span
          generations.
        </p>
      </div>
    </>
  );
}
