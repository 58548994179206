import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import NavBar from "../NavBar";

const CartPage = () => {
  return (
    <>
      <NavBar />
      <Container className="my-5">
        <Row>
          {/* Left Side: Product Short Info */}
          <Col md={8}>
            <h3>Cart Items</h3>
            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col xs={4}>
                    <img
                      src="https://via.placeholder.com/150"
                      alt="Product"
                      className="img-fluid"
                    />
                  </Col>
                  <Col xs={8}>
                    <h5>Product Name</h5>
                    <p>Short description of the product.</p>
                    <p>Price: $50</p>
                    <Button variant="danger" size="sm">
                      Remove
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Additional Product Cards Can Go Here */}
          </Col>

          {/* Right Side: Checkout Summary */}
          <Col md={4}>
            <h3>Summary</h3>
            <Card>
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "20px" }}>Subtotal: </p>
                  <p style={{ fontSize: "20px" }}>$100 </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "20px" }}>Tax: </p>
                  <p style={{ fontSize: "20px" }}>$100 </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderTop: "1px solid black",
                    paddingTop: "20px",
                  }}
                >
                  <h5 style={{ fontSize: "20px" }}>Total: </h5>
                  <h5 style={{ fontSize: "20px" }}>$100 </h5>
                </div>
              </Card.Body>
              <Button variant="primary" block>
                Proceed to Checkout
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CartPage;
