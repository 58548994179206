
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToast, SuccessToast } from '../helper/FormHelper';

function CompanyFormationViewForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [need, setNeed] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://jkconsultancy-backend.onrender.com/send-mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          phone,
          need,
          message,
        }),
      });

      const data = await response.json();
      SuccessToast("Form Submited Successfully");
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
      ErrorToast("Something Went Wrong");
    }

    setName('');
    setEmail('');
    setPhone('');
    setNeed('');
    setMessage('');
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="container">
          <div className="text-center">
            <h2 className="text-primary">Get a Free Consultation For FDI</h2>
          </div>
          <div className="row">
            <div className="col-lg-7 mx-auto">
              <div className="card mt-2 mx-auto p-4 bg-light custom-form-container">
                <div className="card-body bg-light">
                  <div className="container">
                    <form id="contact-form" onSubmit={handleSubmit}>
                      <div className="controls">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_name">Full Name *</label>
                              <input
                                id="form_name"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Please enter your firstname *"
                                
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_phone">Phone *</label>
                              <input
                                id="form_phone"
                                type="text"
                                name="phone"
                                className="form-control"
                                placeholder="Please enter your phone no *"
                                data-error="Phone no. is required."
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_email">Email *</label>
                              <input
                                id="form_email"
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Please enter your email *"
                                
                                data-error="Valid email is required."
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="form_need">Please Specify Your Need *</label>
                              <select
                                id="form_need"
                                name="need"
                                className="form-control"
                                
                                data-error="Please specify your need."
                                value={need}
                                onChange={(e) => setNeed(e.target.value)}
                              >
                                <option selected>FDI / Foreign Company Formation</option> 
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="form_message">Message *</label>
                              <textarea
                                id="form_message"
                                name="message"
                                className="form-control"
                                placeholder="Write your message here."
                                rows={4}
                                data-error="Please, leave us a message."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="submit"
                              className="btn btn-success btn-send  pt-2 btn-block"
                              value="Send Message"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyFormationViewForm;

