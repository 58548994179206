import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import NavLink from '../components/NavLink'
import SecondaryNavbar from '../components/SecendoryNavbar'
import ResourceNavbar from '../components/ResourceNavbar'
import Footer from '../components/Footer'

export default function Resource() {
    const {pathname} = useLocation()

  return (
    <>
    <ResourceNavbar/>
      <Outlet/>
      <Footer/>
    </>
  )
}
