import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import Meta from "../components/Meta";
export default function CleanTech() {
  return (
    <>
      <Meta
        title="Web page title here"
        description="wepage description"
        image={"image will be here"}
        keywords="key word-1,key word-2,keyword-3"
      />
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Energy and cleantech
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">Powering the future</h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Clean energy and green transformation are top priorities in Dubai
            and the UAE. Over the past 15 years, significant progress has been
            made by investing more than US$40 billion (AED147 billion) in the
            energy sector, including financing alternative energy projects.
            <br />
            <br />
            Dubai’s population is expected to reach 5.8 million residents by
            2040, and the city is actively preparing to meet the increased
            energy demand through renewable and clean sources. In line with
            national efforts, the Dubai Clean Energy Strategy aims to position
            the city as a worldwide nucleus for clean energy and economy by
            2050. Plans are also in place to help Dubai achieve the world’s
            smallest carbon footprint.
          </p>
          <h5>Path to Net Zero by 2050 </h5>
          <p>
            The UAE has made a steadfast commitment to fight global warming
            through numerous environmental initiatives and efforts. Central to
            its efforts is the UAE Net Zero Strategy, a key national priority
            that aims to achieve net-zero greenhouse emissions (GHG) by 2050
            across all sectors. This strategy is aligned with the objectives of
            the 2015 Paris Agreement, making the nation a pioneer in the MEASA
            region.
            <br />
            <br />
            Current trends forecast the national clean energy production
            capacity, including solar and nuclear energy, to reach 14 GW by
            2030. Dubai is set to play a very important role in helping the UAE
            achieve its goals in this arena via the Dubai Clean Energy Strategy.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/a/architecture-at-expo-dubai/media/sustainabilitypavilion10eventorganiseroct2021jpg.jpg?rev=c15c6d04e33d461986224435ba25af1b&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Vibrant ESCO market</h4>
            <p>
              Energy Service Companies in Dubai support its fast-growing green
              economy.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/solar-park-dubai-clean-energy-tech.jpg?rev=d536477545a94713ab2ab05683b24c3b&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Energy industry hub</h4>
            <p>
              with government-owned firms like ENOC and multinationals like BP,
              CPECC and Halliburton
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/video-thumbnails/open-your-eyes-renewables.png?rev=02d49e27ddc440de8e0fecbbd4ee923e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Progressive investments</h4>
            <p>
              in renewables and cleantech to achieve Net Zero carbon emissions
              by 2050 in all sectors.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/business-in-dubai-partnerships-2-dtcm.png?rev=261a6b10947b4bbbadfd95bd4f17f564&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Strategic partnerships</h4>
            <p>
              to support clean and renewable energy projects across public and
              private sectors in Dubai and the UAE.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Harnessing solar efficiency</h3>
          <p>
            Dubai is home to the largest single-site solar park in the world,
            the Mohammed Bin Rashid (MBR) Solar Park,The park’s fifth phase was
            inaugurated in June 2023 and has a total capacity of 900MW, which
            will provide clean energy to 270,000 Dubai residences and reduce
            carbon emissions by 1.18 million tonnes. With a planned capacity of
            5,000MW by 2030 and a total investment of US$13.6 billion, MBR Solar
            park is set to reduce 6.5 million tonnes of carbon emissions
            annually by 2030 .
            <br />
            <br />
            DEWA's (Dubai Electricity and Water Authority) Hatta hydroelectric
            power plant is another great example. It is the first of its kind in
            the GCC. With a storage capacity of over 1500 MW, it uses water
            stored in the Hatta Dam to generate solar energy.
          </p>
          <h4 style={{ marginTop: "20px" }}>
            Green hydrogen for a clean future
          </h4>
          <p>
            Dubai is spearheading the development of novel technologies for
            green hydrogen production using clean and renewable energy. Green
            hydrogen production is projected to increase by 57% annually,
            reaching 5.7 million tonnes in 2030. The UAE is actively seeking
            global competition in the low carbon hydrogen sector, aiming to
            acquire 25% of the market, which is estimated to hit US$400 billion
            (AED1.47 trillion).
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Green and sustainable manufacturing</h3>
        <p>
          Dubai Electricity and Water Authority ‘DEWA’ unveiled an ambitious
          industry-friendly energy policy that encourages manufacturers,
          factories, data centres and agritech firms to use solar power matching
          their total connected load. This move is expected to position Dubai as
          an attractive base for manufacturers and exporters, and spur the
          development of a green economy, in line with the objectives of the
          Dubai Economic Agenda D33.
        </p>
        <h3 style={{ marginTop: "20px" }}>
          Empowering tech-driven energy innovation
        </h3>
        <p>
          Dubai’s extensive efforts to build a sustainable green economy has
          made it a preferred choice for cleantech start-ups from all over the
          world. This has also resulted in increased interest from investors
          looking to diversify their portfolios and provide funding to projects
          that are designed to benefit the environment and reduce carbon
          emissions.
        </p>
        <h5>Attracting investments in cleantech</h5>
        <p>
          Dubai’s clean energy sector, boosted by the city’s commitment to
          renewables and carbon-neutrality, offers multiple lucrative
          opportunities for investors. As a whole, the sector is expected to
          grow at 8% per year from 2024 to 2029. Pro-investor policies and the
          region’s expected rise in energy demand due to population growth are
          both expected to spur increased investments in clean energy and
          renewables projects.
          <br />
          <br />
          In 2021, DEWA’s Innovation Centre launched the Cleantech Connect
          Programme, which brings together innovators, industry experts and
          academics to raise awareness, share expertise and demonstrate the
          success of the latest innovations and developments in the sector.
          Under the programme framework, DEWA organises regular workshops,
          seminars and panel discussions to promote innovation in
          sustainability, renewables and clean energy projects.
        </p>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$27.23 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              Green Fund to provide easy, low-interest loans to clean-energy
              investors.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$13.6 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              invested into the world’s largest single-site solar park, the
              Mohammed Bin Rashid (MBR) Solar Park.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Paving the roads of tomorrow</h4>
        <p>
          Dubai’s Smart City approach aims to transform it into the most
          sustainable, and subsequently happiest city on earth. Dubai has been
          at the forefront of smart city development in the Middle East, with
          its solutions dating back to 2014, and the initiative links the public
          and private sectors together to embrace technology innovation.
        </p>
      </div>
    </>
  );
}
