import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import CompanyFormationViewForm from '../components/CompanyFormationViewForm'
import companyImg from "../assets/images/open-Foreign-Company.png"

function CompanyFormationViewFormPage() {
  return (
    <>
             
      <div className="section services-section">
        <CompanyFormationViewForm />
      </div>
      <div className='container'>
        <center>
          <img src={companyImg} alt='open-Foreign-Company' />
        </center>
      </div>
             
    </>
  )
}

export default CompanyFormationViewFormPage