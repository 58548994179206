import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../components/Faq';
import Meta from '../../components/Meta';
import environmentImg from "../../assets/images/Environmental.png"
import environment_og_Img from "../../assets/og-img/Environment Clearance Certificate.png"
import Marquee from 'react-fast-marquee';
import environmentImg2 from "../../assets/images/Fire-License-In-Bangladesh-3.png"
import { othersArticales } from '../../data/relatedArticale';

function EnvironmentClearanceCertificate() {
    const faqItems = [
        {
            title: 'Accordion Item #1',
            content: <strong>One Person Company</strong>
        },
        {
            title: 'Accordion Item #2',
            content: <strong>This is the second item's accordion body.</strong>
        },
        {
            title: 'Accordion Item #3',
            content: <strong>This is the third item's accordion body.</strong>
        }
    ];
    return (
        <>
            <Meta title="Environment Licenses in Bangladesh | Environmental Clearance Procedure"
                description="The Procedure for issuing Environmental. Clearance Certificate has been stated in the Rule-7 of the Environmental Conservation Rules"
                image={environment_og_Img}
                keywords="Environmental Clearance, environment clearance certificate in Bangladesh, Environmental Certificate"
            />
            
                   

            <div className="container-fluid hero-header bg-light py-5 mb-5">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Environment Clearance Certificate</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Regulatory License</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Environment Clearance Certificate</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={environmentImg} alt='Environmental' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        In summary, the Environment License (DOE) in Bangladesh is a legal permit that
                        regulates and controls the environmental impact of industrial and commercial
                        activities and is mandatory for any company or organization that plans to establish
                        a new project or expand an existing project in the country.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            What is an Environment License (DOE) in Bangladesh
                        </b>
                    </p>
                    <p>
                        An Environment License in Bangladesh, also known as a Department of Environment (DOE)
                        License, is a legal permit issued by the Department of Environment under the Ministry
                        of Environment and Forests of the Government of Bangladesh. The purpose of this
                        license is to regulate and control the environmental impact of industrial,
                        commercial, and other activities that could have a negative impact on the
                        environment.<br /><br />

                        The Environment License is mandatory for any company or organization that plans to
                        establish a new project or expand an existing project in Bangladesh, particularly if
                        it involves the production or use of hazardous materials or processes that could
                        potentially harm the environment. The license provides guidelines and requirements
                        that the company must follow to ensure that its operations are environmentally
                        friendly and sustainable.<br /><br />

                        The process of obtaining an Environment License involves submitting an application to
                        the Department of Environment, along with an Environmental Impact Assessment (EIA)
                        report, which outlines the potential environmental impact of the proposed project and
                        the measures that will be taken to mitigate any adverse effects. The Department of
                        Environment reviews the application and EIA report and may conduct site inspections
                        before issuing the license.
                    </p>

                    <p className="color-text fs-5 mb-4">
                        <b>
                            Who issues the Environment License (DOE)
                        </b>
                    </p>

                    <p>
                        The Environmental License in Bangladesh, also known as Department of Environment
                        (DOE) License, is issued by the Department of Environment (DOE) under the Ministry of
                        Environment and Forests of the Government of Bangladesh.<br /><br />

                        The DOE is responsible for enforcing environmental laws, rules, and regulations in
                        Bangladesh. The department issues Environmental Licenses to regulate and control the
                        environmental impact of industrial, commercial, and other activities that could
                        potentially have a negative impact on the environment. The license provides
                        guidelines and requirements that the companies must follow to ensure that their
                        operations are environmentally friendly and sustainable.<br /><br />

                        To obtain an Environmental License, a company must submit an application to the DOE
                        along with an Environmental Impact Assessment (EIA) report, which outlines the
                        potential environmental impact of the proposed project and the measures that will be
                        taken to mitigate any adverse effects. The DOE reviews the application and EIA report
                        and may conduct site inspections before issuing the license.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Criteria for obtaining an Environment License (DOE)
                        </b>
                    </p>
                    <p>
                        The criteria for obtaining an Environmental License include:
                    </p>

                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Submitting an application: </b>The company or organization must submit an
                                application to the Department of Environment (DOE) along with relevant
                                documents such as the Environmental Impact Assessment (EIA) report, which
                                outlines the potential environmental impact of the proposed project and the
                                measures that will be taken to mitigate any adverse effects.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Compliance with environmental regulations: </b>The company or organization
                                must comply with all the relevant environmental regulations and standards set
                                by the government of Bangladesh.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Environmental Management Plan: </b>The company or organization must
                                prepare an Environmental Management Plan (EMP) that outlines the
                                environmental management strategies and actions that will be implemented to
                                minimize the negative impacts on the environment.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Site inspection: </b>The Department of Environment (DOE) may conduct a
                                site inspection to assess the potential environmental impacts of the proposed
                                project.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Public consultation: </b>The company or organization may be required to
                                hold public consultations to engage with stakeholders and receive feedback
                                on the proposed project.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Environmental Monitoring: </b>The company or organization must set up a
                                system for environmental monitoring and reporting to ensure compliance with
                                the conditions of the license.
                            </span>
                        </div>
                        Once the application and supporting documents have been reviewed and approved by the
                        Department of Environment (DOE), the company or organization will be issued an
                        Environmental License. The license outlines the specific conditions that must be met
                        to ensure compliance with environmental regulations and standards.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Types of industries that require an Environment  License (DOE)
                        </b>
                    </p>
                    <p>
                        In Bangladesh, an Environmental License, also known as Department of Environment (DOE)
                        License, is required for certain types of industries and activities that have the
                        potential to harm the environment. The types of industries and activities that require
                        an Environmental License (DOE) in Bangladesh include, but are not limited to:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Large-scale industrial projects, such as chemical manufacturing, power
                                plants, cement plants, and textile mills.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Projects involving the extraction and processing of natural resources, such
                                as mining, oil and gas drilling, and forestry.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Projects involving the disposal of hazardous wastes or other hazardous materials.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Construction and development projects, such as building highways, bridges, and airports.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Agricultural and aqua cultural projects that involve the use of pesticides,
                                fertilisers, or other chemicals.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Projects that involve the generation of significant amounts of noise, vibration, or air
                                pollution.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Large-scale infrastructure projects, such as dams, ports, and airports.
                            </span>
                        </div>
                        In general, any project or activity that has the potential to significantly impact
                        the environment, including air, water, soil, and ecosystems, is required to obtain
                        an Environmental License (DOE) in Bangladesh. The purpose of the license is to
                        regulate and control the environmental impact of these activities and ensure that
                        they are conducted in a sustainable and environmentally friendly manner.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Documents needed to apply for an Environment License (DOE)
                        </b>
                    </p>
                    <p>
                        The specific documents required may vary depending on the nature and scope of the
                        proposed project, but typically include:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Application form: </b>The company or organization must complete an
                                application form that provides details about the proposed project, including
                                the location, size, and nature of the activities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b> Environmental Impact Assessment (EIA) report: </b>A detailed study of the
                                potential environmental impacts of the proposed project. It should include
                                information on the existing environmental conditions, the potential impacts of
                                the project, and the measures that will be taken to mitigate any adverse
                                effects.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Environmental Management Plan (EMP): </b>It is a plan that outlines the
                                strategies and actions that will be implemented to minimize the negative
                                impacts on the environment during the project’s operation and maintenance
                                phases.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Site plan and layout: </b>This must provide a site plan and layout of the
                                proposed project, including details such as the location of buildings,
                                equipment, and other infrastructure.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b> Project feasibility study: </b>It should provide information on the
                                technical, financial, and economic aspects of the proposed project, including
                                the estimated cost, timeline, and expected benefits.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Environmental Monitoring and Management Plan (EMMP): </b>The EMMP is a
                                plan that outlines the strategies and actions that will be implemented to
                                monitor and manage the project’s impact on the environment throughout its
                                life cycle.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Approval and clearance certificates: </b>The company or organization must
                                obtain relevant approvals and clearance certificates from the relevant
                                authorities before submitting the application.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Any other relevant documents: </b>Depending on the nature of the proposed
                                project, the company or organization may be required to provide additional
                                documents such as permits, licenses, and certificates.
                            </span>
                        </div>
                        It is important to note that the specific documents required may vary depending on
                        the type of project and the regulations in force at the time of the application.
                        The Department of Environment (DOE) provides guidance on the documentation
                        requirements for Environmental License applications.
                    </p>
                    <p>
                        <img className="mx-auto responsive-img" src={environmentImg2}
                            alt='Fire-License-In-Bangladesh-3' style={{ width: 1400, height: 300 }}
                        />
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>
                            Consequences of operating business without Environment License (DOE)
                        </b>
                    </p>
                    <p>
                        Operating a business or conducting an activity without an Environmental License, also
                        known as Department of Environment (DOE) License, in Bangladesh is illegal and can
                        result in various consequences. The consequences of operating without an
                        Environmental License include:
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Legal penalties: </b>The Department of Environment (DOE) has the power to
                                impose legal penalties, including fines and imprisonment, on companies or
                                individuals who operate without an Environmental License.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Closure of the business: </b>The DOE can order the closure of the business
                                or activity that is operating without a license, which can lead to financial
                                losses and damage to the reputation of the company or individual.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Loss of reputation: </b>Operating without an Environmental License can
                                harm the reputation of the company or individual and lead to negative
                                publicity, which can have long-term consequences on the business or activity.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Environmental damage: </b>It can result in environmental damage, which can
                                harm the health and well-being of people, ecosystems, and wildlife.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Inability to obtain financing or insurance: </b>Banks and insurance
                                companies may refuse to provide financing or insurance to companies or
                                individuals that operate without an Environmental License due to the
                                increased risk of environmental damage and legal penalties.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Inability to bid for government contracts: </b>Companies that operate
                                without an Environmental License are typically not eligible to bid for
                                government contracts, which can limit their business opportunities.
                            </span>
                        </div>
                        In short, operating a business or conducting an activity without an Environmental
                        License (DOE) in Bangladesh can have serious legal, financial, and repetitional
                        consequences. It is important for companies and individuals to obtain the necessary
                        licenses and comply with the relevant environmental regulations and standards to
                        ensure sustainable and environmentally friendly operations.
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                   {
                    othersArticales
                   }
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Environment ClearanceCertificate FAQ</h2>
                <Faq items={faqItems} />
            </div>
                   
        </>
    )
}

export default EnvironmentClearanceCertificate