import React from 'react'
import { Link } from 'react-router-dom'
import Faq from '../../../components/Faq';
import Meta from '../../../components/Meta';
import smallImg from "../../../assets/images/Small-Business.png"
import small_bussiness_og_img from "../../../assets/og-img/Small Business Setup in Bangladesh.png"
import SmallBusinessProcessImg from "../../../assets/images/Small-Business-Account-Services-process-1.png"
import Marquee from 'react-fast-marquee';
import { serviceRelatedArticales } from '../../../data/relatedArticale';

function SmallBusiness() {
    const faqItems = [
        {
            title: 'Which small business is most profitable in Bangladesh?',
            content: <strong>The most profitable businesses in Bangladesh with an investment of 25-30 lakh taka (approximately 29,000-35,000 USD) include:
            Clothing business: Bangladesh is known for its garment industry. ...
            Grocery store: Starting a grocery store in a densely populated area can be a profitable business idea.</strong>
        },
        {
            title: 'What is small enterprise in Bangladesh?',
            content: <strong>However, an overwhelming number of these enterprises are micro (having less than 10 workers), small (10-49 workers) 
            and medium (50-99 workers) enterprises, usually called MSMEs/SMEs.</strong>
        },
        {
            title: 'How can I start a small business in Bangladesh?',
            content: <strong>Requirements for Starting a Business in Bangladesh
            Approval of the RJSC.
            A bank account in Bangladesh.
            Trade license issued by the local authority.
            Business Identification Number and Tax Identification Number.
            Rent an office in a non-residential location.
            Prepare blueprint and the map of business for factory setup permission</strong>
        }
    ];
    return (
        <>
            <Meta title="Small Business Services in Bangladesh | Satrtup Business"
                description="Small Business In Bangladesh generally, three types of entities are observed operating in Bangladesh. These are Limited Company, Partnership Firm, and Proprietorship Business"
                image={smallImg}
                keywords="small business, startup, business license, business service, partnership business, limited company"
            />
                   

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">Bookkeeping Services</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Taxation</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Audit</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Small Business</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={smallImg} alt='Small Business' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p>
                        Small Business In Bangladesh generally, three types of entities are observed operating
                        in Bangladesh. These are Limited Company, Partnership Firm, and Proprietorship
                        Business. Around 65% of businesses are being operated as a sole proprietorship basis.
                        A single entrepreneur can start a proprietorship business. Generally, small and
                        medium-size business venture goes for proprietorship.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid"  src={SmallBusinessProcessImg} alt='Small-Business-Account-Services-process-1' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">
                                    Following Issues are taken into account by the authority
                                </p>
                                <p>
                                    <div className="d-flex align-items-center mb-2">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Name of the Proprietorship Small Business
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Owner’s name
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Kinds of Small Business In Bangladesh such as Export, Import, Supplier, etc.
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Business Address
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Owner’s Address, Date of Birth, Parents Name
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Owner’s National ID cards copy
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Owner’s Photograph
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            The registered address of the business
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            VAT challah copy of Trade License
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Office rental deed
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            Fill up Trade license form with the Owner’s signature
                                        </span>
                                    </div>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Liability:
                                </p>
                                <p>
                                    A proprietorship is not considered as a separate legal entity. The owner
                                    is liable for all the business consequences.
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Taxation:
                                </p>
                                <p>
                                    The owner shall have to pay the tax by his individual head as his personal income from the business.
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Maintenance & Operation:
                                </p>
                                <p>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            The operating cost is comparatively low for a proprietorship business
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            It is not required to prepare a profit & loss balance sheet & audit report annually
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-1">
                                        <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                                        <span>
                                            The owner is the only person who decides any business decision
                                        </span>
                                    </div>
                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Further required approvals:
                                </p>
                                <p>
                                    Small Business In Bangladesh Once the Trade License is obtained for
                                    proprietorship business, a TIN certificate (Taxpayers’ Identification
                                    Number) is required to open a bank account (business account) to operate
                                    business transaction. At present most of the business implement VAT
                                    (Value Added Tax). To deduct VAT, one needs to collect a VAT Registration
                                    Certificate. TIN Certificate needs for deducting AIT (Advance Income Tax)
                                    at the source to operate the business.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        If the proprietor wants to get involved with international business, he/she shall
                        require Export Registration Certificate or Import Registration Certificate for doing
                        international and multinational business. Now Bangladesh is one of the leading
                        readymade garments, Jute, Frozen Food, Leather Goods, and Potato exporting country
                        in the world. A good number of business entities like Proprietorship, Partnership
                        Firm, and Limited Companies are interested to involve International business or
                        Export & Import Business in Bangladesh. When two or more entrepreneurs want to start
                        a Small Business In Bangladesh, either they have to form a Limited Company or a
                        Partnership Firm.<br /><br />

                        This guide provides information on registration of a sole proprietorship in
                        Bangladesh. To learn about various types of business entities in Bangladesh, refer to
                        start a business in Bangladesh.<br /><br />

                        A sole proprietorship is the simplest form of structure in Bangladesh. However, it is
                        suitable only for very small single-owner type business that does not carry any
                        risks. Unlike a private limited liability company, a sole proprietorship does not
                        provide limited liability protection and your personal assets are not protected from
                        business risks.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>SOLE-PROPRIETORSHIP – KEY FACTS</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                A sole proprietorship in Bangladesh does not constitute a separate legal entity therefore it is not distinct from the owner/ proprietor. The business owner is personally accountable for all liabilities incurred during the course of the business.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Any Bangladeshi citizen of at least 18 years of age is eligible for a sole proprietorship.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                A local commercial address must be provided as the business address for the sole proprietorship.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Profits of the sole proprietorship are treated as income of the individual who owns the entity, thus it is subjected to a tax rate as that of personal income.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                As a Small Business In Bangladesh sole proprietorship is not a legal entity, it cannot register another business firm.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Sole proprietorships do not need to audit their accounts as any profits will be taxed as personal taxes.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Trade License is the basic requirement for proprietorship.

                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Trade License needs to be renewed annually.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>CONSIDERATIONS FOR FOREIGNERS</b>
                    </p>
                    <p>
                        Small Business In Bangladesh Practically, a sole proprietorship is not an option for
                        foreigners. Foreign business professionals should incorporate a private limited
                        company instead. For further details about private limited companies, refer to
                        company registration in Bangladesh guide.
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>DOCUMENTS REQUIRED AND REGISTRATION PROCEDURE</b>
                    </p>
                    <p>
                        In order to register a sole proprietorship Small Business In Bangladesh, the following documents/information are required:
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Proposed business name in Bangla
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Description of principal activities
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Local business address for the proposed business in any commercial area.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Lease/Rent Agreement or title deed for the office address.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Copy of National ID for the owner.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>SOLE PROPRIETORSHIP ADVANTAGES</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Ease of setting up: </b>It is the easiest and least expensive business structure to set up.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Owner Control: </b>As a sole proprietor you are in complete control of all the business affairs including decision making.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>No profit-sharing: </b>You accrue all income generated by the business.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Ease of termination: </b>Terminating a sole proprietorship is easier, less time consuming and less expensive than other business entities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Least compliance requirements:</b> You are free of the obligation of filing returns annually and only need to renew your membership every year.
                            </span>
                        </div>
                    </p>
                    <p className="color-text fs-5 mb-4">
                        <b>SOLE PROPRIETORSHIP DISADVANTAGES</b>
                    </p>
                    <p>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b> No separate legal entity: </b>You are inseparable from your
                                business. This makes you financially and legally responsible for
                                all debts and legal actions against the business.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Unlimited liability: </b>Creditors may sue you for debts incurred
                                and can also obtain a court order to claim against your personal assets, including your property.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b> No corporate tax benefits or incentives: </b>Taxes are determined at your personal income tax rate and you do not enjoy special tax benefits that are available to a private limited company.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b> Limited capital: </b>Capital is limited to your personal finances and the profits generated by the business. Thus, business expansion is limited and difficult.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>No perpetual succession: </b>The business lives and dies with
                                you as you and the business are one and the same thing. However,
                                after the death of the owner of the proprietorship, the successor
                                may continue the business in the same name.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Low public perception: </b>This entity is the least preferred for
                                serious businesses as nobody would be willing to lend you large sums
                                of money. It is also difficult to attract high-caliber employees, or
                                senior level executives who usually look for a more advanced form of
                                business structure such as a private limited company.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                <b>Sale/transfer of all or part of the business: </b>You can
                                transfer the business only by the sale of business assets.
                            </span>
                        </div>
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>
            
            <div className='container-xxl center-background1'>
            <Marquee className="marquee-container">
               {
                serviceRelatedArticales
               }
            </Marquee>
            </div>


            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">Small Business FAQ</h2>
                <Faq items={faqItems} />
            </div>
                   
        </>
    )
}

export default SmallBusiness